import {useSelector} from "react-redux";
import avatar from "../../../assets/icons/profile/avatar.png";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import styles from "./profile-card.module.css";

export default function ProfileCard() {
    const {userData: {first_name, last_name, middle_name, accounts}} = useSelector(loginSelector).login;
    return (
        <div className="d-flex">
            <div>
                <img src={avatar} className={styles.avatar} alt="user" />
            </div>
            <div className="ms-2">
                <div className="fw-bold lead">{first_name} {last_name}</div>
                <div className="extraSmallText fw-bold">{first_name}  {middle_name ?? ''} {last_name}</div>
                <div className="extraSmallText text-muted fw-bold text-capitalize">{accounts[0].bankName} Bank</div>
                <div className="extraSmallText text-muted fw-bold">{accounts[0].accountNumber}</div>
            </div>
        </div>
    )
}