import debitIcon from "../../assets/icons/dashboard/recent-transactions/debit.svg";
import creditIcon from "../../assets/icons/dashboard/recent-transactions/credit.svg";
import dayjs from "dayjs";
import {formatNumberInThousand} from "../../utils/functions";
import TransactionDetailsModal from "./transaction-details/TransactionDetailsModal";
import {useState} from "react";

const tableHeader = ["", "Name", "Date", "Amount", "Type", "Action"];

export default function RecentContributionTransactions({transactions}) {
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState();
  
  function handleViewTransaction(tnx) {
    setSelectedTnx(tnx);
    setOpenTransactionModal(true);
  }
  
  return (
    <div className="bgWhite cardShadow p-3">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fw-bold">Transactions</div>
      </div>
      <div className="table-responsive-lg">
        <table className="table fw-bold small">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transactions.map((item, index) => (
              <tr key={index}>
                <td>
                  <img
                    src={item.amount < 0 ? debitIcon : creditIcon}
                    width="20"
                    alt={item.type}
                  />{" "}
                </td>
                <td>{item?.receiver?.first_name} {item?.receiver?.last_name}</td>
                <td>
                  {dayjs(item.createdAt).format("MMM DD, YYYY")} | {new Date(item.createdAt).toLocaleTimeString()}</td>
                <td>
                  {item.type === "Withdrawal" ? (
                    <span className="text-danger">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  ) : (
                    <span className="">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  )}
                </td>
                <td>
                  {item?.transaction_type}
                </td>
                {/* <td>
                  {item?.receiver?.account_number}
                </td> */}
                <td>
                  <button className="btn btn-sm fw-bold" onClick={() => handleViewTransaction(item)}>View</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TransactionDetailsModal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        transaction={selectedTnx}
      />
    </div>
  );
}
