import {useFormik} from "formik";
import {profileProofOfAddressSchema as validationSchema} from "../../utils/yupSchemas";
import {profileProofOfAddressFields} from "../../utils/formFields";
import CustomForm from "../../widgets/form/CustomForm";
import {useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";

export default function ProfileProofOfAddress() {
  const {userData:{addresses:[address]}} = useSelector(loginSelector).login;
  // const [loading, setLoading] = useState(false);
  // const [message, setMessage] = useState(null);
  const formik = useFormik({
    initialValues: {
      home_address: address.address_line_1,
      city: address.address_line_2,
      state: address.address_line_3,
    },
    validationSchema,
    // onSubmit: (values) => handleSubmit(values),
  });

  // const dispatch = useDispatch();
  // const verify = useVerify()
  // useVerify will always refetch user data
  // async function handleSubmit(values) {
  //   const details = {
  //     ...values,
  //     userId: userData.id
  //   };
  //   await dispatch(addAddress(details, {
  //     setLoading,
  //     setMessage
  //   }));
  //   // to refetch user data
  //   verify()
  // }

  return (
    <>
      <div className="fw-bold">Proof of Address</div>
      <div className="extraSmallText mb-4">
        These information are verified and cannot be changed </div>
      <form>
        {/* {(message && typeof message.text === 'string') &&
          <div className={message.error ? 'text-danger' : 'text-success'}>{message.text}</div>
        } */}
        {profileProofOfAddressFields.map((field, index) => (
          <CustomForm disabled item={field} formik={formik} key={index} />
        ))}
      </form>
    </>
  );
}
