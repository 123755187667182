import PropTypes from "prop-types";
import {formatNumberInThousand} from "../../../utils/functions";
import CustomButtonWithIcon from "../../../widgets/form/custom-button/CustomButtonWithIcon";
import CustomIcon from "../../../widgets/icon/CustomIcon";
import logo from "../../../assets/images/logo.svg";
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import dayjs from "dayjs";

export default function TransactionDetails({data}) {
  // function handleReportTransaction() {
  //   alert("Transaction reported");
  // }

  return (
    <>
      <Actions />
      {/* add id 'receipt' to enable receipt download */}
      <div id="receipt" className="p-3">
        <img src={logo} alt="logo" className="float-end" height={20} />
        <div className="fw-light mt-4 mb-3 small">
          {dayjs(data?.createdAt).format("MMM DD, YYYY")}
        </div>
        <ReceiptLine
          verticalReverse={true}
          leadTitle={true}
          title={`${data?.receiver?.first_name} ${data?.receiver?.last_name} (${data?.receiver?.phone})`}
          value={formatNumberInThousand(data?.amount)}
        />
        <ReceiptLine
          title="From"
          value={data?.sender?.first_name + " " + data?.sender?.last_name + " (" + data?.sender?.phone + ")"}
        />
        <ReceiptLine title="Description" value={data?.description} />
        {/* <ReceiptLine
          title="Payment Method"
          value="Bank Transfer"
          appendTitle="Fees"
          appendValue={`${nairaSign}${data?.cost}`}
        /> */}
        <ReceiptLine title="Transaction Ref" value={data?.transaction_ref} />
      </div>
      {/* <div className="fw-bold mt-4 mb-3">More Actions</div>
      <CustomList
        icon="caution"
        iconColor="#CD0612"
        titleColor="#CD0612"
        title="Report Transaction"
        isClickable
        subtitle="Report an issue with this payment"
        onClick={handleReportTransaction}
      /> */}
    </>
  );
}

function Actions() {
  async function getReceipt() {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#receipt"), {
      html2canvas: {scale: 0.57},
    });
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("receipt.pdf");
  }
  return (
    <div className="d-flex flex-wrap justify-content-evenly justify-content-sm-between align-items-center px-3">
      {/* <CustomButtonWithIcon
        text="Deposit"
        icon="download"
        iconColor="green"
        withShadow
      />
      <CustomButtonWithIcon
        text="Share Details"
        icon="foreign"
        iconColor="#14A3B8"
        withShadow
      /> */}
      <CustomButtonWithIcon
        text="Download Receipt"
        icon="download"
        iconColor="#355BCF"
        withShadow
        onClick={getReceipt}
      />
    </div>
  );
}

function ReceiptLine({
  title,
  verticalReverse,
  leadTitle,
  value,
  appendTitle,
  appendValue,
  appendIcon,
}) {
  return (
    <div className="d-flex justify-content-between text-break align-items-center border-bottom py-2 mb-2">
      <div className="">
        <ReceiptInfo
          verticalReverse={verticalReverse}
          leadTitle={leadTitle}
          title={title}
          value={value}
        />
      </div>
      {appendTitle ? (
        <ReceiptInfo title={appendTitle} value={appendValue} />
      ) : (
        <CustomIcon name={appendIcon} size={40} />
      )}
    </div>
  );
}

function ReceiptInfo({title, value, leadTitle, verticalReverse}) {
  return (
    <div
      className={`d-flex ${verticalReverse ? "flex-column-reverse" : "flex-column"
        }`}
    >
      {title && <div className="fw-light small mb-2">{title}</div>}
      {value && (
        <div className={`fw-bold small ${leadTitle ? "lead" : ""}`}>
          {value}
        </div>
      )}
    </div>
  );
}

TransactionDetails.propTypes = {
  data: PropTypes.object,
};

ReceiptLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  verticalReverse: PropTypes.bool,
  leadTitle: PropTypes.bool,
  appendTitle: PropTypes.string,
  appendValue: PropTypes.string,
  appendIcon: PropTypes.string,
};

ReceiptInfo.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  leadTitle: PropTypes.bool,
  verticalReverse: PropTypes.bool,
};
