import {combineReducers} from "redux";
import loginReducer from "./slices/auth/loginSlice";
import signupReducer from "./slices/auth/signupSlice";
import saversReducer from "./slices/savers/saversSlice";
import payoutReducer from "./slices/savers/payoutSlice";
import depositReducer from "./slices/savers/depositSlice";
import forgetPasswordReducer from "./slices/auth/forgetPasswordSlice";
import agentReducer from "./slices/agent/agentSlice";

const combinedReducer = combineReducers({
  login: loginReducer,
  signup: signupReducer,
  savers: saversReducer,
  payout: payoutReducer,
  deposit: depositReducer,
  forgetPassword: forgetPasswordReducer,
  agent: agentReducer
});

const rootReducer = (state, action) => {
  //clear the whole store when action type "login/logout" is dispatched.
  if (action.type === "login/logout") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default rootReducer;
