import styles from "./downloads.module.css";

export default function Downloads() {
    return (
        <div className={["container-fluid p-3", styles.filter].join(' ')}>
            <div className="row">
                <div className="col-sm-6 col-md-2 col-6 mb-2">
                    <button className="btn bgDanger text-white">Export PDF</button>
                </div>
                <div className="col-sm-6 col-md-2 col-6 mb-2">
                    <button className="btn bgWarning text-white">Export Excel</button>
                </div>
                <div className="col-sm-6 col-md-2 col-6 mb-2">
                    <button className="btn bgDanger text-white">Export CSV</button>
                </div>
                <div className="col-sm-6 col-md-3 col-6 mb-2">
                    <button className="btn bg-light">Copy to clipboard</button>
                </div>
            </div>
        </div>
    )
}