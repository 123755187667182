import {useNavigate} from "react-router-dom";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";

export default function SignUpCompleted({closeModal}) {
  const navigate = useNavigate();

  function handleCompleteSignUp() {
    closeModal();
    navigate("/auth/login");
  }

  return (
    <SuccessFeedback
      title="Account created successfully"
      content="You can now log into your app and fund your Wallet"
      onClick={handleCompleteSignUp}
      buttonText="Go to Log In"
    />
  );
}
