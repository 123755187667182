import {useEffect, useState} from "react";
// import SignUpBVNVerification from "../../../components/auth/sign-up/SignUpBVNVerification";
import SignUpCreatePassword from "../../../components/auth/sign-up/SignUpCreatePassword";
import SignUpOTP from "../../../components/auth/sign-up/SignUpVerifyOTP";
import LayoutAuth from "../../../layouts/LayoutAuth";
import SignUpCompleted from "../../../components/auth/sign-up/SignUpCompleted";
import CustomModal from "../../../widgets/modals/CustomModal";
import SignUpSetupPin from "../../../components/auth/sign-up/SignUpSetupPin";
import {useDispatch, useSelector} from "react-redux";
import {
  setMessage,
  setStep,
  signupSelector,
} from "../../../store/slices/auth/signupSlice";
import SignUpPersonalInformation from "../../../components/auth/sign-up/SignUpPersonalInformation";
import {useNavigate} from "react-router-dom";

export default function SignUp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {step, success} = useSelector(signupSelector).signup;
  const [open, setOpen] = useState(false);

  function handleCloseModal() {
    dispatch(setStep(1))
    setOpen(false);
    navigate("/auth/login");
  }

  useEffect(() => {
    dispatch(setMessage(null));
    if (step === 6) {
      setOpen(true);
    }
  }, [step, dispatch, success]);

  return (
    <LayoutAuth>
      {step === 1 && <SignUpPersonalInformation />}
      {step === 2 && <SignUpPersonalInformation />}
      {step === 3 && <SignUpOTP />}
      {step === 4 && <SignUpCreatePassword />}
      {step === 5 && <SignUpSetupPin />}

      <CustomModal open={open} onClose={handleCloseModal}>
        <SignUpCompleted closeModal={handleCloseModal} />
      </CustomModal>
    </LayoutAuth>
  );
}