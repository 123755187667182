import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {setStep, setUserData, signupSelector} from "../../../store/slices/auth/signupSlice";
import SignUpStepTitle from "./SignUpStepTitle";
import ACInput from "../../../widgets/form/ACInput";
import ACBtn from "../../../widgets/form/ACBtn";
import {CheckCircleIcon} from "@heroicons/react/20/solid";

const validationSchema = Yup.object({
  password: Yup.string("Enter your Password")
    .min(8, "Too short!")
    .matches(/[0-9]/, 'Password must contain at least a digit')
    .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Password must contain special character')
    .matches(/[a-zA-Z]/, 'Password must contain at least a letter')
    .required("New password is required"),
  confirmPassword: Yup.string("Enter your Password")
    .required("Re-type your password").oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default function SignUpCreatePassword() {
  const dispatch = useDispatch();
  const {loading} = useSelector(signupSelector).signup;

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (e) => {
    dispatch(setUserData({password: e.password}))
    dispatch(setStep(5));
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='cfade transition-all duration-500 max-w-md py-20 mx-[auto] md:mx-0 px-3 lg:pl-8 flex min-h-screen flex-col'>
        <SignUpStepTitle title="Password" subtitle="Create your easy to remember password" />

        <div className='flex flex-col gap-3 pt-4'>
          <ACInput inputProps={{placeholder: '**********', name: 'password', type: 'password'}} formik={formik} label={'Password'} />
          <ACInput inputProps={{placeholder: '**********', name: 'confirmPassword', type: 'password'}} formik={formik} label={'Confirm Password'} />

          <div className="pb-3 pt-2 flex flex-col gap-1">
            <Validation text={'Password must contain at least a digit'} condition={/[0-9]/.test(formik.values.password)} />
            <Validation text={'At least 8 characters'} condition={formik.values.password.length >= 8} />
            <Validation text={'Password must contain special character'} condition={/[!@#$%^&*(),.?":{}|<>]/.test(formik.values.password)} />
            <Validation text={'Password must contain at least a letter'} condition={/[a-zA-Z]/.test(formik.values.password)} />
          </div>
          <div className='flex justify-between'>
            <button type='button' onClick={() => dispatch(setStep(3))} className='gr-btn' disabled={loading}>Back</button>
            <ACBtn type='submit' text={'Continue'} loading={loading} className={'max-w-[8rem]'} />
          </div>
        </div>

      </form>
    </>
  );
}

const Validation = ({condition, text}) => {
  return (
    <div className={`flex gap-2 text-sm items-center transition ${condition ? 'text-success-credit' : 'text-neutral-2'}`}>
      <CheckCircleIcon className="h-4 w-4" />
      <p>{text}</p>
    </div>
  )
}