import dayjs from "dayjs";
import { useState } from "react";
import { saversTransactions } from "../../../../utils/constants";
import { formatNumberInThousand } from "../../../../utils/functions";
import TransactionDetailsModal from "../../../transactions/transaction-details/TransactionDetailsModal";

const tableHeader = [
  "Savers's Name",
  "Start Date",
  "Amount",
  "Type",
  "Account Number",
  "Duration",
  "End Date",
  "Phone Number",
  "Action",
];

export default function ContributionTransactions() {
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState({});

  function handleViewTransaction(tnx) {
    setSelectedTnx(tnx);
    setOpenTransactionModal(true);
  }

  return (
    <>
      <div className="bgWhite cardShadow p-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <div className="fw-bold">Recent Contribution Transactions</div>
          {/* <button className="btn btn-sm textSecondary">View All</button> */}
        </div>
        <div className="table-responsive-lg">
          <table className="table fw-bold small">
            <thead className="tableHeader">
              <tr>
                {tableHeader.map((item, index) => (
                  <th key={index}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {saversTransactions.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{dayjs(item.startDate).format("MMM DD, YYYY")}</td>
                  <td>{formatNumberInThousand(item.amount)}</td>
                  <td>{item.type}</td>
                  <td>{item.accountNumber}</td>
                  <td>{item.duration}</td>
                  <td>{dayjs(item.endDate).format("MMM DD, YYYY")}</td>
                  <td>{item.phoneNumber}</td>
                  <td>
                    <button
                      className="btn btn-sm fw-bold"
                      onClick={() => handleViewTransaction(item)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <TransactionDetailsModal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        transaction={selectedTnx}
      />
    </>
  );
}
