import CustomLabel from "../custom-label/CustomLabel";
import styles from "./custom-input.module.css";

export default function CustomInput(props) {
    const {withPrepend, appendValue, prependValue, labelText, labelHtmlFor, ...rest} = props;

    if(prependValue) return (
        <div className="input-group">
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
        </div>
    )
    if(appendValue) return (
        <div className="input-group">
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
        </div>
    )
    if(!prependValue && !appendValue) return (
        <>
            {labelText && <CustomLabel htmlFor={labelHtmlFor} content={labelText} />}
            <input className={[ "form-control" , styles.customInput].join(' ')} {...rest} />
        </>
    )
}