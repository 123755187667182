import React, { useEffect, useState } from "react";
import CompleteVerification from "../../components/dashboard/complete-verification/CompleteVerification";
import DataCard from "../../components/dashboard/data-card/DataCard";
import QuickLinks from "../../components/dashboard/quick-links/QuickLinks";
import RecentTransactions from "../../components/dashboard/recent-transactions/RecentTransactions";
import PageHeader from "../../widgets/page-header/PageHeader";
import { dashboardDataCards } from "../../utils/constants";
import Summary from "../../components/dashboard/summary/Summary";
import { useDispatch, useSelector } from "react-redux";
import { agentSelector, getBalance, getTransactions } from "../../store/slices/agent/agentSlice";
import { loginSelector } from "../../store/slices/auth/loginSlice";
// import styles from './dashboard.module.css';

const Dashboard = () => {
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [trxIsLoading, setTrxIsLoading] = useState(false);

  const { dailys } = useSelector(agentSelector).agent
  // const [error, setError] = useState(null);

  const [transactions, setTransactions] = useState([]);
  // const [dailys, setDailys] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [todayTransactions, setTodayTransactions] = useState([]);

  const {
    userData: { id, accounts },
  } = useSelector(loginSelector).login;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBalance(
      { accountId: accounts[0].accountId, agentId: id },
      { setBalance, setLoading })
    );

    dispatch(getTransactions(
      { agentId: id, accountId: accounts[0].accountId, },
      {
        setLoading: setTrxIsLoading, //setError,
        setTransactions,
        //  setDailys
      }
    ));
  }, [dispatch, accounts, id])
  useEffect(() => {
    let tAmount = 0;
    transactions.forEach((each) => {
      if (new Date(each.createdAt).toISOString() === new Date().toISOString()) {
        tAmount += Number(each.amount);
      }
    });
    setTotalAmount(tAmount);
    let tTransactions = transactions.filter(
      (each) =>
        new Date(each.createdAt).toISOString() === new Date().toISOString()
    );
    setTodayTransactions(tTransactions);
  }, [transactions])

  return (
    <>
      <PageHeader title="Dashboard" withSearch={true} />
      <div className="container pt-4">
        {/* <button className="rounded border-0">
          <svg className={loading && styles.animateSpin} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{fill: '#15a4b9'}}><path d="M10 11H7.101l.001-.009a4.956 4.956 0 0 1 .752-1.787 5.054 5.054 0 0 1 2.2-1.811c.302-.128.617-.226.938-.291a5.078 5.078 0 0 1 2.018 0 4.978 4.978 0 0 1 2.525 1.361l1.416-1.412a7.036 7.036 0 0 0-2.224-1.501 6.921 6.921 0 0 0-1.315-.408 7.079 7.079 0 0 0-2.819 0 6.94 6.94 0 0 0-1.316.409 7.04 7.04 0 0 0-3.08 2.534 6.978 6.978 0 0 0-1.054 2.505c-.028.135-.043.273-.063.41H2l4 4 4-4zm4 2h2.899l-.001.008a4.976 4.976 0 0 1-2.103 3.138 4.943 4.943 0 0 1-1.787.752 5.073 5.073 0 0 1-2.017 0 4.956 4.956 0 0 1-1.787-.752 5.072 5.072 0 0 1-.74-.61L7.05 16.95a7.032 7.032 0 0 0 2.225 1.5c.424.18.867.317 1.315.408a7.07 7.07 0 0 0 2.818 0 7.031 7.031 0 0 0 4.395-2.945 6.974 6.974 0 0 0 1.053-2.503c.027-.135.043-.273.063-.41H22l-4-4-4 4z"></path></svg>
        </button> */}
        <section className="row flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
          <DataCard data={{
            title: "Total daily Volume",
            icon: "upDownArrow",
            value: dailys?.transactionCount || 0,
            loading: trxIsLoading,
            dp: 0,
            backgroundColor: "#061B5A",
            color: "#ffffff",
          }} />
          <DataCard data={{
            title: "Total daily Value",
            icon: "upDownArrow",
            loading: trxIsLoading,
            value: dailys?.totalValue || 0, 
            backgroundColor: "#15A4B9",
            color: "#ffffff",
            dp: 0
          }} />
          <DataCard data={{
            title: "Daily Wallet Balance",
            icon: "walletWhiteBg",
            value: balance,
            dp: 2,
            loading,
            date: new Date(),
            backgroundColor: "#FFE7A5",
          }} />
          {dashboardDataCards.map((item, index) => <DataCard data={{ ...item, }} key={index} />)}
        </section>
        <section className="row">
          <div className="col-sm-12 col-md-6 p-2">
            <CompleteVerification />
          </div>
          <div className="col-sm-12 col-md-6 p-2">
            <QuickLinks />
          </div>
        </section>
        <section className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 p-2 order-1 order-md-0">
            <RecentTransactions />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 p-2">
            <Summary />
          </div>
        </section>
      </div>
    </>
  );
};

export default Dashboard;
