import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {login, loginSelector} from "../../../store/slices/auth/loginSlice";
import ACInput from '../../../widgets/form/ACInput';
import {Link} from "react-router-dom";
import ACBtn from "../../../widgets/form/ACBtn";

const validationSchema = Yup.object({
  phone: Yup.string("Enter phone number, e.g 08012345678")
    .min(11, "Too short! ")
    .max(11, "Too long!")
    .required("Phone number is required"),
  password: Yup.string("Enter Password")
    .min(8, "Too short!")
    .required("Password is required"),
});

export default function LoginForm() {
  const dispatch = useDispatch();
  const {loading, message} = useSelector(loginSelector).login;

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (e) => {
    dispatch(login(e));
  };

  return (
    <form onSubmit={formik.handleSubmit} className='cfade transition-all duration-500 max-w-md py-20 mx-[auto] h-full md:mx-0 px-3 lg:pl-8 flex flex-col'>
      <h3 className='text-light-blue text-2xl md:text-[2rem] font-bold'>Log In to your account</h3>
      <p className='text-sm'>Continue from where you left </p>
      <p className="text-xs mt-2 text-red-600">{message}</p>

      <div className='flex flex-col gap-3 pt-7'>
        <ACInput inputProps={{placeholder: '08000000000', name: 'phone'}} formik={formik} label={'Phone Number'} />
        <ACInput inputProps={{placeholder: '**********', name: 'password', type: 'password'}} formik={formik} label={'Password'} />

        <ACBtn loading={loading} text='Sign In' className={'mt-7'} />
        <div className='flex gap-2 text-center justify-center'>
          <span className='font-light'>New here?</span>
          <Link to="/auth/signup" className="text-light-blue hover:text-blue-600 transition">
            Sign Up
          </Link>
        </div>
        <div className='font-light text-center'>
          <Link to={'/auth/forgot-password'} className="hover:opacity-70 transition">Forgot  Password</Link>
        </div>
      </div>
      <div className='mt-auto flex justify-end'>
        <span className='text-xs text-black-4 hover:text-black transition cursor-pointer'>Need Help?</span>
      </div>
    </form>
  );
}
