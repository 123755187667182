import {nigerianBanksList} from "./constants";

export const bankTransferFields = [
  {
    label: {htmlFor: "fullName", content: "Full Name"},
    type: "text",
    ariaLabel: "FullName",
    ariaDescribedby: "fullName",
    name: "fullName",
    value: "fullName",
  },
  {
    label: {htmlFor: "accountNumber", content: "Account Number"},
    type: "number",
    ariaLabel: "AccountNumber",
    ariaDescribedby: "accountNumber",
    name: "accountNumber",
    value: "accountNumber",
  },
  // {
  //   label: {htmlFor: "amount", content: "Amount"},
  //   type: "number",
  //   ariaLabel: "amount",
  //   ariaDescribedby: "Amount",
  //   name: "amount",
  //   value: "amount",
  // },
  {
    label: {htmlFor: "bankName", content: "Bank Name"},
    type: "text",
    formType: "select",
    options: nigerianBanksList,
    option: "name",
    ariaLabel: "bankName",
    ariaDescribedby: "BankName",
    name: "bankName",
    value: "bankName",
  },
];

export const cardPaymemtFields = [
  {
    label: {htmlFor: "amount", content: "Amount"},
    type: "number",
    ariaLabel: "amount",
    ariaDescribedby: "Amount",
    name: "amount",
    value: "amount",
  },
];

export const withdrawFromWalletfields = [
  {
    label: {htmlFor: "amount", content: "Amount"},
    type: "number",
    ariaLabel: "amount",
    ariaDescribedby: "Amount",
    name: "amount",
    value: "amount",
  },
  {
    label: {htmlFor: "description", content: "Description"},
    formType: "textarea",
    ariaLabel: "description",
    ariaDescribedby: "description",
    name: "description",
    value: "description",
    placeholder: "description",
  },
];

export const profileBankDetailsFields = [
  {
    label: {htmlFor: "account_number", content: "Account Number"},
    type: "number",
    ariaLabel: "AccountNumber",
    ariaDescribedby: "account_number",
    placeholder: "Enter account number",
    name: "account_number",
    value: "account_number",
  },
  {
    label: {htmlFor: "bank_name", content: "Bank Name"},
    type: "text",
    formType: "select",
    options: nigerianBanksList,
    option: "code",
    innerLabel: "name",
    ariaLabel: "bank_name",
    ariaDescribedby: "BankName",
    placeholder: "Select Bank",
    name: "bank_name",
    value: "bank_name",
  },
  {
    label: {htmlFor: "account_name", content: "Account Name"},
    type: "text",
    ariaLabel: "AccountName",
    ariaDescribedby: "account_name",
    placeholder: "Enter Account Name",
    name: "account_name",
    value: "account_name",
  },
];

export const profileChangePasswordFields = [
  {
    label: {htmlFor: "oldPassword", content: "Old Password"},
    type: "text",
    ariaLabel: "OldPassword",
    ariaDescribedby: "oldPassword",
    placeholder: "Enter your old password",
    name: "oldPassword",
    value: "oldPassword",
  },
  {
    label: {htmlFor: "newPassword", content: "New Password"},
    type: "text",
    ariaLabel: "NewPassword",
    ariaDescribedby: "newPassword",
    placeholder: "Enter your new password",
    name: "newPassword",
    value: "newPassword",
  },
];

export const profileResetPasswordFields = [
  {
    label: {htmlFor: "old_password", content: "Old Password"},
    type: "password",
    ariaLabel: "old_password",
    ariaDescribedby: "old_password",
    placeholder: "Enter your old password",
    name: "old_password",
    value: "oldPassword",
  },
  {
    label: {htmlFor: "new_password", content: "New Password"},
    type: "password",
    ariaLabel: "new_password",
    ariaDescribedby: "new_password",
    placeholder: "Enter your new password",
    name: "new_password",
    value: "new_password",
  },
];

export const profileForgotPinFields = [
  {
    label: {htmlFor: "old_pin", content: "Old PIN"},
    type: "text",
    ariaLabel: "OldPin",
    ariaDescribedby: "old_pin",
    placeholder: "Enter your old PIN",
    name: "old_pin",
    value: "old_pin",
  },
  {
    label: {htmlFor: "new_pin", content: "New PIN"},
    type: "text",
    ariaLabel: "NewPin",
    ariaDescribedby: "new_pin",
    placeholder: "Enter your new PIN",
    name: "new_pin",
    value: "new_pin",
  },
];

export const profileGuarantorsInformationFields = [
  {
    label: {htmlFor: "first_name", content: "First Name"},
    type: "text",
    ariaLabel: "FirstName",
    ariaDescribedby: "first_name",
    placeholder: "Enter Guarantor’s First Name",
    name: "first_name",
    value: "first_name",
  },
  {
    label: {htmlFor: "last_name", content: "Last Name"},
    type: "text",
    ariaLabel: "LastName",
    ariaDescribedby: "last_name",
    placeholder: "Enter Guarantor’s First Name",
    name: "last_name",
    value: "last_name",
  },
  {
    label: {htmlFor: "middle_name", content: "Middle Name"},
    type: "text",
    ariaLabel: "MiddleName",
    ariaDescribedby: "middle_name",
    placeholder: "Enter Guarantor’s First Name",
    name: "middle_name",
    value: "middle_name",
  },
  {
    label: {htmlFor: "phone", content: "Phone Number"},
    type: "tel",
    ariaLabel: "phone",
    ariaDescribedby: "phone",
    name: "phone",
    value: "phone",
    prependValue: "+234",
    minLength: "11",
    maxLength: "11",
  },
  {
    label: {htmlFor: "email", content: "Email"},
    type: "email",
    ariaLabel: "Email",
    ariaDescribedby: "email",
    placeholder: "guarantorname@email.com",
    name: "email",
    value: "email",
  },
  {
    label: {htmlFor: "home_address", content: "Home Address"},
    type: "text",
    ariaLabel: "homeAddress",
    ariaDescribedby: "homeAddress",
    placeholder: "Guarantor Home address",
    name: "home_address",
    value: "home_address",
  },
];

export const profileProofOfAddressFields = [
  {
    label: {htmlFor: "home_address", content: "Address"},
    type: "text",
    ariaLabel: "home_address",
    ariaDescribedby: "home_address",
    placeholder: "Enter your home address",
    name: "home_address",
    value: "home_address",
  },
  {
    label: {htmlFor: "city", content: "City"},
    type: "text",
    ariaLabel: "City",
    ariaDescribedby: "city",
    placeholder: "Enter Your city",
    name: "city",
    value: "city",
  },
  {
    label: {htmlFor: "state", content: "State"},
    ariaLabel: "state",
    type: "text",
    ariaDescribedby: "state",
    name: "state",
    value: "state",
    placeholder: "Enter your state"
  },
];

export const profilePersonalInfoFields = [
  {
    label: {htmlFor: "first_name", content: "First Name"},
    type: "text",
    ariaLabel: "FirstName",
    ariaDescribedby: "first_name",
    placeholder: "Enter your First Name",
    name: "first_name",
    value: "first_name",
  },
  {
    label: {htmlFor: "middle_name", content: "Middle Name (optional)"},
    type: "text",
    ariaLabel: "MiddleName",
    ariaDescribedby: "middle_name",
    placeholder: "Enter your Middle Name",
    name: "middle_name",
    value: "middle_name",
  },
  {
    label: {htmlFor: "last_name", content: "Last Name"},
    type: "text",
    ariaLabel: "Last_name",
    ariaDescribedby: "last_name",
    placeholder: "Enter your First Name",
    name: "last_name",
    value: "last_name",
  },
  {
    label: {htmlFor: "phone", content: "Phone Number"},
    type: "tel",
    ariaLabel: "phone",
    ariaDescribedby: "phone",
    name: "phone",
    value: "phone",
    prependValue: "+234",
    minLength: "11",
    maxLength: "11",
  },
  {
    label: {htmlFor: "email", content: "Email"},
    type: "email",
    ariaLabel: "Email",
    ariaDescribedby: "email",
    placeholder: "yourname@email.com",
    name: "email",
    value: "email",
  },
  // {
  //   label: {htmlFor: "homeAddress", content: "Home Address"},
  //   type: "text",
  //   ariaLabel: "homeAddress",
  //   ariaDescribedby: "homeAddress",
  //   placeholder: "yourname@homeAddress.com",
  //   name: "homeAddress",
  //   value: "homeAddress",
  // },
];

export const depositSavingsFields = [
  {
    label: {htmlFor: "saversName", content: "Saver's Name"},
    type: "text",
    ariaLabel: "SaversName",
    ariaDescribedby: "saversName",
    placeholder: "Enter Saver’s First Name",
    hasDropdown: true,
    name: "saversName",
    value: "saversName",
  },
  {
    label: {htmlFor: "description", content: "Description"},
    type: 'text',
    ariaLabel: "description",
    placeholder: "Description",
    ariaDescribedby: "description",
    name: "description",
    value: "description",
  },
  {
    label: {htmlFor: "amount", content: "Amount"},
    type: "number",
    ariaLabel: "amount",
    ariaDescribedby: "Amount",
    placeholder: "0.00",
    name: "amount",
    value: "amount",
  },
  // {
  //   label: {htmlFor: "saving_type", content: "Saving Name"},
  //   type: "text",
  //   formType: "select",
  //   options: contributionTypes,
  //   ariaLabel: "saving_type",
  //   ariaDescribedby: "saving_type",
  //   placeholder: "-- Savings type --",
  //   name: "saving_type",
  //   value: "",
  // },
];