import CustomButton from "../../widgets/form/custom-button/CustomButton";
import successMarkIcon from "../../assets/icons/success-mark.svg";

export default function SuccessFeedback({
  title,
  content,
  onClick,
  buttonText,
}) {
  return (
    <div className="text-center p-4">
      <img className="mx-auto" src={successMarkIcon} alt="success-check" />
      <h3 className="mb-3 mt-4 fw-bold">{title}</h3>
      <div className="mb-4">{content}</div>
      <CustomButton color="primary" onClick={onClick} text={buttonText} />
    </div>
  );
}
