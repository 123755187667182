import axios from "axios";
import { createSlice } from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
	bankList: [],
	transactions: [],
	dailys: ''
};

export const agentSlice = createSlice({
	name: "agent",
	initialState,
	reducers: {
		setBankList: (state, { payload }) => {
			state.bankList = payload;
		},
		setTransactions: (state, { payload }) => {
			state.transactions = payload;
		},
		setDailys: (state, { payload }) => {
			state.dailys = payload;
		}
	},
});

//actions
export const { setBankList, setTransactions, setDailys } = agentSlice.actions;

//selectors
export const agentSelector = (state) => state;

//reducer
export default agentSlice.reducer;

//Asynchronous thunk action

export const getAccountName = async (details, actions) => {
	// return async (dispatch) => {
	actions.setLoadingName(true);
	actions.setLoadingNameMessage(null);
	try {
		const result = await axios.post(`${baseUrl}transactions/comfirm_account_name`, details);
		const data = result.data;
		if (!data.error || data.status) {
			actions.setLoadingName(false);
			return data?.data?.data?.account_name;
		} else {
			actions.setLoadingNameMessage({ error: true, message: data.message });
		}
	} catch (error) {
		if (error.response) {
			let err = (error.response);
			actions.setLoadingNameMessage({ error: true, message: err.data?.error?.data?.msg });
		} else {
			actions.setLoadingNameMessage({ error: true, message: "An error occured, please check your internet connection" });
		}
	}
	actions.setLoadingName(false);
	return false;
	// };
}

export const updatePersonalDdetails = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/update_details`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: "Profile updated successfully" });
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};

export const updateBankDdetails = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/bank_details`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: "Bank details added successfully" });
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const addGuarantor = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/add_guarantor`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: "Guarantor added successfully" });
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const getTransactions = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading && actions.setLoading(true);
		actions.setError && actions.setError(null);
		try {
			const result = await axios.post(`${baseUrl}transactions/get_agent_transaction`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions?.setTransactions(data.data);
				dispatch(setTransactions(data.data));
				dispatch(setDailys(data.result[0]));
			} else {
				actions?.setError({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions?.setError({ error: true, text: err.data?.message });
			} else {
				actions?.setError({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions?.setLoading(false);
	};
};


export const getBalance = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		try {
			const result = await axios.post(`${baseUrl}transactions/get_balance`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setBalance(data.data.data[0].balance);
			}
		} catch (error) {
		}
		actions.setLoading(false);
	};
};


export const addAddress = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/add_address`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: "Address added successfully" });
			} else {
				actions.setError({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const resetPassword = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/reset-password`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: "Password reset successful" });
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const resetPin = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/reset_pin`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: (data.message && typeof (data.message) == 'string') ? data.message : "Pin reset successful" });
			} else {
				actions.setError({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const uploadDocument = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}auth/update_pof`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({ text: (data.message && typeof (data.message) == 'string') ? data.message : "Pin reset successful" });
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


export const getWalletDetails = (details, actions) => {
	return async (dispatch) => {
		actions.setLoading(true);
		actions.setMessage(null);
		try {
			const result = await axios.post(`${baseUrl}transactions/get_agent_transaction_veend`, details);
			const data = result.data;
			if (!data.error || data.status) {
				actions.setMessage({
					text: (data.message && typeof (data.message) == 'string') ? data.message : "Pin reset successful",
					data
				});
			} else {
				actions.setMessage({ error: true, text: data.message });
			}
		} catch (error) {
			if (error.response) {
				let err = (error.response);
				actions.setMessage({ error: true, text: err.data?.message });
			} else {
				actions.setMessage({ error: true, text: "An error occured, please check your internet connection" });
			}
		}
		actions.setLoading(false);
	};
};


