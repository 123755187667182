import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
  token: "",
  userData: null,
  loading: false,
  success: false,
  failure: false,
  step: 1,
  message: null,
  error: null,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLoginStep: (state, {payload}) => {
      state.step = payload;
      state.message = null;
    },
    setLoading: (state, {payload}) => {
      state.loading = payload;
    },
    passwordIsCorrect: (state, {payload}) => {
      state.userData = payload;
      state.step = 2;
      state.message = null;
      state.loading = false;
      state.failure = false;
    },
    pinIsCorrect: (state, {payload}) => {
      state.token = payload?.user_token;
      window.localStorage.setItem("userToken", payload?.user_token);
      window.localStorage.setItem("userData", JSON.stringify(payload));
      state.success = true;
      state.message = null;
      state.loading = false;
      state.failure = false;
    },
    loginFailure: (state, {payload}) => {
      state.failure = true;
      state.loading = false;
      state.success = false;
      let err = JSON.parse(payload); //convert string back to object
      state.error = err?.data;
      state.message = err?.data?.message;
    },
    logout: (state = initialState) => {
      state.message = null;
      state.success = false;
      localStorage.removeItem('userToken');
    },
    setMessage: (state, {payload}) => {
      state.message = payload;
      state.loading = false;
    },
    setUserData: (state, {payload}) => {
      state.userData = {...state.userData, ...payload}
    },
    setSuccess: (state, {payload}) => {
      state.success = payload;
    },
  },
});

//actions
export const {setLoading, setSuccess, passwordIsCorrect, setUserData, pinIsCorrect, setMessage, loginFailure, logout, setLoginStep} = loginSlice.actions;

//selectors
export const loginSelector = (state) => state;

//reducer
export default loginSlice.reducer;

//Asynchronous thunk action
export const login = (loginData) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await axios.post(`${baseUrl}auth/agent_sign_in`, loginData);
      const data = result.data;
      if (data.status || data.data) {
        const userData = {phone: loginData.phone, id: data.data.id};
        dispatch(passwordIsCorrect(userData));
      } else {
        dispatch(setMessage(data.message));
      }
    } catch (error) {
      if (error.response) {
        //convert object to string to enable dispatch
        let err = JSON.stringify(error.response);
        dispatch(loginFailure(err));
      } else {
        dispatch(setLoading(false));
        dispatch(setMessage("An error occured, please check your network connection"));
      }
    }
  };
};

export const confirmPin = (loginData) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await axios.post(`${baseUrl}auth/confirm_pin`, loginData);
      const data = result.data;
      if (data.status || data.data) {
        dispatch(setUserData(data.data));
        dispatch(pinIsCorrect(data.data));
      } else {
        setMessage(data.message);
      }
    } catch (error) {
      if (error.response) {
        //convert object to string to enable dispatch
        let err = JSON.stringify(error.response);
        dispatch(loginFailure(err));
      } else {
        dispatch(setLoading(false));
      }
    }
  };
};