import CustomButton from "../../../../../widgets/form/custom-button/CustomButton";

export default function UssdPayment({ callSuccess }) {
  function handleSubmit(values) {
    callSuccess();
    // dispatch(login(data));
  }

  return (
    <>
      <div className="fw-bold">USSD</div>
      <div className="extraSmallText mb-4">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo
        mollitia fuga ullam repellat
      </div>
      <div>
        Dial <span className="fw-bold lead">*123*amount*pin#</span> on your
        phone and click "OK" below after your making your transaction.
      </div>
      <div className="d-flex justify-content-end mt-3">
        <CustomButton
          onClick={handleSubmit}
          type="button"
          text="Ok"
          color="primary"
        />
      </div>
    </>
  );
}
