import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";
const incomeRanges = [
	{value: "0 to 50k", disp: "0 to 50k"},
	{value: "50k to 100k", disp: "50k to 100k"},
	{value: "100k to 250k", disp: "100k to 250k"},
	{value: "250k to 500k", disp: "250k to 500k"},
	{value: "500k to 1m", disp: "500k to 1m"},
	{value: "1m & above", disp: "1m & above"}
]
export default function IncomeRange({changeStep}) {
	const {newSaverStep} = useSelector(saversSelector).savers;
	const dispatch = useDispatch();

	function handleSubmit(value) {
		dispatch(setNewSaverDetails({income_range: value}))
		dispatch(setNewSaverStep(5));
	}

	function handleGoBack() {
		dispatch(setNewSaverStep(newSaverStep - 1));
	}

	return (
		<div className="text-center">
			{
				incomeRanges.map(each => (
					<button key={each.value} onClick={() => handleSubmit(each.value)} className="border mx-3 rounded my-2 py-1 px-3 bg-white text-start d-inline-flex range-btn">
						<span className="pe-3">{each.disp}</span> <span className="ms-auto">&rarr;</span>
					</button>
				))
			}
			<div className="">
				<button className="btn mt-3 mx-3 customGreyButton btn-sm" onClick={handleGoBack} type="button">Back</button>
			</div>
		</div>
	);
}
