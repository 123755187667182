import { useNavigate } from "react-router-dom"


export default function CompleteVerification() {
    const navigate = useNavigate();

    function handleRedirectToAddSaver() {
        navigate("/profile");
    }

    return (
        <div className="bgWarning p-4 text-white customBorderRadius cursorPointer h-100" onClick={handleRedirectToAddSaver}>
            <div className="fw-bold mb-3">Click here to complete your verification</div>
            <p>To continue with your  Sign Up , kindly provide your Guranator’s Info and Proof Of Address to get your account verified</p>
        </div>
    )
}