import React from "react";
import "./App.scss";
import RoutesComponent from "./router/RoutesComponent";
// axios.interceptors.request.use(config => {
//   config.headers['x-api-key'] = process.env.REACT_APP_X_API_KEY
//   return config;
// });
function App() {
  return (
    <>
      <RoutesComponent />
    </>
  );
}

export default App;
