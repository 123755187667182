import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";

const validationSchema = Yup.object({
	employment_status: Yup.string("Select saver's Employment status")
		.required("Employment status is required"),
});

const statuses = [
	{value: "Employed", disp: "Employed"},
	{value: "Employer", disp: "Employer"},
	{value: "Entrepreneur", disp: "Entrepreneur"},
	{value: "Retired", disp: "Retired"},
	{value: "Self Employed", disp: "Self Employed"},
	{value: "Student", disp: "Student"},
	{value: "Unemployed", disp: "Unemployed"},
]

export default function EmploymentStatus({changeStep}) {
	const {newSaverDetails, newSaverStep} = useSelector(saversSelector).savers;
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			employment_status: newSaverDetails?.employment_status ?? "",
		},
		validationSchema,
		onSubmit: (values) => handleSubmit(values),
	});

	function handleSubmit(values) {
		dispatch(setNewSaverDetails(values))
		dispatch(setNewSaverStep(6));
	}

	function handleGoBack() {
		dispatch(setNewSaverStep(newSaverStep - 1));
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="mb-3">
				<CustomLabel htmlFor="employment_status" content="Employment status" />
				<select className="form-select customFormControl" name="employment_status" onChange={formik.handleChange} aria-label="employment_status" aria-describedby="employment_status">
					<option value="">Open this select menu</option>
					{statuses.map((item, index) => <option key={index} value={item.value}>{item.disp}</option>)}
				</select>
				{formik.touched.employment_status && <div className="form-text text-danger">{formik.errors.employment_status}</div>}
			</div>
			<div className="d-flex justify-content-between">
				<button
					className="btn customButton customGreyButton"
					onClick={handleGoBack}
					type="button"
				>
					Back
				</button>
				<button type="submit" className="btn customButton customPrimaryButton">
					Next
				</button>
			</div>
		</form >
	);
}
