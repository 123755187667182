import { useEffect, useState } from "react";
import { useRef } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import uploadIcon from "../../assets/icons/profile/upload.svg";
import { uploadDocument } from "../../store/slices/agent/agentSlice";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import { API_KEY, upload_preset } from "../../utils/baseUrl";
import CustomButton from "../../widgets/form/custom-button/CustomButton";

export default function ProfileForgotPIN({ changeStep }) {
  const { userData } = useSelector(loginSelector).login;
  const file = useRef(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [fileState, setFileState] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);

  const dispatch = useDispatch();
  function handleSubmit() {
    setLoading(true);
    let form = new FormData();
    form.append("file", fileState);
    form.append("upload_preset", upload_preset);
    form.append("api_key", API_KEY);
    form.append("folder", "docs");
    fetch(`https://api.cloudinary.com/v1_1/demo/image/upload`, {
      body: form,
      method: "POST",
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        dispatch(
          uploadDocument(
            {
              POF: res.secure_url,
              public_id: res.public_id,
              userId: userData.id,
            },
            { setLoading, setMessage }
          )
        );
      })
      .catch((err) => {
        setMessage({
          error: true,
          text: "File size can not be more than 200KB",
        });
      });
  }
  function handleChange(e) {
    const val = e.target.files[0];
    if (val.size / 1024 <= 200) {
      setFileState(val);
      setMessage(null);
    } else {
      setMessage({ error: true, text: "File size can not be more than 200KB" });
    }
  }

  useEffect(() => {
    let fileReader,
      isCancel = false;
    if (fileState) {
      fileReader = new FileReader();
      fileReader.onload = (e) => {
        const { result } = e.target;
        if (result && !isCancel) {
          setFileDataURL(result);
        }
      };
      fileReader.readAsDataURL(fileState);
    }
    return () => {
      isCancel = true;
      if (fileReader && fileReader.readyState === 1) {
        fileReader.abort();
      }
    };
  }, [fileState]);

  return (
    <>
      <div className="fw-bold">Proof of Identity</div>
      {/* <div className="extraSmallText mb-4">These information are verified and cannot be changed without the admin’s Permission</div> */}
      {message && typeof message.text === "string" && (
        <div className={message.error ? "text-danger" : "text-success"}>
          {message.text}
        </div>
      )}
      <div className="bgLightBlue text-center py-4 small customBorderRadius mb-4">
        {fileDataURL && (
          <img
            src={fileDataURL}
            className="mx-auto mb-2"
            alt="document"
            height={100}
          />
        )}
        <div onClick={() => file.current.click()} className="cursorPointer">
          <img className="mr-4" src={uploadIcon} width="20" alt="upload" />
          <small>Select document</small>
        </div>
        <div className="fw-bold my-2">{fileState?.name}</div>
        {loading && (
          <span>
            &nbsp;
            <Spinner animation="border" size="sm" />
          </span>
        )}
        <div className="my-2">(JPG, JPEG, PNG )</div>
        <CustomButton
          onClick={handleSubmit}
          sm
          text={"Upload"}
          color="primary"
        />
      </div>
      <input
        ref={file}
        type="file"
        accept=".jpg,.png,.jpeg"
        className="d-none"
        onChange={handleChange}
      />
      <ul>
        <li>Passport Photo</li>
        <li>Mandate document</li>
      </ul>
    </>
  );
}
