import CustomModal from "../../../widgets/modals/CustomModal";
import WithdrawFromWallet from "./WithdrawFromWallet";

export default function WithdrawFromWalletModal({open, onClose}) {
  return (
    <CustomModal
      open={open}
      position="right"
      onClose={onClose}
      dialogClassName="w-md-50"
      isFullscreen={true}
      title="Withdraw From Wallet"
    >
      <WithdrawFromWallet onClose={onClose} />
    </CustomModal>
  );
}
