import React, {useEffect, useState} from "react";
import PageHeader from "../../../widgets/page-header/PageHeader";
import SaversTransactions from "../../../components/savers/savers-transactions/SaversTransactions";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector} from "../../../store/slices/savers/saversSlice";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import {getTransactions} from "../../../store/slices/agent/agentSlice";
import Loader from "../../../widgets/others/Loader";

export default function SaverDetail() {
  const {userData: {id}} = useSelector(loginSelector).login;
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [saver, setSaver] = useState({});
  const {savers} = useSelector(saversSelector).savers;
  const {phone} = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();

  useEffect(() => {
    const saverFound = savers.find(each => each.phone === phone)
    saverFound ? setSaver(saverFound) : navigate('/savers');
    dispatch(getTransactions({
      agentId: id,
      accountId: saverFound.accounts[0].accountId
    }, {
      setLoading,
      setError,
      setTransactions
    }));
  }, [dispatch, id, navigate, phone, savers])

  useEffect(() => {
    setTransactions([...transactions].filter(each => each?.receiver?.id === saver?.id || each?.sender?.id === saver?.id))
  }, [transactions, saver])

  return (
    <>
      <PageHeader title="Saver Detail" saver={saver} />
      <div className="container pt-4">
        {/* <section className="row flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
          { walletDataCards.map((item, index) => <DataCard data={item} key={index} />)}
        </section> */}
        <section className="row">
          <div className="col-sm-12 p-2">
            {loading && <Loader height={'40vh'} />}

            {/* <div className="mb-5"><Filter /></div> */}
            {/* <Downloads /> */}
            {(transactions[0] && !loading && !error) &&
              <SaversTransactions transactions={transactions} />
            }
            {(!transactions[0] && !loading && !error) &&
              <div className="text-center py-5">This saver has no transactions yet</div>
            }
          </div>
        </section>
      </div>
    </>
  );
};
