import Modal from "react-bootstrap/Modal";

function positionClass(position) {
  return position === "right" ? "modalRight" : "left" ? "modalLeft" : "";
}

export default function CustomModal({
  open,
  onClose,
  title,
  position,
  dialogClassName,
  isFullscreen,
  children,
}) {
  return (
    <Modal
      show={open}
      onHide={onClose}
      fullscreen={isFullscreen}
      dialogClassName={`${positionClass(position)} ${dialogClassName}`}
      backdrop="static"
      keyboard={false}
      centered
    >
      {title && (
        <Modal.Header closeButton>
          <h3 className="fw-bold textPrimary">{title}</h3>
        </Modal.Header>
      )}
      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
}
