import dayjs from "dayjs";
import {useState} from "react";
import {formatNumberInThousand} from "../../../utils/functions";
import TransactionDetailsModal from "../../transactions/transaction-details/TransactionDetailsModal";

const tableHeader = [
  "Savers's Name",
  "Start Date",
  "Amount",
  "Type",
  "Account Number",
  "Action",
];

export default function SaversTransactions({transactions}) {
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState();

  function handleViewTransaction(tnx) {
    setSelectedTnx(tnx);
    setOpenTransactionModal(true);
  }

  return (
    <div className="bgWhite cardShadow p-3">
      {/* <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fw-bold">Recent Contribution Transactions</div>
        <button className="btn btn-sm textSecondary">View All</button>
      </div> */}
      <div className="table-responsive-lg">
        <table className="table fw-bold small">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {transactions.map((item, index) => (
              <tr key={index}>

                <td>{item.receiver.first_name} {item.receiver.last_name}</td>
                <td>
                  {dayjs(item.createdAt).format("MMM DD, YYYY")} | {new Date(item.createdAt).toLocaleTimeString()}</td>
                <td>
                  {item.type === "Withdrawal" ? (
                    <span className="text-danger">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  ) : (
                    <span className="">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  )}
                </td>
                <td>
                  {item.transaction_type}
                </td>
                <td>
                  {item.receiver.account_number}
                </td>
                <td>
                  <button
                    className="btn btn-sm fw-bold"
                    onClick={() => handleViewTransaction(item)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <TransactionDetailsModal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        transaction={selectedTnx}
      />
    </div>
  );
}
