import styles from "./alert-warning.module.css";
import caution from "../../../assets/icons/payout-savings/caution.svg";


export default function AlertWarning({title, content}) {
    if(title || content) {
        return (
            <div className={styles.alert}>
                <div className="me-3"><img src={caution} width="20" alt="c" /></div>
                <div>
                    {title && <div className="fw-bold mb-2">{title}</div>}
                    {content && <div>{content}</div>}
                </div>
            </div>
        )
    }
    else return null;
}