import PropTypes from "prop-types";
import CustomIcon from "../../icon/CustomIcon";

export default function CustomBackButton({ currentStep, setStep, onBack }) {
  function handleChangeStep() {
    if (currentStep !== null) return setStep(currentStep - 1);
    onBack();
  }

  return (
    <button
      className="btn btn-sm bg-white mb-3 d-flex align-items-center"
      onClick={handleChangeStep}
    >
      <CustomIcon name="arrowLeftWithBg" size={15} color="black" /> &nbsp;
      <span>Back</span>
    </button>
  );
}

CustomBackButton.propTypes = {
  currentStep: PropTypes.number,
  setStep: PropTypes.func,
  onBack: PropTypes.func,
};

CustomBackButton.defaultProps = {
  currentStep: null,
};
