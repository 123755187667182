import {Link, useLocation} from "react-router-dom";
import styles from "../components/profile/profile.module.css";
import DownloadAllDocuments from "../components/profile/DownloadAllDocuments";
import ProfileCard from "../components/profile/profile-card/ProfileCard";
import AlertWarning from "../widgets/alerts/warning/AlertWarning";
import {useEffect, useState} from "react";

const profileItems = [
  {name: "Personal Info", path: "/profile/personal-info"},
  {name: "Bank Details", path: "/profile/bank-details"},
  {name: "Proof of Address", path: "/profile/proof-of-address"},
  {name: "Guarantor's Information", path: "/profile/guarantors-information"},
  {name: "Proof of Identity", path: "/profile/proof-of-identity"},
];

const settingsItems = [
  {name: "Reset Password", path: "/profile/reset-password"},
  // {name: "Change Password", path: "/profile/change-password"},
  {name: "Reset PIN", path: "/profile/reset-pin"},
];

const myStyles = {
  linkActive: {
    color: "#ffffff",
    backgroundColor: "#355BCF",
  },
};

export default function LayoutProfile({children}) {
  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState("/personal-info");

  function checkSelected(path) {
    return path === currentRoute ? myStyles.linkActive : {};
  }

  useEffect(() => {
    setCurrentRoute(location?.pathname);
  }, [location]);

  return (
    <>
      <DownloadAllDocuments />
      <div className="container py-md-4">
        <div className="mt-3 mb-4">
          <ProfileCard />
        </div>
        <AlertWarning
          title="Complete your Verification"
          content="To complete  your  Profile , kindly provide your Guarantor’s Info , Proof of  Identity and Proof Of Address to get your account verified"
        />
        <div className="row mt-5">
          <div className="col-sm-12 col-md-3 col-lg-3 px-md-0 py-3 bgLightBlue">
            <div
              className={[
                "extraSmallText textPrimary mb-3",
                styles.listTitle,
              ].join(" ")}
            >
              Profile
            </div>
            <div className="row">
              {profileItems.map((item, index) => (
                <div
                  className="col-sm-6 col-md-12 col-6 d-flex justify-content-stretch"
                  key={index}
                >
                  <Link
                    to={item.path}
                    className={[styles.link].join(" ")}
                    style={checkSelected(item.path)}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>
            <div
              className={[
                "extraSmallText textPrimary my-3",
                styles.listTitle,
              ].join(" ")}
            >
              Settings
            </div>
            <div className="row">
              {settingsItems.map((item, index) => (
                <div
                  className="col-sm-6 col-md-12 col-6 d-flex justify-content-stretch"
                  key={index}
                >
                  <Link
                    key={index}
                    to={item.path}
                    className={[styles.link].join(" ")}
                    style={checkSelected(item.path)}
                  >
                    {item.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
          <div className="col-sm-12 col-md-9 col-lg-9 py-3 p-md-5">
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-9">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
