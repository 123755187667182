import PropTypes from "prop-types";
import Avatar from "../avatar/Avatar";
import CustomIcon from "../icon/CustomIcon";
import styles from "./search-dropdown.module.css";

export default function SearchDropdown({isActive, options, onClick, }) {

  if (!isActive) return null;

  if (isActive && options.length < 1)
    return (
      <div className={["bg-white", styles.options__container].join(" ")}>
        No details match
      </div>
    );

  if (isActive)
    return (
      <div
        className={["bg-white", styles.options__container].join(" ")}
        title="suggestion-dropdown"
      >
        {options?.map((item, index) => (
          <Option
            saver={item}
            key={index}
            onClick={() => onClick(item)}
          />
        ))}
      </div>
    );
}

function Option({saver, onClick}) {
  return (
    <div
      className="d-flex justify-content-between align-items-center py-2 px-2 border-bottom hoverLightBlue cursorPointer"
      onClick={onClick}
    >
      <div className="d-flex align-items-center w-100">
        {!saver?.bankName && <Avatar name={saver?.option} />}
        <div className="ms-2">{saver.first_name} {saver.last_name} {saver.bankName ?? ''}</div>
      </div>
      <div className="d-flex align-items-center">
        <div className="me-2">
          {saver?.bank === "GTB" && <CustomIcon name="gtBank" />}
        </div>
        <div>{saver.account_number}</div>
      </div>
    </div>
  );
}

SearchDropdown.propTypes = {
  isActive: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  option: PropTypes.string,
  bank: PropTypes.string,
  accountNumber: PropTypes.string,
  onClick: PropTypes.func,
};

SearchDropdown.defaultProps = {
  options: [],
};

Option.propTypes = {
  item: PropTypes.object,
  option: PropTypes.string,
  bank: PropTypes.string,
  accountNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};
