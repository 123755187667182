import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {confirmPin, loginSelector, setLoginStep} from "../../../store/slices/auth/loginSlice";
import ReactCodeInput from "react-code-input";
import ACBtn from "../../../widgets/form/ACBtn";

export default function OTPLogin() {
  const {loading, message, userData: {id}} = useSelector(loginSelector).login;
  const [pin, setPin] = useState("");
  const dispatch = useDispatch();

  function handleSubmit(e) {
    e.preventDefault()
    if (pin.length < 4) return;
    dispatch(confirmPin({id, pin}));
  }

  const props = {
    onChange: setPin,
    type: 'password',
    fields: 4,
    className: `[display:flex_!important] justify-between auth`,
    disabled: loading,
    inputMode: 'numeric',
    // isValid: states.validCode,
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='cfade max-w-md py-20 mx-[auto] md:mx-0 px-3 lg:pl-8 flex min-h-screen flex-col'>
        <h3 className='text-light-blue text-2xl md:text-[2rem] font-bold'>Enter PIN </h3>
        <p className='text-sm'>Type in your 4-digit PIN to continue </p>
        <p className="text-xs mt-2 text-red-600">{message}</p>
        <div className='flex flex-col gap-1 pt-7'>
          <ReactCodeInput {...props} /> <br />
          <div className='flex justify-between'>
            <button type='button' onClick={() => dispatch(setLoginStep(1))} className='gr-btn' disabled={loading}>Back</button>
            <ACBtn type='submit' text={'Continue'} loading={loading} disabled={(pin.length !== 4) || loading} className={'max-w-[8rem]'} />
          </div>
        </div>
      </form>
    </>
  );
}
