import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";
import {states} from "../../../utils/constants";
import {useState} from "react";

const validationSchema = Yup.object({
	str_address: Yup.string("Enter saver's Street address")
		.required("Street Address is required"),
	state: Yup.string("Select saver's State")
		.required("Saver's state is required"),
	lga: Yup.string("Select saver's local government")
		.required("Saver's local government is required"),
});

export default function AddAdress({changeStep}) {
	const {newSaverDetails, newSaverStep} = useSelector(saversSelector).savers;
	const dispatch = useDispatch();
	const [lgas, setlgas] = useState([]);
	const formik = useFormik({
		initialValues: {
			str_address: newSaverDetails?.str_address ?? "",
			state: newSaverDetails?.state ?? '',
			lga: newSaverDetails?.lga ?? '',
		},
		validationSchema,
		onSubmit: (values) => handleSubmit(values),
	});

	function handleSubmit(values) {
		dispatch(setNewSaverDetails(values))
		dispatch(setNewSaverStep(3));
	}

	function handleGoBack() {
		dispatch(setNewSaverStep(newSaverStep - 1));
	}

	const stateChange = (e) => {
		formik.handleChange(e)
		const stateFound = states.find(each => each.state === e.target.value);
		setlgas(stateFound.lgas);
	}
	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="mb-3">
				<CustomLabel htmlFor="str_address" content="Home/Street Address" />
				<CustomInput
					type="text"
					placeholder="Address"
					aria-label="Address"
					aria-describedby="str_address"
					name="str_address"
					onChange={formik.handleChange}
					value={formik.values.str_address}
				/>
				{formik.touched.str_address && (
					<div className="form-text text-danger">{formik.errors.str_address}</div>
				)}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="state" content="State" />
				<select className="form-select customFormControl" name="state" onChange={stateChange} aria-label="state" aria-describedby="state">
					<option value="">Open this select menu</option>
					{states.map((item, index) => <option key={item.state} value={item.state}>{item.state}</option>)}
				</select>
				{formik.touched.state && <div className="form-text text-danger">{formik.errors.state}</div>}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="lga" content="Local government" />
				<select className="form-select customFormControl" name="lga" onChange={formik.handleChange} aria-label="lga" aria-describedby="lga">
					<option value="">Open this select menu</option>
					{lgas.map((item, index) => <option key={item} value={item}>{item}</option>)}
				</select>
				{formik.touched.lga && <div className="form-text text-danger">{formik.errors.lga}</div>}
			</div>
			<div className="d-flex justify-content-between">
				<button
					className="btn customButton customGreyButton"
					onClick={handleGoBack}
					type="button"
				>
					Back
				</button>
				<button type="submit" className="btn customButton customPrimaryButton">
					Next
				</button>
			</div>
		</form >
	);
}
