import PropTypes from "prop-types";
import {Spinner} from "react-bootstrap";

export default function CustomButton({type, color, sm, text, disabled, onClick, loading}) {
  return (
    <button
      type={type || "button"}
      disabled={disabled || loading}
      onClick={onClick}
      className={`btn ${sm ? 'customSmButton' : 'customButton'} ${color === "primary"
        ? "customPrimaryButton text-white"
        : color === "secondary"
          ? "customSecondaryButton"
          : "grey"
            ? "customGreyButton"
            : ""
        }`}
    >
      {text
        ? text
        : !text && type === "submit"
          ? "Submit"
          : !text && type === "reset"
            ? "Reset"
            : ""}
      {loading && <span>&nbsp;<Spinner animation="border" size="sm" /></span>}
    </button>
  );
}

CustomButton.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};
