import {useEffect} from "react";
import PageHeader from "../../../widgets/page-header/PageHeader";
import AddBankDetails from "../../../components/savers/add-saver/AddBankDetails";
import AddContributionInfo from "../../../components/savers/add-saver/AddContributionInfo";
import AddPersonalDetails from "../../../components/savers/add-saver/AddPersonalDetails";
import {saversSelector, setNewSaverMessage, setNewSaverStep} from "../../../store/slices/savers/saversSlice";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import NextOfKin from "../../../components/savers/add-saver/NextofKin";
import AddAdress from "../../../components/savers/add-saver/AddAddress";
import IncomeRange from "../../../components/savers/add-saver/IncomeRange";
import EmploymentStatus from "../../../components/savers/add-saver/EmploymentState";

export default function AddSaver() {
    const {newSaverMessage, newSaverStep} = useSelector(saversSelector).savers;
    const dispatch = useDispatch();
    useEffect(() => {
        // set this to always override the value coming from persist:root saved in localstorage 
        dispatch(setNewSaverStep(1));
        dispatch(setNewSaverMessage(null))
    }, [dispatch])

    return (
        <>
            <PageHeader title="Add New Saver" />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="fw-bold">
                                {newSaverStep === 1 && "Personal Details"}
                                {newSaverStep === 2 && "Address"}
                                {newSaverStep === 3 && "Bank Details"}
                                {newSaverStep === 4 && "Select your monthly income Range"}
                                {newSaverStep === 5 && "Employment Status"}
                                {newSaverStep === 6 && "Provide Next of kin"}
                                {newSaverStep === 7 && "Contribution Info"}
                            </div>
                            <div className="fw-bold">
                                <span className="h3 fw-bold">{newSaverStep}</span>/7
                            </div>
                        </div>
                        {newSaverMessage && <span className={newSaverMessage.error ? 'text-danger' : 'text-success'}>{newSaverMessage.message}</span>}
                    </div>
                    <div className="col-sm-12 col-md-8 col-lg-6 col-xl-5">
                        <div className="customFormCard">
                            {newSaverStep === 1 && <AddPersonalDetails />}
                            {newSaverStep === 2 && <AddAdress />}
                            {newSaverStep === 3 && <AddBankDetails />}
                            {newSaverStep === 4 && <IncomeRange />}
                            {newSaverStep === 5 && <EmploymentStatus />}
                            {newSaverStep === 6 && <NextOfKin />}
                            {newSaverStep === 7 && <AddContributionInfo />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}