import PropTypes from "prop-types";
import CustomIcon from "../icon/CustomIcon";

export default function CustomList({
  icon,
  title,
  iconColor,
  subtitle,
  titleColor,
  subtitleColor,
  isClickable,
  onClick,
}) {
  function handleClick() {
    if (!isClickable) return;
    onClick();
  }

  return (
    <div
      className={`d-flex align-items-center ${
        isClickable ? "cursorPointer" : ""
      }`}
      onClick={handleClick}
    >
      {icon && (
        <div className="me-2">
          <CustomIcon name={icon} size={30} color={iconColor} />
        </div>
      )}
      <div className="textPriamry">
        {title && (
          <div className="fw-bold" style={{ color: titleColor }}>
            {title}
          </div>
        )}
        {subtitle && (
          <div className="extraSmallText" style={{ color: subtitleColor }}>
            {subtitle}
          </div>
        )}
      </div>
    </div>
  );
}

CustomList.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  iconColor: PropTypes.string,
  subtitle: PropTypes.string,
  titleColor: PropTypes.string,
  subtitleColor: PropTypes.string,
  isClickable: PropTypes.bool,
  onClick: PropTypes.func,
};
