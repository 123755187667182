import React, {useEffect, useState} from "react";
import DataCard from "../../components/dashboard/data-card/DataCard";
import PageHeader from "../../widgets/page-header/PageHeader";
import {nairaSign, walletDataCards} from "../../utils/constants";
import RecentWalletTransactions from "../../components/wallet/RecentWalletTransactions";
import Filter from "../../widgets/filter/Filter";
import CustomIcon from "../../widgets/icon/CustomIcon";
import FundWalletModal from "../../components/wallet/fund-wallet/FundWalletModal";
import WithdrawFromWalletModal from "../../components/wallet/withdraw-from-wallet/WithdrawFromWalletModal";
import CustomConfirmModal from "../../widgets/modals/CustomConfirmModal";
import CustomList from "../../widgets/list/CustomList";
import {formatNumberInThousand} from "../../utils/functions";
import CustomInput from "../../widgets/form/custom-input/CustomInput";
import CustomModal from "../../widgets/modals/CustomModal";
import SuccessFeedback from "../../widgets/feedbacks/SuccessFeedback";
import {useDispatch} from "react-redux";
import {getBalance} from "../../store/slices/agent/agentSlice";
import {useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";

const availableOverdraft = 100000;

export default function Wallet() {
  const {userData: {id, accounts}} = useSelector(loginSelector).login
  const [openFundModal, setOpenFundModal] = useState(false);
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openBorrowModal, setOpenBorrowModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [amount, setAmount] = useState(null);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  function handleCloseFundModal() {
    setOpenFundModal(false);
  }
  function handleCloseWithdrawModal() {
    setOpenWithdrawModal(false);
  }
  function handleCloseBorrowModal() {
    setError("");
    setOpenBorrowModal(false);
  }
  function handleCloseSuccessModal() {
    setOpenSuccessModal(false);
  }

  function handlePerformAction(action) {
    if (action === "withdraw") setOpenWithdrawModal(true);
    if (action === "fund") setOpenFundModal(true);
    if (action === "borrow") {
      setOpenBorrowModal(true);
      setAmount(null);
    }
  }

  function handleChangeAmount(e) {
    setAmount(e.target.value);
  }

  function handleSubmitAmount() {
    if (parseInt(amount) > 99 && parseInt(amount) <= availableOverdraft) {
      setError("");
      setOpenSuccessModal(true);
      handleCloseBorrowModal();
      return;
    }
    setError(
      `Please enter an amount between ${nairaSign}100 and ${nairaSign + formatNumberInThousand(availableOverdraft)
      }`
    );
  }

  useEffect(() => {
    let isInValid =
      isNaN(amount) ||
      amount === null ||
      amount === undefined ||
      amount < 99 ||
      amount > availableOverdraft;

    setIsPrimaryButtonDisabled(isInValid);

  }, [amount]);

  useEffect(() => {
    dispatch(getBalance({
      accountId: accounts[0].accountId,
      agentId: id
    }, {
      setBalance,
      setLoading
    }))
  }, [id, accounts, dispatch])

  return (
    <>
      <PageHeader title="Wallet" withSearch={true} />
      <div className="container pt-4">
        <section className="row d-flex flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
          {walletDataCards.map((item, index) => (
            <DataCard data={{...item, value: balance, loading}} key={index}>
              {item?.actions?.map((act, i) => (
                <WalletAction
                  key={i}
                  data={act}
                  onClick={handlePerformAction}
                />
              ))}
            </DataCard>
          ))}
        </section>
        <section className="row">
          <div className="col-sm-12 p-2">
            <Filter types={["Withdrawal", "Deposit"]} />
            <RecentWalletTransactions />
          </div>
        </section>
      </div>
      <FundWalletModal open={openFundModal} onClose={handleCloseFundModal} />

      <WithdrawFromWalletModal
        open={openWithdrawModal}
        onClose={handleCloseWithdrawModal}
      />

      <CustomConfirmModal
        open={openBorrowModal}
        onClose={handleCloseBorrowModal}
        title="Borrow from Overdraft"
        subtitle="Borrow an amount from the overdraft available . The repayment date will be shown on your Wallet Page"
        icon="borrowFromOverdraft"
        position="right"
        isPrimaryButtonDisabled={isPrimaryButtonDisabled}
        onConfirm={handleSubmitAmount}
      >
        <div className="d-flex justify-content-center mb-5">
          <CustomList
            icon="overdraftCircle"
            title={`${nairaSign}${formatNumberInThousand(
              availableOverdraft.toFixed(2)
            )}`}
            subtitle="Available Overdraft"
          />
        </div>
        <CustomInput
          type="number"
          name=""
          id="amount"
          labelText="Amount"
          labelHtmlFor="amount"
          placeholder="Enter amount"
          onChange={handleChangeAmount}
        />
        {error && <div className="text-danger small mt-2">{error}</div>}
      </CustomConfirmModal>

      <CustomModal open={openSuccessModal} onClose={handleCloseSuccessModal}>
        <SuccessFeedback
          title="Successful"
          content={`You have successfully taken ${nairaSign + " " + formatNumberInThousand(amount)
            } overdraft.`}
          buttonText="Ok"
          onClick={handleCloseSuccessModal}
        />
      </CustomModal>
    </>
  );
}

function WalletAction({data, onClick}) {
  return (
    <div
      className="text-center me-3 cursorPointer"
      onClick={() => onClick(data.action)}
    >
      <CustomIcon name={data.icon} size={25} />
      <div className="extraSmallText text-capitalize">{data.text}</div>
    </div>
  );
}
