import { useFormik } from "formik";
import CustomButton from "../../../../../widgets/form/custom-button/CustomButton";
import CustomForm from "../../../../../widgets/form/CustomForm";
import { cardPaymemtFields } from "../../../../../utils/formFields";
import { cardPaymentSchema as validationSchema } from "../../../../../utils/yupSchemas";

export default function CardPayment({ callSuccess }) {
  const formik = useFormik({
    initialValues: {
      amount: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  function handleSubmit(values) {
    callSuccess();
  }

  return (
    <>
      <div className="fw-bold">Card</div>
      <div className="extraSmallText mb-4">
        Adding Money with a Naira Card is Free for upt to 100,000 after which
        you will be charge 100naira plus 1.5% of the amount
      </div>
      <form onSubmit={formik.handleSubmit}>
        {cardPaymemtFields.map((field, index) => (
          <CustomForm item={field} formik={formik} key={index} />
        ))}
        <div className="d-flex justify-content-end">
          <CustomButton type="submit" text="Fund Wallet" color="primary" />
        </div>
      </form>
    </>
  );
}
