import { Link, useLocation } from "react-router-dom";
import { navbarItems } from "../../../utils/constants";

export default function NavSidebarContent() {
  const location = useLocation();

  function isCurrentRoute(path) {
    const currentPath = location?.pathname;
    return path === currentPath;
  }

  return (
    <ul className="list-group mx-0">
      {navbarItems.map((item, index) => (
        <li
          className={[
            `list-group-item bg-transparent py-3 ${
              isCurrentRoute(item.path) ? "active text-white" : "text-white-50"
            }`,
          ].join(" ")}
          aria-current={isCurrentRoute(item.path)}
          key={index}
        >
          <Link
            className="text-decoration-none fw-bold lead d-block"
            style={{ color: "inherit" }}
            to={item.path}
          >
            {item.name}
          </Link>
        </li>
      ))}
    </ul>
  );
}
