import React from 'react'
import PropTypes from 'prop-types'
import {Spinner} from 'react-bootstrap'

const ACBtn = ({loading, children, className, text, ...props}) => {
	return (
		<button className={`rounded-[.75rem] bg-light-blue text-white text-base w-full flex gap-2 items-center justify-center h-14 hover:gradient1 transition-all duration-700 ${className ?? ''}`} disabled={loading} {...props}>
			{loading &&
				<Spinner animation="border" size="sm" />
			}

			<span>	{text}</span>
		</button>
	)
}

ACBtn.propTypes = {
	loading: PropTypes.bool,
	className: PropTypes.string,
	text: PropTypes.string,
	props: PropTypes.any,
}

export default ACBtn