import PageHeader from "../../widgets/page-header/PageHeader";
import DepositSavingsForm from "../../components/deposit-savings/DepositSavingsForm";

export default function DepositSavings() {

  return (

    // <>
    //   <PageHeader title="Deposit Savings" />
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-sm-12 col-md-8 col-lg-6">
    //         {/* {step === 1 && <AlertWarning content="Savers will be charged N100 on every N5000, if the payout is done before the set collection date " />} */}
    //         <div className="d-flex justify-content-between align-items-center mb-3 mt-5">
    //           {/* <div className="fw-bold">
    //                             {step === 1 && "Recent"}
    //                         </div> */}
    //         </div>
    //         {/* {step === 1 && <RecentPayoutSavers />} */}
    //         <div className="customFormCard mt-3 py-5">
    //           {step === 1 && <PayoutSavingsForm setPayoutValues={setPayoutValues} changeStep={handleChangeStep} />}
    //           {step === 2 && <PayoutSavingsSuccess payoutValues={payoutValues} />}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </>
    <>
      <PageHeader title="Deposit Savings" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-8 col-lg-6">
            {/* <div className="fw-bold mb-3">Recent</div> */}

            {/* <RecentSavers /> */}
            <div className="customFormCard mt-3 py-5">
              <DepositSavingsForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
