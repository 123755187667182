import React, { useEffect, useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import Filter from "../../widgets/filter/Filter";
import RecentContributionTransactions from "../../components/transactions/RecentContributionTransactions";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import { useSelector } from "react-redux";
import { getTransactions } from "../../store/slices/agent/agentSlice";
import { useDispatch } from "react-redux";
import Loader from "../../widgets/others/Loader";

const types = ["Withdrawal", "Deposit"];

export default function Transactions() {
  const {
    userData: { id, accounts },
  } = useSelector(loginSelector).login;
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState(
    transactions
  );
  const [searchedTransactions, setSearchedTransactions] =
    useState(filteredTransactions);
  const [searchValue, setsearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const setFilter = (values) => {
    const prefetch = [...transactions]
      .reverse()
      .filter(
        (each, i) =>
          (new Date(each.createdAt).getDate() >=
            new Date(values.start_date).getDate() &&
            new Date(each.createdAt).getDate() <=
            new Date(values.end_date).getDate()) ||
          (new Date(each.createdAt).getDate() <=
            new Date(values.start_date).getDate() &&
            new Date(each.createdAt).getDate() >=
            new Date(values.end_date).getDate())
      );
    setFilteredTransactions(
      prefetch.filter(
        (each, i) =>
          Number(values.entries) === 0 || i + 1 <= Number(values.entries)
      )
    );
  };

  useEffect(() => {
    dispatch(
      getTransactions(
        {
          agentId: id,
          accountId: accounts[0].accountId,
        },
        {
          setLoading,
          setError,
          setTransactions,
        }
      )
    );
  }, [dispatch, accounts, id]);

  useEffect(() => {
    setSearchedTransactions(filteredTransactions);
    searchTransactions(searchValue);
    //eslint-disable-next-line
  }, [filteredTransactions]);

  useEffect(() => {
    setFilteredTransactions([...transactions].reverse());
  }, [transactions]);

  const searchTransactions = (searchText) => {
    setsearchValue(searchText);
    setSearchedTransactions(
      // filteredTransactions
      filteredTransactions.filter(
        (each, i) =>
          // each?.receiver?.first_name
          //   .toLowerCase()
          //   .includes(searchText.toLowerCase()) ||
          // each?.receiver?.last_name
          //   .toLowerCase()
          //   .includes(searchText.toLowerCase())
          each?.description
            .toLowerCase()
            .includes(searchText.toLowerCase())
      )
    );
  };

  return (
    <>
      <PageHeader
        title="Transactions"
        withSearch={true}
        search={searchTransactions}
      />
      <div className="container pt-4">
        {loading && <Loader height={"40vh"} />}

        {transactions[0] && !loading && !error && (
          <section className="row">
            <div className="col-sm-12 p-2">
              <div className="mb-5">
                <Filter isTransaction setFilter={setFilter} types={types} />
              </div>
              {/* <Downloads /> */}
              <RecentContributionTransactions
                transactions={searchedTransactions}
              />
            </div>
          </section>
        )}

        {
          !transactions[0] && !loading && !error && (
            <div className="text-center py-3">No transactions yet</div>
          )
        }
      </div>
    </>
  );
}
