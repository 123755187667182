import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getSavers, newSaver, saversSelector, setNewSaverStep} from "../../../store/slices/savers/saversSlice";
import {useDispatch, useSelector} from "react-redux";
import CustomButton from "../../../widgets/form/custom-button/CustomButton";
import {useState} from "react";
import {loginSelector} from "../../../store/slices/auth/loginSlice";

const validationSchema = Yup.object({
    saving_type: Yup.string("Select contribution type")
        .required("Contribution type is required"),
    savings_duration: Yup.string("Saving duration")
        .required("Saving duration is required")
});

const saving_types = ["Daily", "Weekly", "Monthly", "Nil"];
const savings_durations = [
    {name: "4 weeks", value: 28},
    {name: "8 weeks", value: 56},
    {name: "12 weeks", value: 84},
    {name: "16 weeks", value: 112},
    {name: "Prefer not to choose", value: "NIL"},
    /*"Let me choose"*/
];

export default function AddContributionInfo({changeStep}) {
    const {userData: {id}} = useSelector(loginSelector).login;
    const {newSaverDetails, newSaverStep} = useSelector(saversSelector).savers;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const formik = useFormik({
        initialValues: {
            saving_type: "",
            savings_duration: ""
        },
        validationSchema,
        onSubmit: (values) => handleSubmit({...values, agentId: id}),
    });

    async function handleSubmit(values) {
        setLoading(true);
        await dispatch(newSaver({...newSaverDetails, ...values}))
        setLoading(false);
        // update available savers in store
        dispatch(getSavers({agentId: id}));
    }

    function handleGoBack() {
        dispatch(setNewSaverStep(newSaverStep - 1));
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="mb-4">
                <CustomLabel htmlFor="saving_type" content="Contribution Type" />
                {saving_types.map((item, index) => (
                    <div className="form-check mb-3" key={index}>
                        <input
                            className="form-check-input"
                            type="radio"
                            name="saving_type"
                            aria-label="saving_type"
                            aria-describedby="saving_type"
                            // checked={formik.values.savings_duration === item}
                            onChange={formik.handleChange}
                            value={item.toLowerCase()}
                        />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                            {item}
                        </label>
                    </div>
                ))}
                {formik.touched.saving_type && <div className="form-text text-danger">{formik.errors.saving_type}</div>}
                <div className="w-75 small">You can not  modify your savings frequency, anytime after creating this plan but can create another</div>
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="savings_duration" content="Saving Duration" />
                <div className="small w-75 mb-3">This Saving duration will determine saver’s set collection date</div>
                {savings_durations.map((item, index) => (
                    <div className="d-flex" key={index}>
                        <div className="form-check mb-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="savings_duration"
                                // checked={formik.values.savings_duration === item}
                                aria-label="savings_duration"
                                aria-describedby="savings_duration"
                                onChange={formik.handleChange}
                                value={item.value}
                            />
                            <label className="form-check-label" htmlFor="savings_duration">
                                {item.name}
                            </label>
                        </div>
                        {/* <div className="ms-3">
                            {index === (savings_durations.length - 1) && <input placeholder="Number of days" type="text" />}
                        </div> */}
                    </div>
                ))}
                {formik.touched.savings_duration && <div className="form-text text-danger">{formik.errors.savings_duration}</div>}
                <div className="w-75 small">You can not  modify your savings frequency, anytime after creating this plan but can create another</div>
            </div>
            <div className="d-flex justify-content-between">
                <button className="btn customButton customGreyButton" onClick={handleGoBack} type="button">Back</button>
                <CustomButton
                    type="submit"
                    loading={loading}
                    disabled={loading}
                    color="primary"
                />
            </div>
        </form>
    )
}
