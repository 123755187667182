import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
	mailIsSending: false,
	mailSent: false,
	step: 1,
	message: null,
};

export const forgetPasswordSlice = createSlice({
	name: "forgetPassword",
	initialState,
	reducers: {
		setStep: (state, {payload}) => {
			state.step = payload;
			state.message = null;
		},
		setMailIsSending: (state, {payload}) => {
			state.mailIsSending = payload;
			state.message = null;
		},
		setMessage: (state, {payload}) => {
			state.message = JSON.parse(payload);
			state.mailIsSending = false;
		},
		setMailSent: (state) => {
			state.mailIsSending = false;
			state.message = JSON.stringify({
				message: "Mail sent"
			})
		},
	},
});

//actions
export const {
	setMessage,
	setStep,
	setMailIsSending,
	setMailSent
} = forgetPasswordSlice.actions;

//selectors
export const forgetPasswordSelector = (state) => state;

//reducer
export default forgetPasswordSlice.reducer;

export const sendOTP = async (details, {setMessage, changeStep, setAgentId}) => {
	try {
		const result = await axios.post(`${baseUrl}auth/forget-password`, details);
		const data = result.data;
		if (data.status || data.data) {
			changeStep(2)
			setAgentId("uayoubouhjbo");
			setMessage({
				text: (data.message && typeof (data.message) == 'string') ? data.message : "OTP sent to phone number"
			})
		} else {
			setMessage({
				error: true,
				text: (data.message && typeof (data.message) == 'string')
					? data.message : 'An error occured'
			})
		}
	} catch (error) {
		if (error.response) {
			let err = error.response;
			setMessage({
				error: true,
				text: (err.message && typeof (err.message) == 'string')
					? err.message : 'An error occured'
			})
		} else {
			setMessage({
				error: true,
				text: 'An error occured, please check your network connection.'
			})
		}
	}
};


export const confirmOtpAndResetPassword = async (details, {setMessage, changeStep}) => {
	try {
		const result = await axios.post(`${baseUrl}auth/verify_otp_and_reset_password`, details);
		const data = result.data;
		if (data.status || data.data) {
			changeStep(3)
			setMessage({
				text: (data.message && typeof (data.message) == 'string') ? data.message : "OTP sent to phone number"
			})
		} else {
			setMessage({
				error: true,
				text: (data.message && typeof (data.message) == 'string')
					? data.message : 'An error occured'
			})
		}
	} catch (error) {
		if (error.response) {
			let err = error.response;
			setMessage({
				error: true,
				text: (err.message && typeof (err.message) == 'string')
					? err.message : 'An error occured'
			})
		} else {
			setMessage({
				error: true,
				text: 'An error occured, please check your network connection.'
			})
		}
	}
};

