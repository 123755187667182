import React from 'react'
import {Outlet} from 'react-router-dom'
// import {useEffect} from 'react';
// import toast from 'react-hot-toast';
// import {useSelector} from 'react-redux';
// import {Navigate, Outlet, useLocation} from 'react-router-dom';
// import {loginSelector} from '../store/slices/auth/loginSlice';

const IsVerified = () => {
	// const {userData: {verified, guarantors, kycs}} = useSelector(loginSelector).login;
	// const location = useLocation("");
	// useEffect(() => {
	// if (!(verified && guarantors[0] && kycs[0])) {
	// 	toast.error("Account not verified, add your guarantors and updated your proof of Identity to verify your account", {duration: 5000})
	// 	return <Navigate to={`/`} />
	// } else {
	return <Outlet />
	// }
	// }, [verified, guarantors, kycs])
}

export default IsVerified