import React from "react";
import styles from "./page-header.module.css";
import {Link, useLocation, useNavigate} from "react-router-dom";
import CustomIcon from "../icon/CustomIcon";
import dayjs from "dayjs";

const PageHeader = ({title, withSearch, showNewSaverButton, withNewLoan, saver, search}) => {
  const navigate = useNavigate();
  const location = useLocation();
  function handleGoBack() {
    navigate(-1);
  }

  return (
    <div className="bgPrimary py-4 mb-4">
      <div className="container-fluid px-md-5">
        {location?.pathname !== "/" && (
          <button
            onClick={handleGoBack}
            className="btn text-white d-flex align-items-center ps-0 mb-4"
          >
            <CustomIcon name="arrowLeftWithBg" color="white" /> &nbsp;Back
          </button>
        )}
        {saver && (
          <div className="row text-white">
            <div className="col-sm-12 col-md-4 mb-3">
              <h5 className="fw-bold text-white">Savers Detail</h5>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-sm-12 col-md-3 col-lg-2 col-12 mb-2">
                  <DetailCard
                    title="Saver's Name"
                    content={`${saver.first_name} ${saver.last_name}`}
                  />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-1 col-4 mb-2">
                  <DetailCard title="Amount" content={saver.collection_amount} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-1 col-4 mb-2">
                  <DetailCard title="Saving Type" content={saver.saving_type} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-1 col-4 mb-2">
                  <DetailCard title="Duration" content={typeof (saver.savings_duration) == "string" ? saver.savings_duration : saver.savings_duration / 7 + 'weeks'} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-2 col-4 mb-2">
                  <DetailCard title="Start Date" content={dayjs(saver.createdAt).format("MMM DD, YYYY")} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-2 col-4 mb-2">
                  <DetailCard title="Payout Date" content={dayjs(saver.collection_date).format("MMM DD, YYYY")} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-1 col-4 mb-2">
                  <DetailCard title="Phone Number" content={saver.phone} />
                </div>
                <div className="col-sm-4 col-md-3 col-lg-2 col-4 mb-2">
                  <DetailCard title="Account Number" content={saver.accounts ? saver.accounts[0].accountNumber : ''} />
                </div>
              </div>
            </div>
          </div>
        )}
        {!saver && (
          <div className="row">
            <div className="col-sm-12 col-md-4 col-7">
              <h3 className="fw-bold text-white">{title}</h3>
            </div>
            <div className="col-sm-12 col-md-4 col-5 text-end text-md-center">
              {withNewLoan && (
                <Link to="/loans/new" className="btn bgSecondary text-white">
                  New Loan
                </Link>
              )}
              {showNewSaverButton && (
                <Link to="/savers/add" className="btn bgSecondary text-white">
                  New Saver
                </Link>
              )}
            </div>
            <div className="col-sm-12 col-md-4 col-12">
              {withSearch && (
                <div className="input-group mb-3 mt-2 mt-md-0">
                  <input
                    type="text"
                    // value={searchText}
                    onChange={(e) => search(e.target.value)}
                    className={[
                      "text-left form-control",
                      styles.pageHeaderSearchInput,
                    ].join(" ")}
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                  />
                  <span className="input-group-text" id="basic-addon2">
                    <CustomIcon name="search" size={15} />
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;

function DetailCard({title, content}) {
  return (
    <div>
      <div className="extraSmallText">{title}</div>
      <div className="fw-bold small text-capitalize">{content}</div>
    </div>
  );
}
