import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
  step: 2,
  modalIsOpen: false,
  loading: false,
  userData: null,
  message: null,
  error: null,
  success: false
};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setLoading: (state, {payload}) => {
      state.loading = payload;
      state.message = null;
    },
    setBVN: (state, {payload}) => {
      state.userData = payload;
      state.step = 3;
      state.loading = false;
      state.message = null;
    },
    setUserData: (state, {payload}) => {
      state.userData = {...state.userData, ...payload};
      state.loading = false;
      state.message = null;
    },
    signupError: (state, {payload}) => {
      state.loading = false;
      state.success = false;
      let err = JSON.parse(payload); //convert string back to object
      if (typeof (err?.data.message) == 'string') {
        state.error = err?.data;
        state.message = err?.data.message;
      } else {
        state.message = "An error occured, please make sure the data you supplied is valid."
      }
    },
    setStep: (state, {payload}) => {
      state.step = payload;
      state.message = null;
    },
    setMessage: (state, {payload}) => {
      state.message = payload;
      state.loading = false;
    },
    setSuccess: (state, {payload}) => {
      state.success = payload;
    },
    getUser: (state) => {
      return state.userData
    }
  },
});

//actions
export const {setLoading, setBVN, signupError, setStep, setUserData, setSuccess, setMessage, getUser} = signupSlice.actions;

//selectors
export const signupSelector = (state) => state;

//reducer
export default signupSlice.reducer;

//Asynchronous thunk actions
export const verifyBVN = (signupData) => {
  return async (dispatch) => {
    dispatch(setBVN({bvn: signupData.bvn}))
    dispatch(setStep(2));
  };
};

export const signUpUser = (_data) => {
  return async (dispatch) => {
    dispatch(setLoading(true))
    axios.post(`${baseUrl}auth/verify-bvn`, _data)
      .then(result => {
        if (result?.data?.status) {
          const data = result.data;
          dispatch(setUserData({..._data, id: data.data}));
          dispatch(setStep(3))
          // dispatch(setBVN({bvn: data.bvn, phone: data?.phone}))
        } else {
          dispatch(setMessage(result?.data?.message || "An error occured"));
          // dispatch(setMessage("An error occured"));
        }
      }).catch(error => {
        if (error.response) {
          //convert object to string to enable dispatch
          let err = JSON.stringify(error.response);
          dispatch(signupError(err));
        } else {
          dispatch(setMessage("An error occured."));
        }
      });
  }
}

export const verifyOTP = (signupData) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await axios.post(`${baseUrl}auth/verify-otp`, signupData);
      const data = result.data;
      if (data.status) {
        dispatch(setUserData(data.data));
        dispatch(setStep(4));
      } else {
        dispatch(setMessage(data.message));
      }
    } catch (error) {
      if (error.response) {
        //convert object to string to enable dispatch
        let err = JSON.stringify(error.response);
        dispatch(signupError(err));
      } else {
        dispatch(setLoading(false));
      }
    }
  };
};

export const setUpPasswordAndPin = (signupData) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const result = await axios.post(`${baseUrl}auth/setPinAndPassword`, signupData);
      const data = result.data;
      if (data.status || data.data) {
        dispatch(setStep(6));
        dispatch(setSuccess(true))
      } else {
        dispatch(setMessage(data.message))
      }
    } catch (error) {
      if (error.response) {
        //convert object to string to enable dispatch
        let err = JSON.stringify(error.response);
        dispatch(signupError(err));
      } else {
        dispatch(setLoading(false));
      }
    }
  };
};

export const resendOtp = (phone, actions) => {
  return async (dispatch) => {
    actions.setResending(true);
    try {
      const result = await axios.post(`${baseUrl}auth/resend_otp`, {phone});
      const data = result.data;
      if (data.status || data.data) {
        actions.setOTPSent(true);
      } else {
        actions.setOTPSent(false);
        actions.setShowResend(true);
      }
    } catch (error) {
      actions.setOTPSent(false);
      actions.setShowResend(true);
    }
    actions.setResending(false);
  };
};