import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import CustomConfirmModal from "../../widgets/modals/CustomConfirmModal";
import CustomModal from "../../widgets/modals/CustomModal";
import SuccessFeedback from "../../widgets/feedbacks/SuccessFeedback";
import {date, nairaSign} from "../../utils/constants";
import ConfirmModalTextRow from "../../widgets/others/ConfirmModalTextRow";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import CustomForm from "../../widgets/form/CustomForm";
import {depositSavingsFields} from "../../utils/formFields";
import {depositSavingsSchema as validationSchema} from "../../utils/yupSchemas";
import SearchDropdown from "../../widgets/search-dropdown/SearchDropdown";
import {useSelector} from "react-redux";
import {saversSelector} from "../../store/slices/savers/saversSlice";
import {loginSelector} from "../../store/slices/auth/loginSlice";
import {useDispatch} from "react-redux";
import {depositSelector, newDeposit, setDepositIsLoading, setDepositMessage} from "../../store/slices/savers/depositSlice";

export default function DepositSavingsForm() {
  const [saver, setSaver] = useState(null);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const {savers} = useSelector(saversSelector).savers;
  const {userData: {id}} = useSelector(loginSelector).login;
  const {depositIsLoading, depositMessage} = useSelector(depositSelector).deposit;
  const [saverError, setSaverError] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      // saversName: "",
      amount: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => handleSubmit(values, resetForm),
  });
  const [value, setValue] = useState("");

  useEffect(() => {
    dispatch(setDepositIsLoading(false));
    dispatch(setDepositMessage(null));
  }, [dispatch])


  // function handleOpenModal() {
  //   setOpenModal(true);
  // }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenConfirmModal() {
    setOpenConfirmModal(true);
  }
  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }

  function handleSubmit(values, resetForm) {
    if (saver) {
      handleOpenConfirmModal();
    } else {
      setSaverError(true);
    }
    // setReset(resetForm)
  }

  function handleConfirmDeposit() {
    // handleCloseConfirmModal();
    dispatch(newDeposit({
      amount: formik.values.amount,
      transaction_type: 'Deposit for saver',
      agentId: id,
      description: formik.values.description,
      userId: saver.id,
    }, () => {
      handleCloseConfirmModal();
    }))
    // handleOpenModal();
  }

  function handleChange(e) {
    setSaver(null);
    setValue(e.target.value);
    setSaverError(false);
    findRelatedText(e.target.value);
    setIsUserTyping(true);
  }

  function handleSelectSaver(saver) {
    setSaver(saver);
    setIsUserTyping(false);
    setSaverError(false);
    setValue(`${saver.first_name} ${saver.last_name}`)
  }

  function findRelatedText(searchTerm) {
    setSearchResults(
      savers.filter(({first_name, last_name}) =>
        first_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        last_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        `${first_name.toLocaleLowerCase()} ${last_name.toLocaleLowerCase()}`
          .includes(searchTerm.toLocaleLowerCase()) ||
        `${last_name.toLocaleLowerCase()} ${first_name.toLocaleLowerCase()}`
          .includes(searchTerm.toLocaleLowerCase())
      )
    );
  }

  return (
    <>
      {depositMessage &&
        <div className={`${depositMessage?.error ? 'text-danger' : 'text-success'}`}>{depositMessage.message}</div>}
      <form onSubmit={formik.handleSubmit}>
        {depositSavingsFields.map((field, index) => (
          <React.Fragment key={index}>
            <CustomForm
              item={field}
              formik={formik}
              value={value}
              handleChange={handleChange}
            />
            {(saverError && field.name === "saversName") && <div className="form-text text-danger">Select a saver</div>}
            <SearchDropdown
              isActive={Boolean(field.hasDropdown && value && isUserTyping)}
              options={searchResults}
              onClick={handleSelectSaver}
            />
          </React.Fragment>
        ))}

        <CustomButton
          type="submit"
          color="primary"
          text="Deposit Contribution"
        />
      </form>
      <CustomConfirmModal
        title="Confirm deposit"
        subtitle="Confirm the details of your saver and plan before you proceed"
        icon="piggy"
        loading={depositIsLoading}
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmDeposit}
      >
        <div className="">
          <ConfirmModalTextRow title="Full Name" value={value} />
          {/* <ConfirmModalTextRow
            title="Phone Number"
            value={formik.values.phone}
          /> */}
          <ConfirmModalTextRow
            title="Amount"
            type="amount"
            value={formik.values.amount}
          />
          {/* <ConfirmModalTextRow
            title="Contribution Type"
            value={formik.values.saving_type}
          /> */}
          <ConfirmModalTextRow title="Date" type="date" value={date} />
        </div>
      </CustomConfirmModal>
      <CustomModal open={openModal}>
        <SuccessFeedback
          title="Deposit Successful"
          content={`You have successfully deposited ${nairaSign}${formik.values.amount} as savings for ${saver?.first_name} ${saver?.last_name}`}
          buttonText="Close"
          onClick={handleCloseModal}
        />
      </CustomModal>
    </>
  );
}
