import dayjs from "dayjs";
import {formatNumberInThousand} from "../../../utils/functions";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import TransactionDetailsModal from "../../transactions/transaction-details/TransactionDetailsModal";
import {useDispatch, useSelector} from "react-redux";
import {getTransactions} from "../../../store/slices/agent/agentSlice";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import Loader from "../../../widgets/others/Loader";

const tableHeader = ["", "Name", "Date", "Amount", "Tnx Type", "Action"];

export default function RecentTransactions() {

  const {
    userData: {id, accounts},
  } = useSelector(loginSelector).login;
  const [transactions, setTransactions] = useState([]);
  const [trxIsLoading, setTrxIsLoading] = useState(true);
  const [trxError, setTrxError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getTransactions(
        {
          agentId: id,
          accountId: accounts[0].accountId,
        },
        {
          setLoading: setTrxIsLoading,
          setError: setTrxError,
          setTransactions,
        }
      )
    );
  }, [dispatch, accounts, id]);


  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx, setSelectedTnx] = useState();
  function handleViewTransaction(tnx) {
    setSelectedTnx(tnx);
    setOpenTransactionModal(true);
  }
  return (
    <div className="bgWhite cardShadow p-3 h-100">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="fw-bold">Recent Transactions</div>
        <Link to="/transactions" className="btn btn-sm textSecondary">
          View All
        </Link>
      </div>
      <div className="table-responsive-lg">
        <table className="table fw-bold small">
          <thead className="tableHeader">
            <tr>
              {tableHeader.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
          </thead>
          <tbody>

            {transactions && [...transactions].reverse().filter((each, i) => i <= 4).map((item, index) => (
              <tr key={index}>
                <td>
                  {/* <img
                    src={item.type === "Pay Out" ? debitIcon : creditIcon}
                    width="20"
                    alt={item.type}
                  />{" "} */}
                </td>
                <td>{item?.receiver?.first_name} {item?.receiver?.last_name}</td>
                <td>
                  {dayjs(item.createdAt).format("MMM DD, YYYY")} | {new Date(item.createdAt).toLocaleTimeString()}</td>
                <td>
                  {item.type === "Withdrawal" ? (
                    <span className="text-danger">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  ) : (
                    <span className="">
                      {formatNumberInThousand(item.amount)}
                    </span>
                  )}
                </td>
                <td>
                  {item.transaction_type}
                </td>
                <td>
                  <button
                    className="btn btn-sm fw-bold"
                    onClick={() => handleViewTransaction(item)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {trxIsLoading && <Loader height={"40vh"} />}
      {
        !transactions[0] && !trxIsLoading && !trxError && (
          <div className="text-center py-3">No transactions yet</div>
        )
      }
      {
        trxError && (
          <small className="text-center py-3 mx-auto">{trxError.text}</small>
        )
      }
      <TransactionDetailsModal
        open={openTransactionModal}
        onClose={() => setOpenTransactionModal(false)}
        transaction={selectedTnx}
      />
    </div>
  );
}
