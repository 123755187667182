import PropTypes from "prop-types";
import CustomInput from "./custom-input/CustomInput";
import CustomLabel from "./custom-label/CustomLabel";
import CustomSelect from "./custom-select/CustomSelect";
import CustomTextarea from "./custom-textarea/CustomTextarea";

export default function CustomForm({item, formik, value, handleChange, disabled}) {
  return (
    <div className="mb-3">
      <CustomLabel htmlFor={item.label.htmlFor} content={item.label.content} />
      {item.formType === "select" ? (
        <CustomSelect
          disabled={disabled}
          options={item.options}
          option={item.option}
          name={item.name}
          innerLabel={item.innerLabel}
          prependValue={item.prependValue}
          appendValue={item.appendValue}
          onChange={formik.handleChange}
          value={formik.values[item.value]}
          placeholder={item.placeholder}
        />
      ) : item.formType === "textarea" ? (
        <CustomTextarea
          disabled={disabled}
          aria-label={item.ariaLabel}
          aria-describedby={item.ariaDescribedby}
          name={item.name}
          prependValue={item.prependValue}
          appendValue={item.appendValue}
          onChange={formik.handleChange}
          value={formik.values[item.value]}
          placeholder={item.placeholder}
        />
      ) : (
        <CustomInput
          disabled={disabled}
          type={item.type}
          aria-label={item.ariaLabel}
          aria-describedby={item.ariaDescribedby}
          name={item.name}
          prependValue={item.prependValue}
          appendValue={item.appendValue}
          onChange={item.hasDropdown ? handleChange : formik.handleChange}
          value={item.hasDropdown ? value : formik.values[item.value]}
          placeholder={item.placeholder}
        />
      )}
      {formik.touched[item.name] && (
        <div className="form-text text-danger">{formik.errors[item.name]}</div>
      )}
    </div>
  );
}

CustomForm.propTypes = {
  item: PropTypes.object.isRequired,
  formik: PropTypes.object.isRequired,
  value: PropTypes.string,
  handleChange: PropTypes.func,
};
