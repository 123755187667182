import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStep, setUpPasswordAndPin, signupSelector} from "../../../store/slices/auth/signupSlice";
import SignUpStepTitle from "./SignUpStepTitle";
import ReactCodeInput from "react-code-input";
import ACBtn from "../../../widgets/form/ACBtn";

export default function SignUpSetupPin() {
	const [pin, setPin] = useState("");
	const dispatch = useDispatch();
	const {loading, userData: {id, password}} = useSelector(signupSelector).signup;

	function handleSubmit(e) {
		e.preventDefault();
		dispatch(setUpPasswordAndPin({id, password, pin}));
	}

	const props = {
		onChange: setPin,
		type: 'password',
		fields: 4,
		className: `[display:flex_!important] justify-between auth`,
		disabled: loading,
		inputMode: 'numeric',
		// isValid: states.validCode,
	}

	return (
		<>
			<form onSubmit={handleSubmit} className='cfade max-w-md py-20 mx-[auto] md:mx-0 px-3 lg:pl-8 flex min-h-screen flex-col'>
				<SignUpStepTitle title={'Transactional PIN'} subtitle={'Create a 4-digit PIN that will be used to authenticate all your transactions.'} />
				<div className='flex flex-col gap-1 pt-7'>
					<ReactCodeInput {...props} /> <br />
					<div className='flex justify-between'>
						<button type='button' onClick={() => dispatch(setStep(4))} className='gr-btn' disabled={loading}>Back</button>
						<ACBtn type='submit' text={'Complete'} loading={loading} disabled={(pin.length !== 4) || loading} className={'max-w-[8rem]'} />
					</div>
				</div>
			</form>
		</>
	);
}
