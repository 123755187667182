// Your variables goes here

import dayjs from "dayjs";

export const date = new Date();
export const dateFormatted = dayjs(date).format("MMM DD, YYYY");
export const nairaSign = "₦";
export const contributionTypes = ["Daily", "Weekly", "Monthly"];

export const navbarItems = [
  { name: "Dashboard", path: "/" },
  { name: "Wallet", path: "/wallet" },
  { name: "Savers", path: "/savers" },
  { name: "Loans", path: "/loans" },
  { name: "Transactions", path: "/transactions" },
];

export const otpInputStyle = {
  appearance: "none",
  backgroundClip: "padding-box",
  backgroundColor: "#fff",
  border: "1px solid #ced4da",
  borderRadius: "0.25rem",
  color: "#212529",
  display: "block",
  fontSize: "1rem",
  fontWeight: 400,
  lineHeight: 1.5,
  padding: "0.375rem 0.75rem",
  transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
  width: 50,
};

export const dashboardDataCards = [
  // {
  //   title: "Total daily Volume",
  //   icon: "upDownArrow",
  //   value: 0,
  //   backgroundColor: "#061B5A",
  //   color: "#ffffff",
  // },
  // ,

  {
    title: "Amount Owed",
    icon: "graphPopout",
    value: 0,
    backgroundColor: "#FD8D94",
    color: "#ffffff",
  },
];

export const walletDataCards = [
  {
    title: "Wallet Balance",
    icon: "",
    value: 9000000,
    backgroundColor: "#061B5A",
    color: "#ffffff",
    actions: [
      { icon: "forward", action: "withdraw", text: "withdraw" },
      { icon: "downloadCircle", action: "fund", text: "fund" },
    ],
  },
  // {
  //   title: "Available Loan Balance",
  //   icon: "",
  //   value: 0,
  //   backgroundColor: "#14A3B8",
  //   color: "#ffffff",
  //   actions: [{icon: "naira", action: "borrow", text: "borrow"}],
  // },
  // {
  //   title: "Amount Owed",
  //   icon: "",
  //   value: 0,
  //   subtitle: "Repayment Payment due on --/--/--",
  //   backgroundColor: "#FFE8E9",
  // },
];

export const walletTransactions = [
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 20000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "FirstBank",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    description: "...",
    action: "View",
  },
];

export const contributionTransactions = [
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 20000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Withdrawal",
    bank: "FirstBank",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 8130023455,
    description: "...",
    action: "View",
  },
  {
    name: "Temilade AKin",
    date: date,
    amount: 70000,
    type: "Deposit",
    bank: "GTB",
    accountNumber: 9130023455,
    description: "...",
    action: "View",
  },
];

export const saversTransactions = [
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Daily",
    bank: "GTB",
    accountNumber: 9130023455,
    duration: "3 months",
    endDate: date,
    phoneNumber: 8123675489,
    action: "View",
  },
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Weekly",
    bank: "GTB",
    accountNumber: 9130023455,
    duration: "6 months",
    endDate: date,
    phoneNumber: 8123675489,
  },
  {
    name: "Temilade Akinlade Taiwo",
    startDate: date,
    amount: 70000,
    type: "Monthly",
    bank: "Zenith",
    accountNumber: 1433023455,
    duration: "12 months",
    endDate: date,
    phoneNumber: 8123675489,
  },
];

export const dashboardQuickLinks = [
  {
    icon: "add.svg",
    name: "Add New",
    path: "/savers/add",
    isLink: true,
  },
  {
    icon: "download.svg",
    name: "Fund Wallet",
    path: "/",
    isLink: false,
    func: "fund-wallet",
  },
  {
    icon: "arrow-up.svg",
    name: "Pay Out",
    path: "/payout-savings",
    isLink: true,
  },
  {
    icon: "arrow-down.svg",
    name: "Deposit",
    path: "/deposit-savings",
    isLink: true,
  },
  {
    icon: "arrow-up-down.svg",
    name: "Loan",
    path: "/loans",
    isLink: true,
  },
];

export const nigerianBanksList = [
  { id: "22", name: "Wema Bank", code: "035" },
  { id: "1", name: "Access Bank", code: "044" },
  { id: "2", name: "Citibank", code: "023" },
  { id: "3", name: "Diamond Bank", code: "063" },
  // {id: "4", name: "Dynamic Standard Bank", code: ""},
  { id: "5", name: "Ecobank Nigeria", code: "050" },
  { id: "6", name: "Fidelity Bank Nigeria", code: "070" },
  { id: "7", name: "First Bank of Nigeria", code: "011" },
  { id: "8", name: "First City Monument Bank", code: "214" },
  { id: "9", name: "Guaranty Trust Bank", code: "058" },
  { id: "10", name: "Heritage Bank Plc", code: "030" },
  { id: "11", name: "Jaiz Bank", code: "301" },
  { id: "12", name: "Keystone Bank Limited", code: "082" },
  { id: "13", name: "Providus Bank Plc", code: "101" },
  { id: "14", name: "Polaris Bank", code: "076" },
  { id: "15", name: "Stanbic IBTC Bank Nigeria Limited", code: "221" },
  { id: "16", name: "Standard Chartered Bank", code: "068" },
  { id: "17", name: "Sterling Bank", code: "232" },
  { id: "18", name: "Suntrust Bank Nigeria Limited", code: "100" },
  { id: "19", name: "Union Bank of Nigeria", code: "032" },
  { id: "20", name: "United Bank for Africa", code: "033" },
  { id: "21", name: "Unity Bank Plc", code: "215" },
  { id: "23", name: "Zenith Bank", code: "057" },
];


export const states = [
  {
    "state": "Abia",
    "lgas": [
      "Aba North",
      "Aba South",
      "Arochukwu",
      "Bende",
      "Ikawuno",
      "Ikwuano",
      "Isiala-Ngwa North",
      "Isiala-Ngwa South",
      "Isuikwuato",
      "Umu Nneochi",
      "Obi Ngwa",
      "Obioma Ngwa",
      "Ohafia",
      "Ohaozara",
      "Osisioma",
      "Ugwunagbo",
      "Ukwa West",
      "Ukwa East",
      "Umuahia North",
      "Umuahia South"
    ]
  },
  {
    "state": "Adamawa",
    "lgas": [
      "Demsa",
      "Fufore",
      "Ganye",
      "Girei",
      "Gombi",
      "Guyuk",
      "Hong",
      "Jada",
      "Lamurde",
      "Madagali",
      "Maiha",
      "Mayo-Belwa",
      "Michika",
      "Mubi-North",
      "Mubi-South",
      "Numan",
      "Shelleng",
      "Song",
      "Toungo",
      "Yola North",
      "Yola South"
    ]
  },
  {
    "state": "Akwa Ibom",
    "lgas": [
      "Abak",
      "Eastern-Obolo",
      "Eket",
      "Esit-Eket",
      "Essien-Udim",
      "Etim-Ekpo",
      "Etinan",
      "Ibeno",
      "Ibesikpo-Asutan",
      "Ibiono-Ibom",
      "Ika",
      "Ikono",
      "Ikot-Abasi",
      "Ikot-Ekpene",
      "Ini",
      "Itu",
      "Mbo",
      "Mkpat-Enin",
      "Nsit-Atai",
      "Nsit-Ibom",
      "Nsit-Ubium",
      "Obot-Akara",
      "Okobo",
      "Onna",
      "Oron",
      "Oruk Anam",
      "Udung-Uko",
      "Ukanafun",
      "Urue-Offong/Oruko",
      "Uruan",
      "Uyo"
    ]
  },
  {
    "state": "Anambra",
    "lgas": [
      "Aguata",
      "Anambra East",
      "Anambra West",
      "Anaocha",
      "Awka North",
      "Awka South",
      "Ayamelum",
      "Dunukofia",
      "Ekwusigo",
      "Idemili-North",
      "Idemili-South",
      "Ihiala",
      "Njikoka",
      "Nnewi-North",
      "Nnewi-South",
      "Ogbaru",
      "Onitsha-North",
      "Onitsha-South",
      "Orumba-North",
      "Orumba-South"
    ]
  },
  {
    "state": "Bauchi",
    "lgas": [
      "Alkaleri",
      "Bauchi",
      "Bogoro",
      "Damban",
      "Darazo",
      "Dass",
      "Gamawa",
      "Ganjuwa",
      "Giade",
      "Itas Gadau",
      "Jama'Are",
      "Katagum",
      "Kirfi",
      "Misau",
      "Ningi",
      "Shira",
      "Tafawa-Balewa",
      "Toro",
      "Warji",
      "Zaki"
    ]
  },
  {
    "state": "Benue",
    "lgas": [
      "Ado",
      "Agatu",
      "Apa",
      "Buruku",
      "Gboko",
      "Guma",
      "Gwer-East",
      "Gwer-West",
      "Katsina-Ala",
      "Konshisha",
      "Kwande",
      "Logo",
      "Makurdi",
      "Ogbadibo",
      "Ohimini",
      "Oju",
      "Okpokwu",
      "Otukpo",
      "Tarka",
      "Ukum",
      "Ushongo",
      "Vandeikya"
    ]
  },
  {
    "state": "Borno",
    "lgas": [
      "Abadam",
      "Askira-Uba",
      "Bama",
      "Bayo",
      "Biu",
      "Chibok",
      "Damboa",
      "Dikwa",
      "Gubio",
      "Guzamala",
      "Gwoza",
      "Hawul",
      "Jere",
      "Kaga",
      "Kala Balge",
      "Konduga",
      "Kukawa",
      "Kwaya-Kusar",
      "Mafa",
      "Magumeri",
      "Maiduguri",
      "Marte",
      "Mobbar",
      "Monguno",
      "Ngala",
      "Nganzai",
      "Shani"
    ]
  },
  {
    "state": "Bayelsa",
    "lgas": [
      "Brass",
      "Ekeremor",
      "Kolokuma Opokuma",
      "Nembe",
      "Ogbia",
      "Sagbama",
      "Southern-Ijaw",
      "Yenagoa"
    ]
  },
  {
    "state": "Cross River",
    "lgas": [
      "Abi",
      "Akamkpa",
      "Akpabuyo",
      "Bakassi",
      "Bekwarra",
      "Biase",
      "Boki",
      "Calabar-Municipal",
      "Calabar-South",
      "Etung",
      "Ikom",
      "Obanliku",
      "Obubra",
      "Obudu",
      "Odukpani",
      "Ogoja",
      "Yakurr",
      "Yala"
    ]
  },
  {
    "state": "Delta",
    "lgas": [
      "Aniocha North",
      "Aniocha-North",
      "Aniocha-South",
      "Bomadi",
      "Burutu",
      "Ethiope-East",
      "Ethiope-West",
      "Ika-North-East",
      "Ika-South",
      "Isoko-North",
      "Isoko-South",
      "Ndokwa-East",
      "Ndokwa-West",
      "Okpe",
      "Oshimili-North",
      "Oshimili-South",
      "Patani",
      "Sapele",
      "Udu",
      "Ughelli-North",
      "Ughelli-South",
      "Ukwuani",
      "Uvwie",
      "Warri South-West",
      "Warri North",
      "Warri South"
    ]
  },
  {
    "state": "Ebonyi",
    "lgas": [
      "Abakaliki",
      "Afikpo-North",
      "Afikpo South (Edda)",
      "Ebonyi",
      "Ezza-North",
      "Ezza-South",
      "Ikwo",
      "Ishielu",
      "Ivo",
      "Izzi",
      "Ohaukwu",
      "Onicha"
    ]
  },
  {
    "state": "Edo",
    "lgas": [
      "Akoko Edo",
      "Egor",
      "Esan-Central",
      "Esan-North-East",
      "Esan-South-East",
      "Esan-West",
      "Etsako-Central",
      "Etsako-East",
      "Etsako-West",
      "Igueben",
      "Ikpoba-Okha",
      "Oredo",
      "Orhionmwon",
      "Ovia-North-East",
      "Ovia-South-West",
      "Owan East",
      "Owan-West",
      "Uhunmwonde"
    ]
  },
  {
    "state": "Ekiti",
    "lgas": [
      "Ado-Ekiti",
      "Efon",
      "Ekiti-East",
      "Ekiti-South-West",
      "Ekiti-West",
      "Emure",
      "Gbonyin",
      "Ido-Osi",
      "Ijero",
      "Ikere",
      "Ikole",
      "Ilejemeje",
      "Irepodun Ifelodun",
      "Ise-Orun",
      "Moba",
      "Oye"
    ]
  },
  {
    "state": "Enugu",
    "lgas": [
      "Aninri",
      "Awgu",
      "Enugu-East",
      "Enugu-North",
      "Enugu-South",
      "Ezeagu",
      "Igbo-Etiti",
      "Igbo-Eze-North",
      "Igbo-Eze-South",
      "Isi-Uzo",
      "Nkanu-East",
      "Nkanu-West",
      "Nsukka",
      "Oji-River",
      "Udenu",
      "Udi",
      "Uzo-Uwani"
    ]
  },
  {
    "state": "Federal Capital Territory",
    "lgas": [
      "Abuja",
      "Kwali",
      "Kuje",
      "Gwagwalada",
      "Bwari",
      "Abaji"
    ]
  },
  {
    "state": "Gombe",
    "lgas": [
      "Akko",
      "Balanga",
      "Billiri",
      "Dukku",
      "Funakaye",
      "Gombe",
      "Kaltungo",
      "Kwami",
      "Nafada",
      "Shongom",
      "Yamaltu Deba"
    ]
  },
  {
    "state": "Imo",
    "lgas": [
      "Aboh-Mbaise",
      "Ahiazu-Mbaise",
      "Ehime-Mbano",
      "Ezinihitte",
      "Ideato-North",
      "Ideato-South",
      "Ihitte Uboma",
      "Ikeduru",
      "Isiala-Mbano",
      "Isu",
      "Mbaitoli",
      "Ngor-Okpala",
      "Njaba",
      "Nkwerre",
      "Nwangele",
      "Obowo",
      "Oguta",
      "Ohaji-Egbema",
      "Okigwe",
      "Onuimo",
      "Orlu",
      "Orsu",
      "Oru-East",
      "Oru-West",
      "Owerri-Municipal",
      "Owerri-North",
      "Owerri-West"
    ]
  },
  {
    "state": "Jigawa",
    "lgas": [
      "Auyo",
      "Babura",
      "Biriniwa",
      "Birnin-Kudu",
      "Buji",
      "Dutse",
      "Gagarawa",
      "Garki",
      "Gumel",
      "Guri",
      "Gwaram",
      "Gwiwa",
      "Hadejia",
      "Jahun",
      "Kafin-Hausa",
      "Kaugama",
      "Kazaure",
      "Kiri kasama",
      "Maigatari",
      "Malam Madori",
      "Miga",
      "Ringim",
      "Roni",
      "Sule-Tankarkar",
      "Taura",
      "Yankwashi"
    ]
  },
  {
    "state": "Kebbi",
    "lgas": [
      "Aleiro",
      "Arewa-Dandi",
      "Argungu",
      "Augie",
      "Bagudo",
      "Birnin-Kebbi",
      "Bunza",
      "Dandi",
      "Fakai",
      "Gwandu",
      "Jega",
      "Kalgo",
      "Koko-Besse",
      "Maiyama",
      "Ngaski",
      "Sakaba",
      "Shanga",
      "Suru",
      "Wasagu/Danko",
      "Yauri",
      "Zuru"
    ]
  },
  {
    "state": "Kaduna",
    "lgas": [
      "Birnin-Gwari",
      "Chikun",
      "Giwa",
      "Igabi",
      "Ikara",
      "Jaba",
      "Jema'A",
      "Kachia",
      "Kaduna-North",
      "Kaduna-South",
      "Kagarko",
      "Kajuru",
      "Kaura",
      "Kauru",
      "Kubau",
      "Kudan",
      "Lere",
      "Makarfi",
      "Sabon-Gari",
      "Sanga",
      "Soba",
      "Zangon-Kataf",
      "Zaria"
    ]
  },
  {
    "state": "Kano",
    "lgas": [
      "Ajingi",
      "Albasu",
      "Bagwai",
      "Bebeji",
      "Bichi",
      "Bunkure",
      "Dala",
      "Dambatta",
      "Dawakin-Kudu",
      "Dawakin-Tofa",
      "Doguwa",
      "Fagge",
      "Gabasawa",
      "Garko",
      "Garun-Mallam",
      "Gaya",
      "Gezawa",
      "Gwale",
      "Gwarzo",
      "Kabo",
      "Kano-Municipal",
      "Karaye",
      "Kibiya",
      "Kiru",
      "Kumbotso",
      "Kunchi",
      "Kura",
      "Madobi",
      "Makoda",
      "Minjibir",
      "Nasarawa",
      "Rano",
      "Rimin-Gado",
      "Rogo",
      "Shanono",
      "Sumaila",
      "Takai",
      "Tarauni",
      "Tofa",
      "Tsanyawa",
      "Tudun-Wada",
      "Ungogo",
      "Warawa",
      "Wudil"
    ]
  },
  {
    "state": "Kogi",
    "lgas": [
      "Adavi",
      "Ajaokuta",
      "Ankpa",
      "Dekina",
      "Ibaji",
      "Idah",
      "Igalamela-Odolu",
      "Ijumu",
      "Kabba Bunu",
      "Kogi",
      "Lokoja",
      "Mopa-Muro",
      "Ofu",
      "Ogori Magongo",
      "Okehi",
      "Okene",
      "Olamaboro",
      "Omala",
      "Oyi",
      "Yagba-East",
      "Yagba-West"
    ]
  },
  {
    "state": "Katsina",
    "lgas": [
      "Bakori",
      "Batagarawa",
      "Batsari",
      "Baure",
      "Bindawa",
      "Charanchi",
      "Dan-Musa",
      "Dandume",
      "Danja",
      "Daura",
      "Dutsi",
      "Dutsin-Ma",
      "Faskari",
      "Funtua",
      "Ingawa",
      "Jibia",
      "Kafur",
      "Kaita",
      "Kankara",
      "Kankia",
      "Katsina",
      "Kurfi",
      "Kusada",
      "Mai-Adua",
      "Malumfashi",
      "Mani",
      "Mashi",
      "Matazu",
      "Musawa",
      "Rimi",
      "Sabuwa",
      "Safana",
      "Sandamu",
      "Zango"
    ]
  },
  {
    "state": "Kwara",
    "lgas": [
      "Asa",
      "Baruten",
      "Edu",
      "Ekiti (Araromi/Opin)",
      "Ilorin-East",
      "Ilorin-South",
      "Ilorin-West",
      "Isin",
      "Kaiama",
      "Moro",
      "Offa",
      "Oke-Ero",
      "Oyun",
      "Pategi"
    ]
  },
  {
    "state": "Lagos",
    "lgas": [
      "Agege",
      "Ajeromi-Ifelodun",
      "Alimosho",
      "Amuwo-Odofin",
      "Apapa",
      "Badagry",
      "Epe",
      "Eti-Osa",
      "Ibeju-Lekki",
      "Ifako-Ijaiye",
      "Ikeja",
      "Ikorodu",
      "Kosofe",
      "Lagos-Island",
      "Lagos-Mainland",
      "Mushin",
      "Ojo",
      "Oshodi-Isolo",
      "Shomolu",
      "Surulere",
      "Yewa-South"
    ]
  },
  {
    "state": "Nasarawa",
    "lgas": [
      "Akwanga",
      "Awe",
      "Doma",
      "Karu",
      "Keana",
      "Keffi",
      "Kokona",
      "Lafia",
      "Nasarawa",
      "Nasarawa-Eggon",
      "Obi",
      "Wamba",
      "Toto"
    ]
  },
  {
    "state": "Niger",
    "lgas": [
      "Agaie",
      "Agwara",
      "Bida",
      "Borgu",
      "Bosso",
      "Chanchaga",
      "Edati",
      "Gbako",
      "Gurara",
      "Katcha",
      "Kontagora",
      "Lapai",
      "Lavun",
      "Magama",
      "Mariga",
      "Mashegu",
      "Mokwa",
      "Moya",
      "Paikoro",
      "Rafi",
      "Rijau",
      "Shiroro",
      "Suleja",
      "Tafa",
      "Wushishi"
    ]
  },
  {
    "state": "Ogun",
    "lgas": [
      "Abeokuta-North",
      "Abeokuta-South",
      "Ado-Odo Ota",
      "Ewekoro",
      "Ifo",
      "Ijebu-East",
      "Ijebu-North",
      "Ijebu-North-East",
      "Ijebu-Ode",
      "Ikenne",
      "Imeko-Afon",
      "Ipokia",
      "Obafemi-Owode",
      "Odeda",
      "Odogbolu",
      "Ogun-Waterside",
      "Remo-North",
      "Shagamu",
      "Yewa North"
    ]
  },
  {
    "state": "Ondo",
    "lgas": [
      "Akoko North-East",
      "Akoko North-West",
      "Akoko South-West",
      "Akoko South-East",
      "Akure-North",
      "Akure-South",
      "Ese-Odo",
      "Idanre",
      "Ifedore",
      "Ilaje",
      "Ile-Oluji-Okeigbo",
      "Irele",
      "Odigbo",
      "Okitipupa",
      "Ondo West",
      "Ondo-East",
      "Ose",
      "Owo"
    ]
  },
  {
    "state": "Osun",
    "lgas": [
      "Atakumosa West",
      "Atakumosa East",
      "Ayedaade",
      "Ayedire",
      "Boluwaduro",
      "Boripe",
      "Ede South",
      "Ede North",
      "Egbedore",
      "Ejigbo",
      "Ife North",
      "Ife South",
      "Ife-Central",
      "Ife-East",
      "Ifelodun",
      "Ila",
      "Ilesa-East",
      "Ilesa-West",
      "Irepodun",
      "Irewole",
      "Isokan",
      "Iwo",
      "Obokun",
      "Odo-Otin",
      "Ola Oluwa",
      "Olorunda",
      "Oriade",
      "Orolu",
      "Osogbo"
    ]
  },
  {
    "state": "Oyo",
    "lgas": [
      "Afijio",
      "Akinyele",
      "Atiba",
      "Atisbo",
      "Egbeda",
      "Ibadan North",
      "Ibadan North-East",
      "Ibadan North-West",
      "Ibadan South-East",
      "Ibadan South-West",
      "Ibarapa-Central",
      "Ibarapa-East",
      "Ibarapa-North",
      "Ido",
      "Ifedayo",
      "Irepo",
      "Iseyin",
      "Itesiwaju",
      "Iwajowa",
      "Kajola",
      "Lagelu",
      "Ogo-Oluwa",
      "Ogbomosho-North",
      "Ogbomosho-South",
      "Olorunsogo",
      "Oluyole",
      "Ona-Ara",
      "Orelope",
      "Ori-Ire",
      "Oyo-West",
      "Oyo-East",
      "Saki-East",
      "Saki-West",
      "Surulere"
    ]
  },
  {
    "state": "Plateau",
    "lgas": [
      "Barkin-Ladi",
      "Bassa",
      "Bokkos",
      "Jos-East",
      "Jos-North",
      "Jos-South",
      "Kanam",
      "Kanke",
      "Langtang-North",
      "Langtang-South",
      "Mangu",
      "Mikang",
      "Pankshin",
      "Qua'an Pan",
      "Riyom",
      "Shendam",
      "Wase"
    ]
  },
  {
    "state": "Rivers",
    "lgas": [
      "Abua Odual",
      "Ahoada-East",
      "Ahoada-West",
      "Akuku Toru",
      "Andoni",
      "Asari-Toru",
      "Bonny",
      "Degema",
      "Eleme",
      "Emuoha",
      "Etche",
      "Gokana",
      "Ikwerre",
      "Khana",
      "Obio Akpor",
      "Ogba-Egbema-Ndoni",
      "Ogba Egbema Ndoni",
      "Ogu Bolo",
      "Okrika",
      "Omuma",
      "Opobo Nkoro",
      "Oyigbo",
      "Port-Harcourt",
      "Tai"
    ]
  },
  {
    "state": "Sokoto",
    "lgas": [
      "Binji",
      "Bodinga",
      "Dange-Shuni",
      "Gada",
      "Goronyo",
      "Gudu",
      "Gwadabawa",
      "Illela",
      "Kebbe",
      "Kware",
      "Rabah",
      "Sabon Birni",
      "Shagari",
      "Silame",
      "Sokoto-North",
      "Sokoto-South",
      "Tambuwal",
      "Tangaza",
      "Tureta",
      "Wamako",
      "Wurno",
      "Yabo"
    ]
  },
  {
    "state": "Taraba",
    "lgas": [
      "Ardo-Kola",
      "Bali",
      "Donga",
      "Gashaka",
      "Gassol",
      "Ibi",
      "Jalingo",
      "Karim-Lamido",
      "Kurmi",
      "Lau",
      "Sardauna",
      "Takum",
      "Ussa",
      "Wukari",
      "Yorro",
      "Zing"
    ]
  },
  {
    "state": "Yobe",
    "lgas": [
      "Bade",
      "Bursari",
      "Damaturu",
      "Fika",
      "Fune",
      "Geidam",
      "Gujba",
      "Gulani",
      "Jakusko",
      "Karasuwa",
      "Machina",
      "Nangere",
      "Nguru",
      "Potiskum",
      "Tarmuwa",
      "Yunusari",
      "Yusufari"
    ]
  },
  {
    "state": "Zamfara",
    "lgas": [
      "Anka",
      "Bakura",
      "Birnin Magaji/Kiyaw",
      "Bukkuyum",
      "Bungudu",
      "Gummi",
      "Gusau",
      "Isa",
      "Kaura-Namoda",
      "Kiyawa",
      "Maradun",
      "Maru",
      "Shinkafi",
      "Talata-Mafara",
      "Tsafe",
      "Zurmi"
    ]
  }
]