import {useFormik} from "formik";
import CustomForm from "../../../../../widgets/form/CustomForm";
import {bankTransferFields} from "../../../../../utils/formFields";
import {bankTransferSchema as validationSchema} from "../../../../../utils/yupSchemas";

import {useSelector} from "react-redux";
import {loginSelector} from "../../../../../store/slices/auth/loginSlice";

export default function BankTransfer({callSuccess}) {
  const {userData} = useSelector(loginSelector).login;

  const formik = useFormik({
    initialValues: {
      fullName: `${userData.first_name} ${userData.last_name}`,
      accountNumber: userData.accounts[0].accountNumber ?? "",
      // amount: "",
      bankName: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  function handleSubmit(values) {
    callSuccess();
  }

  return (
    <>
      <div className="fw-bold">Bank Transfer</div>
      <div className="extraSmallText mb-4">
        Use the details below to fund your Wallet from any bank app or via
        internet banking
      </div>

      <form onSubmit={formik.handleSubmit}>
        {bankTransferFields.map((field, index) => (
          <CustomForm disabled={true} item={field} formik={formik} key={index} />
        ))}
        {/* <div className="d-flex justify-content-end">
          <CustomButton type="submit" text="Fund Wallet" color="primary" />
        </div> */}
      </form>

      {/* <div>
        <div className="mb-3">
          <span className="fw-bold">Bank Name</span>
          <div className="">{userData.first_name} {userData.last_name}</div>
        </div>
        <div className="mb-3">
          <span className="fw-bold">Account Number</span>
          <div className="">00000000000</div>
        </div>
        <div className="mb-3">
          <span className="fw-bold">Account Name</span>
          <div className="">{userData.first_name} {userData.last_name}</div>
        </div>
      </div> */}
    </>
  );
}
