import { useFormik } from "formik";
import * as Yup from "yup";
import { nigerianBanksList } from "../../../utils/constants";
import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import CustomConfirmModal from "../../../widgets/modals/CustomConfirmModal";
import CustomModal from "../../../widgets/modals/CustomModal";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";
import { date, nairaSign } from "../../../utils/constants";
import ConfirmModalTextRow from "../../../widgets/others/ConfirmModalTextRow";
import { useEffect, useRef, useState } from "react";
import CustomSelect from '../../../widgets/form/custom-select/CustomSelect'
import axios from "axios";
import { Spinner } from "react-bootstrap";

const validationSchema = Yup.object({
  name: Yup.string("Enter receipent's full name").required(
    "Receipent's name is required"
  ),
  account_no: Yup.string("Enter receipent's account number")
    .min(11, "Too short! must be 11 in length")
    .max(11, "Too long! must be 11 in length")
    .required("Account number is required"),
  amount: Yup.string("Enter loan amount").required(
    "Loan amount is required"
  ),
  bank: Yup.string("Enter Bank Name").required(
    "Bank name is required"
  ),
  repayment_date: Yup.string("Enter Repayment Date").required(
    "Repayment date is required"
  ),
  reason: Yup.string("Enter Loan Reason").required(
    "Loan reason is required"
  ),
  duration: Yup.string("Enter Duration").required(
    "Duration is required"
  ),
  bs_bank: Yup.string("Bank Statement Bank is required").required(
    "Bank Statement Bank is required"
  ),
});

export default function NewLoanForm() {
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const file = useRef(null);
  const [fileState, setFileState] = useState(null);
  const [fileDataURL, setFileDataURL] = useState(null);
  const [message, setMessage] = useState(null);
  const [finverBank, setFinverBank] = useState([]);
  const [bsUrl, setBsUrl] = useState({
    url: '',
    loading: true
  })

  const finverBaseUrl = 'https://api.finver.io/api/v1';

  const formik = useFormik({
    initialValues: {
      name: "",
      account_no: "",
      amount: "",
      bank: "",
      repayment_date: "",
      reason: "",
      duration: "",
      password: '',
      bs_bank: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  function handleOpenModal() {
    setOpenModal(true);
  }
  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleOpenConfirmModal() {
    setOpenConfirmModal(true);
  }
  function handleCloseConfirmModal() {
    setOpenConfirmModal(false);
  }

  const handleSubmit = async (values) => {
    console.log(values);
    const res = await uploadCustomer();
    console.log(res);
    // handleOpenConfirmModal();
  }

  function handleConfirmDeposit() {
    handleCloseConfirmModal();
    handleOpenModal();
  }

  const handleChange = async (e) => {
    const val = e.target.files[0];
    setFileState(val);
    setMessage(null);
    const url = await finverGenerateUploadUrl(val)
    await uploadToS3(url.url, val)
    setBsUrl((prevData) => ({ ...prevData, url: url.url }))
    if (val.size / 1024 <= 200) {
      // 
    } else {
      // setMessage({ error: true, text: "File size can not be more than 200KB" });
    }
  }

  const getFinverBank = async () => {
    try {
      const res = await axios.get(`${finverBaseUrl}/finrisk/banks`, { headers: { 'finver-secret': process.env.REACT_APP_FINVER_APP_SECRET_KEY } })

      setFinverBank(res?.data?.data || [])
    } catch (error) {
      console.log(error);
    }
  }

  const finverGenerateUploadUrl = async (file) => {
    try {
      const res = await axios.post(`${finverBaseUrl}/finrisk/uploads/generate-url`, { fileName: file?.name }, { headers: { 'finver-secret': process.env.REACT_APP_FINVER_APP_SECRET_KEY } })

      setBsUrl((prevData) => ({ ...prevData, loading: false }))
      return res?.data?.data || [];
    } catch (error) {
      console.log(error);
    }
  }

  const uploadToS3 = async (link, file) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: file
    };
    try {
      const response = await fetch(link, requestOptions)
      const data = await response.json();
      const res = await axios.put(`${link}`, file, { overide: true });
      // console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  const uploadCustomer = async () => {
    let data = {
      bankStatements: [
        {
          fileUrl: bsUrl.url,
          password: '',
          bankId: formik.values.bs_bank
        }
      ]
    }

    let string = JSON.stringify(data);

    try {
      const res = await axios.post(`${finverBaseUrl}/finrisk/upload-customers`, string, { headers: { 'finver-secret': process.env.REACT_APP_FINVER_APP_SECRET_KEY } });

      console.log(res.data.data);

      return res.data.data ?? [];
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getFinverBank()
  }, [])

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-6 customFormCard">
            <div className="mb-3">
              <CustomLabel htmlFor="name" content="Receipent's Name" />
              <CustomInput
                type="text"
                placeholder="Enter receipent’s First Name"
                aria-label="name"
                aria-describedby="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              {formik.touched.name && (
                <div className="form-text text-danger">
                  {formik.errors.name}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel
                htmlFor="account_no"
                content="Receipent's Account Number"
              />
              <CustomInput
                type="number"
                aria-label="account_no"
                aria-describedby="account_no"
                minLength="11"
                maxLength="11"
                name="account_no"
                onChange={formik.handleChange}
                value={formik.values.account_no}
              />
              {formik.touched.account_no && (
                <div className="form-text text-danger">
                  {formik.errors.account_no}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor="amount" content="Loan Amount" />
              <CustomInput
                type="number"
                placeholder="₦0.00"
                aria-label="amount"
                aria-describedby="amount"
                name="amount"
                onChange={formik.handleChange}
                value={formik.values.amount}
              />
              {formik.touched.amount && (
                <div className="form-text text-danger">
                  {formik.errors.amount}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor="bank" content="Receipent's Bank" />
              <select
                className="form-select customFormControl"
                name="bank"
                onChange={formik.handleChange}
                value={formik.values.bank}
                aria-label="bank"
                aria-describedby="bank"
              >
                <option defaultValue>Select bank</option>
                {nigerianBanksList.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              {formik.touched.bank && (
                <div className="form-text text-danger">
                  {formik.errors.bank}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor="repayment_date" content="Repayment Date" />
              <CustomInput
                type="date"
                placeholder="repayment date"
                aria-label="repayment_date"
                aria-describedby="repayment_date"
                name="repayment_date"
                onChange={formik.handleChange}
                value={formik.values.repayment_date}
              />
              {formik.touched.repayment_date && (
                <div className="form-text text-danger">
                  {formik.errors.repayment_date}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor="reason" content="Reasons for loan" />
              <CustomInput
                type="text"
                placeholder="State reason for loan"
                aria-label="reason"
                aria-describedby="reason"
                name="reason"
                onChange={formik.handleChange}
                value={formik.values.reason}
              />
              {formik.touched.reason && (
                <div className="form-text text-danger">
                  {formik.errors.reason}
                </div>
              )}
            </div>
            <div className="mb-3">
              <CustomLabel htmlFor='Duration' content='Duration' />
              <CustomSelect
                options={["1", "2", "3", "4", "5", "6"]}
                placeholder="Select Duration"
                aria-label="duration"
                name="duration"
                aria-describedby="duration"
                onChange={formik.handleChange}
                value={formik.values.duration}
              />

              {formik.touched.gender && (
                <div className="form-text text-danger">
                  {formik.errors.gender}
                </div>
              )}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 customFormCard">
            <h6 className="font-weight-bold">Upload Bank Statement</h6>
            <div>
              <div onClick={() => file.current.click()} className="cursorPointer text-center px-5 py-3 rounded" style={{ border: '2px black dotted', }}>
                <input
                  ref={file}
                  type="file"
                  className="d-none"
                  accept=".pdf"
                  onChange={handleChange}
                />
                <small> <i class="fa fa-plus mx-2" aria-hidden="true"></i> Click or drag file to this area to upload </small>
              </div>
              {fileState?.name && (
                <div className="bg-white border shadow-sm p-3 my-2 d-flex justify-content-between">
                  <div className="mt-auto">
                    {
                      bsUrl.loading ? <Spinner animation="grow" variant="primary" size="sm" /> : <i class="far fa-file-pdf fa-2x" aria-hidden="true"></i>
                    }
                  </div>
                  <div className="text-truncate mx-3"> {fileState?.name}</div>
                  <div onClick={() => setFileState(null)} className="cursor-pointer"> <i class="far fa-trash mt-auto"></i> </div>
                </div>
              )}
            </div>
            <div className="mb-3 mt-3">
              <CustomLabel htmlFor="bs_bank" content="Select Bank" />
              <select
                className="form-select customFormControl"
                name="bs_bank"
                onChange={formik.handleChange}
                value={formik.values.bs_bank}
                aria-label="bs_bank"
                aria-describedby="bs_bank"
              >
                <option defaultValue>Select bank</option>
                {finverBank.map((item, index) => (
                  <option key={index} value={item._id}>
                    {item.bankName}
                  </option>
                ))}
              </select>
              {formik.touched.bs_bank && (
                <div className="form-text text-danger">
                  {formik.errors.bs_bank}
                </div>
              )}
            </div>
            <div className="mt-3">
              <CustomLabel htmlFor="password" content="Password" />
              <CustomInput
                type="password"
                placeholder="Enter password to protected file"
                aria-label="password"
                aria-describedby="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
            </div>
            <div className="d-flex justify-content-between mt-4">
              <button
                type="submit"
                className="btn customButton customPrimaryButton"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
      <CustomConfirmModal
        title="Confirm Loan"
        subtitle="Confirm the details of your saver  before you proceed to assign this loan"
        icon="piggy"
        open={openConfirmModal}
        onClose={handleCloseConfirmModal}
        onConfirm={handleConfirmDeposit}
      >
        <div>
          <ConfirmModalTextRow
            title="Full Name"
            value={formik.values.name}
          />
          <ConfirmModalTextRow title="Phone Number" value="2348123456789" />
          <ConfirmModalTextRow
            title="Loan Amount"
            type="amount"
            value={formik.values.amount}
          />
          <ConfirmModalTextRow
            title="Assignation Date"
            type="date"
            value={date}
          />
          <ConfirmModalTextRow
            title="Repayment Date"
            withBackground={true}
            type="date"
            value={date}
          />
        </div>
      </CustomConfirmModal>
      <CustomModal open={openModal}>
        <SuccessFeedback
          title="Loan Disbursed"
          content={`A loan amount of ${nairaSign}${formik.values.amount} was disbursed successfully.`}
          buttonText="Close"
          onClick={handleCloseModal}
        />
      </CustomModal>
    </>
  );
}
