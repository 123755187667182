import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getSavers,
  saversSelector,
} from "../../store/slices/savers/saversSlice";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import { formatNumberInThousand } from "../../utils/functions";
import SaversPayout from '../savers/savers-payout/SaversPayout'
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import styles from "./savers.module.css";
import { useNavigate } from "react-router-dom";

const tableHeader = [
  "Savers's Name",
  "Amount",
  "Type",
  "Account Number",
  "Duration",
  "Phone Number",
  "Action",
  "..."
];

export default function AllSavers({ filteredSaver }) {
  const { savers, saversIsLoading, error } = useSelector(saversSelector).savers;
  const {
    userData: { id },
  } = useSelector(loginSelector).login;
  const [openTransactionModal, setOpenTransactionModal] = useState(false);
  const [selectedTnx] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saversDetails, setSaversDetails] = useState()

  function handleViewSaver(saver) {
    navigate(`/saver/${saver.phone}`);
  }

  function openWithdrawalModal(details) {
    setSaversDetails(details);
    setOpenTransactionModal(true);
    // console.log({ details, saversDetails });
  }

  useEffect(() => {
    dispatch(getSavers({ agentId: id }));
  }, [id, dispatch]);

  return (
    <>
      {saversIsLoading && (
        <div className="d-flex" style={{ height: "40vh" }}>
          <div className="m-auto d-flex flex-column">
            <Spinner animation="border" className={styles.spinner} size="sm" />
          </div>
        </div>
      )}

      {error && !saversIsLoading && (
        <div className="d-flex" style={{ minHeight: "40vh" }}>
          <div
            className="m-auto d-flex flex-column text-center"
            style={{ maxWidth: "18rem" }}
          >
            {error.message}.
            <button
              onClick={() => dispatch(getSavers({ agentId: id }))}
              className="btn mt-2 w-50 mx-auto btn-secondary"
            >
              REFRESH
            </button>
          </div>
        </div>
      )}

      {!savers[0] && !saversIsLoading && !error && (
        <div className="d-flex" style={{ minHeight: "40vh" }}>
          <div
            className="m-auto d-flex flex-column text-center"
            style={{ maxWidth: "18rem" }}
          >
            No saver added yet.
            <button
              onClick={() => navigate("/savers/add")}
              className="btn mt-2 mx-auto btn-secondary"
            >
              ADD NEW
            </button>
          </div>
        </div>
      )}

      {savers[0] && !saversIsLoading && !error && (
        <div className="bgWhite cardShadow p-3">
          <div className="table-responsive-lg">
            <table className="table fw-bold small">
              <thead className="tableHeader">
                <tr>
                  {tableHeader.map((item, index) => (
                    <th key={index}>{item}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {filteredSaver.map((item, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        {item.first_name} {item.last_name}
                      </td>
                      <td>{formatNumberInThousand(item.collection_amount)}</td>
                      <td className="text-capitalize">{item.saving_type}</td>
                      <td>{item.accounts[0].accountNumber}</td>
                      <td>{typeof (item.savings_duration) == "string" ? item.savings_duration : item.savings_duration / 7 + 'weeks'} </td>
                      <td>{item.phone}</td>
                      <td>
                        <button
                          className="btn btn-sm fw-bold"
                          onClick={() => handleViewSaver(item)}
                        >
                          View
                          {/* <Dropdown></Dropdown> */}
                        </button>
                      </td>
                      <td> <button className="btn btn-outline-primary btn-sm" onClick={() => openWithdrawalModal(item)}>Send</button> </td>
                    </tr>
                    {openTransactionModal && (
                      <SaversPayout
                        open={openTransactionModal}
                        onClose={() => setOpenTransactionModal(false)}
                        transaction={saversDetails}
                      />
                    )}
                  </>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
}
