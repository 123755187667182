import React from 'react'
import PropTypes from 'prop-types'
import StarBg from '../components/svgs/StarBg'
import ladies from '../assets/images/ladies.png';
import lady2 from '../assets/images/lady2.png';
import LogoWhite from '../components/svgs/LogoWhite';

const LayoutAuth = ({children, img}) => {
  return (
    <div className='flex cfade font-ubuntu bg-white'>
      <div className="hidden relative md:block w-[55%] bg-light-blue min-h-screen pl-20 lg:pl-32 xl:pl-40 pr-20 pt-10 lg:pt-20">
        <StarBg className="absolute inset-0 w-full h-full" />
        <span className='flex gap-2 items-center mb-10'>
          <LogoWhite className={'h-12'} />
          <span className='text-white text-[2.15rem]'>Gate</span>
        </span>
        <div className='relative overflow-hidden rounded-[1.25rem] w-full'>
          <img src={img ? ladies : lady2} alt='ladies' className='rm-drag min-h-[30rem] w-full object-cover' />
          <div className='bg-[#212121]/30 h-20 blur-sm transition-all duration-500 text-white absolute w-full bottom-0'>
          </div>
          <h3 className='font-light leading-6 text-white absolute w-full bottom-0 py-3 px-4 lg:px-8 max-w-md transition-all duration-500 xl:text-xl'>Enabling your communities with the right opportunities</h3>
        </div>
      </div>
      <div className='w-full md:w-[45%] p-3 max-h-screen overflowed '>
        {children}
      </div>
    </div>
  )
}

LayoutAuth.propTypes = {
  children: PropTypes.node,
  img: PropTypes.bool
}

export default LayoutAuth