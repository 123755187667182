
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomInput from "../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../widgets/form/custom-label/CustomLabel";
import { useDispatch, useSelector } from "react-redux";
import { newPayout, payoutSelector } from "../../store/slices/savers/payoutSlice";
import { loginSelector } from "../../store/slices/auth/loginSlice";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import { saversSelector } from "../../store/slices/savers/saversSlice";
import { useEffect, useState } from "react";
import SearchDropdown from "../../widgets/search-dropdown/SearchDropdown";
import axios from "axios";
import baseUrl from "../../utils/baseUrl";
import CustomConfirmModal from "../../widgets/modals/CustomConfirmModal";
import ConfirmModalTextRow from "../../widgets/others/ConfirmModalTextRow";
import { dateFormatted } from "../../utils/constants";
import { getAccountName } from "../../store/slices/agent/agentSlice";

const validationSchema = Yup.object({
    accountNumber: Yup.string("Enter your account number")
        .length(10, "Invalid")
        .required("Account Number is required"),
    amount: Yup.string("Enter payout amount")
        .required("Payout amount is required"),
    description: Yup.string("Enter reasons for payout")
        .required("Description is required")
});

export default function PayoutSavingsForm({ changeStep, setPayoutValues }) {
    const { userData } = useSelector(loginSelector).login;
    const [isUserTyping, setIsUserTyping] = useState(false);
    const { savers } = useSelector(saversSelector).savers;
    const [saverError, setSaverError] = useState(false)
    const [searchResults, setSearchResults] = useState([]);
    const [value, setValue] = useState("");
    const [saver, setSaver] = useState(null);

    const [bankList, setBankList] = useState([]);
    const [isUserTypingBank, setIsUserTypingBank] = useState(false);
    const [bank, setBank] = useState(null);
    const [bankName, setBankName] = useState("");
    const [searchBankResults, setSearchBankResults] = useState([]);
    const [bankError, setBankError] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false);

    // for resolving account name
    const [loadingName, setLoadingName] = useState(false);
    const [loadingNameMessage, setLoadingNameMessage] = useState(null)

    const [payoutDetails, setPayoutDetails] = useState(null);

    const { payoutIsLoading, payoutMessage } = useSelector(payoutSelector).payout;

    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            accountNumber: "",
            amount: "",
            receipentBankName: "",
            description: ""
        },
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
    });

    async function handleSubmit(values) {
        if (saver && bank) {
            const getName = await getAccountName({
                "account_number": "0690000010",
                "bank_code": "044",
                // "account_number": formik.values.accountNumber,
                // "bank_code": bank.bankCode1
            }, {
                setLoadingName,
                setLoadingNameMessage
            })
            if (getName) {
                setPayoutDetails({
                    // pots : pay out to saver
                    purchaseReference: `pots-${Math.floor(Math.random() * 1000000000)}`,
                    amount: values.amount,
                    bankcode: bank.bankCode1,
                    accountNumber: values.accountNumber,
                    currency: "NGN",
                    senderName: `${saver.first_name} ${saver.last_name}`,
                    narration: values.description,
                    userId: saver.accounts[0].accountId,
                    agentId: userData.id,
                    transaction_type: "Payout to saver",
                    name: getName,
                })
                setOpenConfirm(true)
            }
        } else if (!saver) {
            setSaverError(true);
        } else if (!bank) {
            setBankError(true);
        }
    }

    async function handlePayout() {
        setPayoutValues(payoutDetails)
        await dispatch(newPayout(payoutDetails, () => changeStep(2)))
        setOpenConfirm(false);
        setLoadingNameMessage(null);
    }

    function setCurrentSaver(saver) {
        setSaver(saver);
        setIsUserTyping(false);
        setSaverError(false);
        setValue(`${saver.first_name} ${saver.last_name}`)
    }

    function setCurrentBank(bank) {
        setBank(bank);
        setIsUserTypingBank(false);
        setBankError(false);
        setBankName(bank.bankName)
    }

    function handleChange(e) {
        setSaver(null);
        setValue(e.target.value);
        setSaverError(false);
        findRelatedText(e.target.value);
        setIsUserTyping(true);
    }

    function handleBankChange(e) {
        setBank(null);
        setBankName(e.target.value);
        setBankError(false);
        findBankRelatedText(e.target.value);
        setIsUserTypingBank(true);
    }

    function findBankRelatedText(searchTerm) {
        setSearchBankResults(
            bankList.filter(({ bankName }) =>
                bankName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        );
    }
    useEffect(() => {
        const fetchd = async () => {
            const result = await axios.get(`${baseUrl}transactions/get_bank_list `);
            const data = result.data;
            if (!data.error || data.status) {
                setBankList(data.data.data);
            }
        }
        fetchd();
    }, [])

    function findRelatedText(searchTerm) {
        setSearchResults(
            savers.filter(({ first_name, last_name }) =>
                first_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                last_name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                `${first_name.toLocaleLowerCase()} ${last_name.toLocaleLowerCase()}`
                    .includes(searchTerm.toLocaleLowerCase()) ||
                `${last_name.toLocaleLowerCase()} ${first_name.toLocaleLowerCase()}`
                    .includes(searchTerm.toLocaleLowerCase())
            )
        );
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {payoutMessage &&
                    <div className={`${payoutMessage?.error ? 'text-danger' : 'text-success'}`}>{payoutMessage.message}</div>}
                {loadingNameMessage &&
                    <div className={`${loadingNameMessage?.error ? 'text-danger' : 'text-success'}`}>{loadingNameMessage.message}</div>}
                <div className="mb-3">
                    <CustomLabel htmlFor="receipentName" content="Receipent" />
                    <CustomInput
                        type="text"
                        aria-label="receipentName"
                        aria-describedby="receipentName"
                        name="receipentName"
                        onChange={handleChange}
                        value={value}
                    />
                    <SearchDropdown
                        isActive={Boolean(savers[0] && value && isUserTyping)}
                        options={searchResults}
                        option="name"
                        onClick={setCurrentSaver}
                    />
                    {saverError && <div className="form-text text-danger">Select a saver</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="accountNumber" content="Account Number" />
                    <CustomInput
                        type="text"
                        placeholder="0000000000"
                        aria-label="accountNumber"
                        aria-describedby="accountNumber"
                        name="accountNumber"
                        onChange={formik.handleChange}
                        value={formik.values.accountNumber}
                    />
                    {formik.touched.accountNumber && <div className="form-text text-danger">{formik.errors.accountNumber}</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="bankId" content="Bank Name" />
                    <CustomInput
                        type="text"
                        aria-label="bankId"
                        aria-describedby="bankId"
                        name="bankId"
                        onChange={handleBankChange}
                        value={bankName}
                    />
                    <SearchDropdown
                        isActive={Boolean(bankList[0] && bankName && isUserTypingBank)}
                        options={searchBankResults}
                        option="name"
                        onClick={setCurrentBank}
                    />
                    {bankError && <div className="form-text text-danger">Select a Bank</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="amount" content="Payout Amount" />
                    <CustomInput
                        type="number"
                        placeholder="₦0.00"
                        aria-label="PayoutAmount"
                        aria-describedby="amount"
                        name="amount"
                        onChange={formik.handleChange}
                        value={formik.values.amount}
                    />
                    {formik.touched.amount && <div className="form-text text-danger">{formik.errors.amount}</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="description" content="Description" />
                    <CustomInput
                        type="text"
                        placeholder="Add comment"
                        aria-label="description"
                        aria-describedby="description"
                        name="description"
                        onChange={formik.handleChange}
                        value={formik.values.description}
                    />
                    {formik.touched.description && <div className="form-text text-danger">{formik.errors.description}</div>}
                </div>
                <div className="d-flex justify-content-between">
                    <CustomButton
                        type="submit"
                        color="primary"
                        loading={loadingName}
                    />
                </div>
            </form>
            <CustomConfirmModal
                title="Confirm Payout Details"
                subtitle="Confirm the details of the saver account name"
                icon="piggy"
                loading={payoutIsLoading}
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                onConfirm={handlePayout}
            >
                <div className="">
                    <ConfirmModalTextRow title="Account Name" value={payoutDetails?.name} />
                    {/* <ConfirmModalTextRow
            title="Phone Number"
            value={formik.values.phone}
          /> */}
                    <ConfirmModalTextRow
                        title="Amount"
                        type="amount"
                        value={payoutDetails?.amount}
                    />
                    {/* <ConfirmModalTextRow
            title="Contribution Type"
            value={formik.values.saving_type}
          /> */}
                    <ConfirmModalTextRow title="Date" type="date" value={dateFormatted} />
                </div>
            </CustomConfirmModal>
        </>
    )
}