import CustomIcon from "../../icon/CustomIcon";
import PropTypes from "prop-types";

export default function CustomButtonWithIcon({
  color,
  text,
  textColor,
  icon,
  iconColor,
  withShadow,
  onClick,
}) {
  return (
    <button
      className={`btn btn-sm d-flex m-1 align-items-center ${withShadow ? "buttonShadow" : ""
        } ${color === "primary"
          ? "customPrimaryButton text-white"
          : color === "secondary"
            ? "customSecondaryButton"
            : color === "grey"
              ? "customGreyButton"
              : "btn-light"
        }`}
      onClick={onClick}
    >
      <CustomIcon name={icon} color={iconColor} />
      <span className="ms-2 small" style={{color: textColor || iconColor}}>
        {text}
      </span>
    </button>
  );
}

CustomButtonWithIcon.propTypes = {
  color: PropTypes.string,
  text: PropTypes.string,
  textColor: PropTypes.string,
  icon: PropTypes.string,
  withShadow: PropTypes.bool,
  iconColor: PropTypes.string,
};
