import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
	savers: [],
	saversIsLoading: true,
	error: null,

	newSaverDetails: null,
	newSaverLoading: false,
	newSaverMessage: null,
	newSaverStep: 1
};

export const saversSlice = createSlice({
	name: "savers",
	initialState,
	reducers: {
		setNewSaverMessage: (state, {payload}) => {
			// every change in message will set step back to 1
			state.newSaverStep = 1;
			// parse payload
			state.newSaverMessage = JSON.parse(payload)?.data;
			state.newSaverLoading = false;
		},
		setSavers: (state, {payload}) => {
			state.savers = payload;
			state.saversIsLoading = false;
			// setting savers successfully means error should be null
			state.error = null;
		},
		setSaversIsLoading: (state, {payload}) => {
			state.saversIsLoading = payload;
		},
		setError: (state, {payload}) => {
			state.saversIsLoading = false;
			state.error = JSON.parse(payload)?.data;
		},
		setNewSaverDetails: (state, {payload}) => {
			state.newSaverDetails = payload ? {...state.newSaverDetails, ...payload} : null
		},
		setNewSaverStep: (state, {payload}) => {
			// every change in step will set message to null
			state.newSaverStep = payload;
			state.newSaverMessage = null;
		}
	},
});

//actions
export const {setSavers, setNewSaverMessage, setSaversIsLoading, setError, setNewSaverDetails, setNewSaverStep} = saversSlice.actions;

//selectors
export const saversSelector = (state) => state;

//reducer
export default saversSlice.reducer;

//Asynchronous thunk action
export const getSavers = (details) => {
	return async (dispatch) => {
		dispatch(setSaversIsLoading(true));
		try {
			const result = await axios.post(`${baseUrl}auth/get-all-user-by-agent`, details);
			const data = result.data;
			if (data.data) {
				dispatch(setSavers(data.data));
			} else {
				setError(JSON.stringify(data));
			}
		} catch (error) {
			if (error.response) {
				let err = JSON.stringify(error.response); //convert object to string to enable dispatch
				dispatch(setError(err));
			} else {
				dispatch(setError(JSON.stringify({data: {message: "An error occured, please check your connection and try again"}})));
			}
		}
	};
};


export const newSaver = (details) => {
	return async (dispatch) => {
		try {
			const result = await axios.post(`${baseUrl}auth/create-user`, details);
			const data = result.data;
			if (!data.error) {
				// clear previous data after saver is successfully added
				dispatch(setNewSaverDetails(null));
				dispatch(setNewSaverMessage(JSON.stringify(
					{
						data: {message: "Saver registered successfully", error: false}
					}
				)));
			} else {
				dispatch(setNewSaverDetails(null))
				dispatch(setNewSaverMessage(data));
			}
		} catch (error) {
			if (error.response) {
				let err = JSON.stringify(error.response); //convert object to string to enable dispatch
				dispatch(setNewSaverMessage(err));
			} else {
				// dispatch(setNewSaverMessage(JSON.stringify({data: {message: "An error occured, please try again", error: true}})));
			}
		}
	};
};

