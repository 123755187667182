import CustomModal from "../../../widgets/modals/CustomModal";
import FundWallet from "./FundWallet";

export default function FundWalletModal({ open, onClose }) {
  return (
    <CustomModal
      open={open}
      position="right"
      onClose={onClose}
      dialogClassName="w-md-50"
      isFullscreen={true}
      title="Fund Wallet"
    >
      <FundWallet />
    </CustomModal>
  );
}
