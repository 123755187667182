import Modal from "react-bootstrap/Modal";
import CustomIcon from "../icon/CustomIcon";
import PropTypes from "prop-types";
import CustomButton from "../form/custom-button/CustomButton";
import CustomCloseButtonIcon from "../form/custom-button/CustomCloseButtonIcon";

function positionClass(position) {
  return position === "right" ? "modalRight" : "left" ? "modalLeft" : "";
}

export default function CustomConfirmModal({
  open,
  onClose,
  title,
  subtitle,
  icon,
  position,
  dialogClassName,
  primaryButtonText,
  isPrimaryButtonDisabled,
  isFullscreen,
  onConfirm,
  children,
  loading
}) {
  function handleConfirm() {
    onConfirm();
  }

  return (
    <Modal
      show={open}
      onHide={onClose}
      fullscreen={isFullscreen}
      dialogClassName={`${positionClass(position)} ${dialogClassName}`}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body className="p-0">
        <div className="bgLightBlue text-center customBorderRadius py-5 position-relative">
          <div
            className="position-absolute"
            style={{top: 0, right: 0, marginTop: "10px"}}
          >
            <CustomCloseButtonIcon onClose={onClose} color="grey" />
          </div>
          <CustomIcon name={icon} size={120} />
          <div className="w-75 mx-auto">
            <h3 className="fw-bold textPrimary my-3">{title}</h3>
            <div className="small textPrimary mb-4">{subtitle}</div>
          </div>
          <div className="bg-white py-4 px-3">{children}</div>
          <br />
          <CustomButton
            color="primary"
            disabled={isPrimaryButtonDisabled}
            onClick={handleConfirm}
            text={primaryButtonText || "Proceed"}
            loading={loading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}

CustomConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.string,
  position: PropTypes.string,
  dialogClassName: PropTypes.string,
  primaryButtonText: PropTypes.string,
  isPrimaryButtonDisabled: PropTypes.bool,
  isFullscreen: PropTypes.bool,
  children: PropTypes.node,
};
