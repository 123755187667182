import styles from "./custom-textarea.module.css";

export default function CustomTextarea(props) {
    const {appendValue, prependValue, ...rest} = props;

    if(prependValue) return (
        <div className="input-group">
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
        </div>
    )
    if(appendValue) return (
        <div className="input-group">
            <input {...rest} className={["form-control", styles.customInput].join(' ')} />
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
        </div>
    )
    if(!prependValue && !appendValue) return <textarea className={[ "form-control" , styles.customInput].join(' ')} {...rest}></textarea>
}