import {useFormik} from "formik";
import * as Yup from "yup";
import {sendOTP} from "../../../store/slices/auth/forgetPasswordSlice";
import {useState} from "react";
import ACInput from "../../../widgets/form/ACInput";
import ACBtn from "../../../widgets/form/ACBtn";
import {Link} from "react-router-dom";

const validationSchema = Yup.object({
  // phone: Yup.string("Enter your Phone Number")
  //   .phone("Phone Number is not valid")
  //   .required("Account phone is required"),
  phone: Yup.string("Enter your Phone Number")
    .max(11, "Invalid")
    .required("Account phone is required"),
});

export default function AccountPhone({changeStep, setAgentId}) {
  // const {mailIsSending, message} = useSelector(forgetPasswordSelector).forgetPassword;
  const [message, setMessage] = useState(null);

  const [otpIsSending, setOtpIsSending] = useState(false);
  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (e) => {
    setOtpIsSending(true);
    setMessage(null);
    await sendOTP({phone: e.phone, bvn: "ds"}, {setMessage, changeStep, setAgentId})
    setOtpIsSending(false);
  };



  return (
    <>
      <form onSubmit={formik.handleSubmit} className='cfade transition-all duration-500 max-w-md py-20 mx-[auto] md:mx-0 px-3 lg:pl-8 flex min-h-screen flex-col'>
        <h3 className='text-light-blue text-2xl md:text-[2rem] font-bold'>Forgot Password</h3>
        <p className='text-sm'>Enter the Phone Number attached to your account and you'll get an OTP to reset your password. </p>
        {message &&
          <div className={`text-xs ${message?.error ? 'text-danger' : 'text-success'}`}>{message.text}</div>}

        <div className='flex flex-col gap-3 pt-7'>
          <ACInput inputProps={{placeholder: '08000000000', name: 'phone'}} formik={formik} label={'Phone Number'} />

          <ACBtn loading={otpIsSending} text='Get OTP' className={'mt-7'} />
          <div className='flex gap-2 text-center justify-center'>
            <span className='font-light'>Remember Password?</span>
            <Link to="/auth/login" className="text-light-blue hover:text-blue-600 transition">
              Log In
            </Link>
          </div>
        </div>
      </form>
    </>
  );
}
