import PageHeader from "../../widgets/page-header/PageHeader";
import RecentSavers from "../../components/deposit-savings/recent-savers/RecentSavers";
import NewLoanForm from "../../components/loans/new/NewLoanForm";

export default function NewLoan() {
  return (
    <>
      <PageHeader title="New Loan" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="fw-bold">Recent</div>
            </div> */}
            {/* <RecentSavers /> */}
            <div className="customFormCard mt-3 py-5">
              <NewLoanForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
