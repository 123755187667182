import styles from "./custom-select.module.css";

export default function CustomSelect(props) {
    const {appendValue, prependValue, placeholder, name, options, option, innerLabel, ...rest} = props;

    if (prependValue) return (
        <div className="input-group">
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{prependValue}</span>
            <select className="form-select customFormControl" name={name} {...rest}>
                {placeholder && <option value="">{placeholder}</option>}
                {options?.map((item, index) => <option key={index} value={index}>{option ? item[option] : item}</option>)}
            </select>
        </div>
    )
    if (appendValue) return (
        <div className="input-group">
            <select className="form-select customFormControl" name={name} {...rest}>
                {placeholder && <option value="">{placeholder}</option>}
                {options?.map((item, index) => <option key={index} value={index}>{option ? item[option] : item}</option>)}
            </select>
            <span className={["input-group-text text-muted", styles.customInput].join(' ')}>{appendValue}</span>
        </div>
    )
    if (!prependValue && !appendValue) return (
        <select className="form-select customFormControl" name={name} {...rest}>
            {placeholder && <option value="">{placeholder}</option>}
            {options?.map((item, index) => <option key={index} value={item[option]}>
                {
                    option ?
                        (innerLabel ? item[innerLabel] : item[option]) : item
                }
            </option>)}
        </select>
    )
}