import {useNavigate} from "react-router-dom";
import LoginForm from "../../../components/auth/login/LoginForm";
import LayoutAuth from "../../../layouts/LayoutAuth";
import OTPLogin from "../../../components/auth/login/OTPLogin";
import {loginSelector, setLoginStep, setMessage, setSuccess} from "../../../store/slices/auth/loginSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

function Login() {
  const {step, success} = useSelector(loginSelector).login;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (success) navigate("/");
    dispatch(setSuccess(false));
  }, [success, dispatch, navigate])

  useEffect(() => {
    dispatch(setMessage(null));
    dispatch(setLoginStep(1));
  }, [dispatch])
  return (
    <LayoutAuth img>
      {step === 1 && <LoginForm />}
      {step === 2 && <OTPLogin />}
    </LayoutAuth>
  );
}

export default Login;
