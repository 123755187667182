// import {useState, useEffect} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
// import nigerianStates from "../../../utils/nigerianStates";
import {setStep, signUpUser, signupSelector} from "../../../store/slices/auth/signupSlice";
import ACBtn from "../../../widgets/form/ACBtn";
import SignUpStepTitle from "./SignUpStepTitle";
import ACInput from "../../../widgets/form/ACInput";

const validationSchema = Yup.object({
  first_name: Yup.string("Enter saver's first name")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("First Name is required"),
  last_name: Yup.string("Enter saver's last name (surname)")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Surname is required"),
  phone: Yup.string("This will be needed during login, e.g 08012345678")
    .min(11, "Too short! (format, 08012345678)")
    .max(11, "Too long! (format, 08012345678)")
    .required("Phone number is required"),
  // dob: Yup.string("Enter your date of birth").required(
  //   "Date of birth is required"
  // ),
  // gender: Yup.string("Select gender").required("Gender is required"),
  // email: Yup.string("Enter your email")
  // .email("Not a valid email")
  // .required("Email is required"),
  // occupation: Yup.string("Enter Occupation").required("Occupation is required"),
  // residentialAddress: Yup.string("Enter Your Residential Address").required(
  //   "Residential address is required"
  // ),
  // state: Yup.string("Select your State").required("Your state is required"),
  // lga: Yup.string("Select your Local Government Area"),
  // city: Yup.string("Select your City").required("City is required"),
  // marital_status: Yup.string("Select your marital status").required("Select is marital status"),
  // NIN: Yup.string('Enter your NIN').required(' Your NIN is required'),
  // middle_name: Yup.string('Enter your middle name').required(' Your middle name is required')
});

export default function SignUpPersonalInformation() {

  // const [lgaList, setLgaList] = useState([]);
  const {loading,
    // userData,
    message} = useSelector(signupSelector).signup;
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      // middle_name: "",
      phone: "",
      // dob: "",
      // gender: "",
      // email: "",
      // occupation: "",
      // residentialAddress: "",
      // lga: "",
      // city: "",
      // state: "",
      // marital_status: '',
      // NIN: ''
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values)
  });

  // useEffect(() => {
  //   let updatedLgas =
  //     nigerianStates?.find((item) => formik.values.state === item.name)?.lgas ||
  //     [];

  //   setLgaList(updatedLgas);
  // }, [formik.values.state]);

  const handleSubmit = (values) => {
    dispatch(signUpUser({...values}));
    // dispatch(signUpUser({...values, bvn: userData.bvn}));
  };

  return (
    <form onSubmit={formik.handleSubmit} className='cfade max-w-[30rem] px-3 pb-5 pt-20 mx-[auto] md:mx-0 lg:pl-8 flex flex-col'>
      <SignUpStepTitle title="Personal Information" subtitle="You're required to fill this information" message={message} />

      <div className="flex flex-col sm:grid grid-cols-2 gap-[1rem] sm:gap-[.6rem] py-3">
        <ACInput label={'First Name'} formik={formik} inputProps={{name: 'first_name', placeholder: 'First Name'}} className={'col-span-2'} />
        <ACInput label={'Last Name'} formik={formik} inputProps={{name: 'last_name', placeholder: 'Last Name'}} className={'col-span-2'} />
        {/* <ACInput label={'Middle Name'} formik={formik} inputProps={{name: 'middle_name', placeholder: 'Middle Name'}} />
        <ACInput label={'Gender'} formik={formik} inputProps={{name: 'gender', placeholder: 'Gender', type: 'select'}} options={["Male", "Female"]} /> */}
        <ACInput label={'Phone Number'} formik={formik} inputProps={{name: 'phone', placeholder: '08000000000'}} className={'col-span-2'} />
        {/* <ACInput label={'Email Address'} formik={formik} inputProps={{name: 'email', placeholder: 'example@domain.com'}} className={'col-span-2'} />
        <ACInput label={'Date of Birth'} formik={formik} inputProps={{name: 'dob', placeholder: 'Date of Birth', type: 'date'}} />
        <ACInput label={'Occupation'} formik={formik} inputProps={{name: 'occupation', placeholder: 'Occupation'}} />
        <ACInput label={'NIN'} formik={formik} inputProps={{name: 'NIN', placeholder: '00000000000'}} />
        <ACInput label={'Residential Address'} formik={formik} inputProps={{name: 'residentialAddress', placeholder: 'Address'}} />
        <ACInput label={'City'} formik={formik} inputProps={{name: 'city', placeholder: 'City'}} />
        <ACInput label={'Marital Status'} formik={formik} inputProps={{name: 'marital_status', placeholder: 'Gender', type: 'select'}} options={['Single', 'Married']} />
        <ACInput label={'State'} formik={formik} inputProps={{name: 'state', placeholder: 'Select State', type: 'select'}} options={nigerianStates} optionDisplayKey={'name'} optionValueKey={'name'} />
        <ACInput label={'Local Government'} formik={formik} inputProps={{name: 'lga', placeholder: 'Select LGA', type: 'select'}} options={lgaList} /> */}
      </div>
      <div className='flex justify-end'>
        {/* <button type='button' onClick={() => dispatch(setStep(1))} className='gr-btn' disabled={loading}>Back</button> */}
        <ACBtn type='submit' text={'Continue'} loading={loading} className={'max-w-[8rem]'} />
      </div>
    </form>
  );
}
