import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
	depositIsLoading: false,
	error: null,
	depositMessage: null,
	success: false,
};

export const depositSlice = createSlice({
	name: "deposit",
	initialState,
	reducers: {
		setDepositMessage: (state, {payload}) => {
			// parse payload
			state.depositMessage = payload;
			state.newDepositLoading = false;
		},
		setDeposit: (state, {payload}) => {
			state.deposit = payload;
			state.depositIsLoading = false;
			// setting deposit successfully means error should be null
			state.error = null;
		},
		setDepositIsLoading: (state, {payload}) => {
			state.depositIsLoading = payload;
		},
		setError: (state, {payload}) => {
			state.depositIsLoading = false;
			state.error = payload;
		},
		setNewDepositDetails: (state, {payload}) => {
			state.newDepositDetails = {...state.newDepositDetails, ...payload}
		},
		setNewDepositStep: (state, {payload}) => {
			// every change in step will set message to null
			state.newDepositStep = payload;
			state.depositMessage = null;
		},
		resetDeposit: (state) => {
			state.depositIsLoading = false;
			state.error = null;
			state.depositMessage = null;
			state.success = false;
		}
	},
});

//actions
export const {setDeposit, resetDeposit, setSaver, setDepositMessage, setDepositIsLoading, setError, setNewDepositDetails, setNewDepositStep} = depositSlice.actions;

//selectors
export const depositSelector = (state) => state;

//reducer
export default depositSlice.reducer;

//Asynchronous thunk action

export const newDeposit = (details, action) => {
	return async (dispatch) => {
		dispatch(setDepositIsLoading(true));
		try {
			const result = await axios.post(`${baseUrl}transactions/fund-user-by-agent`, details);
			const data = result.data;
			if (!data.error || data.status) {
				dispatch(resetDeposit());
				dispatch(setDepositMessage({error: false, message: data.message}));
			} else {
				dispatch(setDepositMessage(data));
			}
		} catch (error) {
			if (error.response) {
				let err = error.response?.data
				dispatch(setDepositIsLoading(false));
				if (err) { // to avoid saving unnecessary data to app state.
					dispatch(setDepositMessage({
						error: true,
						message: err?.error?.data?.forbidden ? err.error.data.forbidden : err?.error?.message
					}));
				}
			} else {
				dispatch(setDepositMessage({data: {message: "An error occured, please try again", error: true}}));
			}
		}
		action();
	};
};

