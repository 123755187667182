import PropTypes from "prop-types";
import dayjs from "dayjs";
import { formatNumberInThousand } from "../../utils/functions";

export default function ConfirmModalTextRow({
  title,
  value,
  type,
  withBackground,
}) {
  return (
    <div
      className={`d-flex gap-4 small py-1 ${
        withBackground ? "bgLightBlue rounded" : ""
      }`}
    >
      <div className="w-100 text-end">{title}</div>
      <div className="w-100 fw-bold textPrimary text-start">
        {type === "amount"
          ? formatNumberInThousand(Number(value).toFixed(2))
          : type === "date"
          ? dayjs(value).format("MMM DD, YYYY")
          : value}
      </div>
    </div>
  );
}

ConfirmModalTextRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  type: PropTypes.string,
  withBackground: PropTypes.bool,
};
