import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Login from "../pages/auth/login/Login";
import SignUp from "../pages/auth/sign-up/SignUp";
import Dashboard from "../pages/dashboard/Dashboard";
import DepositSavings from "../pages/deposit-savings/DepositSavings";
import PayoutSavings from "../pages/payout-savings/PayoutSavings";
import ProfileBankDetailsPage from "../pages/profile/ProfileBankDetailsPage";
// import ProfileChangePasswordPage from "../pages/profile/ProfileChangePasswordPage";
import ProfileForgotPasswordPage from "../pages/profile/ProfileForgotPasswordPage";
import ProfileResetPINPage from "../pages/profile/ProfileResetPINPage";
import ProfileGuarantorsInformationPage from "../pages/profile/ProfileGuarantorsInformationPage";
import ProfileProofOfAddressPage from "../pages/profile/ProfileProofOfAddressPage";
import ProfileProofOfIdentityPage from "../pages/profile/ProfileProofOfIdentityPage";
import SaverDetail from "../pages/saver/detail/SaverDetail";
import AddSaver from "../pages/savers/add-saver/AddSaver";
import Savers from "../pages/savers/Savers";
import Transactions from "../pages/transactions/Transactions";
import Wallet from "../pages/wallet/Wallet";
import ProfilePersonalInfoPage from "../pages/profile/ProfilePersonalInfoPage";
import Profile from "../pages/profile/Profile";
import ProtectedRoute from "./ProtectedRoute";
import ForgotPassword from "../pages/auth/forgot-password/ForgotPassword";
import Loans from "../pages/loans/Loans";
import NewLoan from "../pages/loans/New";
import IsVerified from "../guards/IsVerified";
import {Toaster} from "react-hot-toast";

function RoutesComponent() {
  return (
    <Router>
      <Routes>
        <Route key="auth" path="/auth" element={<Login />} />
        <Route key="auth-login" path="/auth/login" element={<Login />} />
        <Route key="auth-signup" path="/auth/signup" element={<SignUp />} />
        <Route key="auth-forgot" path="/auth/forgot-password" element={<ForgotPassword />} />
        <Route key="protected" path="" element={<ProtectedRoute />} children={[
          <Route key="index" path="/" element={<Dashboard />} />,
          <Route key="profile" path="/profile" element={<Profile />}>
            <Route index key="profile-index" element={<ProfilePersonalInfoPage />} />
            <Route
              key="profile-index-2"
              index
              path="personal-info"
              element={<ProfilePersonalInfoPage />}
            />
            <Route key="bank-details" path="bank-details" element={<ProfileBankDetailsPage />} />
            <Route
              key="proof-of-address"
              path="proof-of-address"
              element={<ProfileProofOfAddressPage />}
            />
            <Route
              key="guarantors-information"
              path="guarantors-information"
              element={<ProfileGuarantorsInformationPage />}
            />
            <Route
              key="proof-of-identity"
              path="proof-of-identity"
              element={<ProfileProofOfIdentityPage />}
            />
            <Route
              key="reset-password"
              path="reset-password"
              element={<ProfileForgotPasswordPage />}
            />
            {/* <Route
            path="change-password"
            element={<ProfileChangePasswordPage />}
          /> */}
            <Route key="reset-pin" path="reset-pin" element={<ProfileResetPINPage />} />
          </Route>,
          <Route key="others" path="" element={<IsVerified />} children={[
            <Route key="wallet" path="/wallet" element={<Wallet />} />,
            <Route key="transactions"
              path="/transactions"
              element={<Transactions />}
            />,
            <Route key="savers" path="/savers" element={<Savers />} />,
            <Route key="savers-add"
              path="/savers/add"
              element={<AddSaver />}
            />,
            <Route key="savers-phone"
              path="/saver/:phone"
              element={<SaverDetail />}
            />,
            <Route key="saver-all"
              path="/saver/*"
              element={<Savers />}
            />,
            <Route key="loan" path="/loans" element={<Loans />} />,
            <Route key="loans-new"
              path="/loans/new"
              element={<NewLoan />}
            />,
            <Route key="deposit"
              path="/deposit-savings"
              element={<DepositSavings />}
            />,
            <Route key="payout-sa"
              path="/payout-savings"
              element={<PayoutSavings />}
            />,
          ]} />
        ]} />
        <Route key="nothing-here" path="*" element={<p>There's nothing here: 404!</p>} />
      </Routes>
      <Toaster position="bottom-left" />
    </Router>
  );
}

export default RoutesComponent;
