import {useCallback, useEffect, useState} from "react";
import CustomModal from "../../../widgets/modals/CustomModal";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";
import CustomBackButton from "../../../widgets/form/custom-button/CustomBackButton";
import CustomForm from "../../../widgets/form/CustomForm";
import {useFormik} from "formik";
import CustomButton from "../../../widgets/form/custom-button/CustomButton";
import {withdrawFromWalletfields} from "../../../utils/formFields";
import {withdrawFromWalletSchema as validationSchema} from "../../../utils/yupSchemas";
import baseUrl from "../../../utils/baseUrl";
import axios from "axios";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import SearchDropdown from "../../../widgets/search-dropdown/SearchDropdown";
import {getAccountName} from "../../../store/slices/agent/agentSlice";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import {newPayout, payoutSelector, setPayoutIsLoading, setPayoutMessage} from "../../../store/slices/savers/payoutSlice";
import {useDispatch} from "react-redux";
import useVerify from "../../../hooks/useVerify";
import OtpInput from "react-otp-input";
import {otpInputStyle} from "../../../utils/constants";

export default function WithdrawFromSavers({onClose, details}) {
    const {userData} = useSelector(loginSelector).login;
    const [bankList, setBankList] = useState([]);
    const [isUserTypingBank, setIsUserTypingBank] = useState(false);
    const [bank, setBank] = useState(null);
    const [bankName, setBankName] = useState("");
    const [searchBankResults, setSearchBankResults] = useState([]);
    const [bankError, setBankError] = useState(false)
    const [pin, setPin] = useState("");
    const [loading, setLoading] = useState(false)


    // for resolving account name
    const [loadingName, setLoadingName] = useState(false);
    const [loadingNameMessage, setLoadingNameMessage] = useState(null)
    const [accountName, setAccountName] = useState("")

    const [option, setOption] = useState(null);
    const [open, setOpen] = useState(true);
    const [openOTP, setOpenOTP] = useState(false)

    const {payoutIsLoading, payoutMessage} = useSelector(payoutSelector).payout;
    const verify = useVerify()

    function handleChange(pin) {
        setPin(pin);
    }
    const formik = useFormik({
        initialValues: {
            accountNumber: "",
            amount: "",
            description: "Withdrawal from AjoBank Wallet",
        },
        validationSchema,
        onSubmit: (values, {resetForm}) => handleSubmit(values, resetForm),
    });
    const dispatch = useDispatch()
    const handleWithdraw = async () => {
        setLoading(true)
        try {
            const otp = await axios.post(`${baseUrl}auth/verify_otp_for_savers_withdrawal`, {userId: details.id, otp: pin})

            if (otp.data.status) {
                let n_details = {
                    // wfw : Withdrawal from wallet
                    purchaseReference: `wfw-${Math.floor(Math.random() * 1000000000)}`,
                    amount: formik.values.amount,
                    bankcode: bank?.bankCode1,
                    accountNumber: formik.values.accountNumber,
                    currency: "NGN",
                    senderName: `${details.first_name} ${details.last_name}`,
                    narration: formik.values.description,
                    userId: details.accounts[0].accountId,
                    transaction_type: "Withdrawal from wallet",
                    agentId: userData.id
                }
                dispatch(newPayout(n_details, () => {
                    verify();
                    setOpen(true);
                    formik.resetForm();
                    // onClose()
                }))
            }
        } catch (error) {
            console.log(error);
        }
    }
    const handleSubmit = async (values, resetForm) => {
        if (bank && !loadingName && accountName) {
            setLoading(true)
            const res = await axios.post(`${baseUrl}auth/send_otp_for_withdrawal`, {phone: details?.phone, userId: details.id, })
            if (res.data.status) {
                setLoading(false)
                setOpenOTP(true);
            }
        } else if (!bank) {
            setBankError(true);
        }
    }
    const resolveAccountName = useCallback(
        async (value) => {
            if (Number(value.length) === 10) {
                const accName = await getAccountName({
                    account_number: value,
                    bank_code: bank?.bankCode1
                }, {
                    setLoadingName,
                    setLoadingNameMessage,
                })
                if (accName) {
                    setAccountName(accName);
                } else {
                    setAccountName("");
                }
            }
        }, [bank?.bankCode1]
    )
    function setCurrentBank(bank) {
        setBank(bank);
        setIsUserTypingBank(false);
        setBankError(false);
        setBankName(bank.bankName)
    }
    function handleBankChange(e) {
        setBank(null);
        setBankName(e.target.value);
        setBankError(false);
        findBankRelatedText(e.target.value);
        setIsUserTypingBank(true);
    }
    function findBankRelatedText(searchTerm) {
        setSearchBankResults(
            bankList.filter(({bankName, bankCode1, bankCode2}) =>
                bankName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
            )
        );
    }
    useEffect(() => {
        resolveAccountName(formik.values.accountNumber);
    }, [bank, formik.values.accountNumber, resolveAccountName])

    const fetchBankList = useCallback(
        async () => {
            const result = await axios.get(`${baseUrl}transactions/get_bank_list `);
            const data = result.data;
            if (!data.error || data.status) {
                setBankList(data.data.data);
            }
        },
        [],
    )
    useEffect(() => {
        fetchBankList();
    }, [fetchBankList])

    console.log({payoutIsLoading, payoutMessage});


    useEffect(() => {
        dispatch(setPayoutMessage(null));
        dispatch(setPayoutIsLoading(false));
    }, [dispatch])

    return (
        <>
            {option && <CustomBackButton onBack={() => setOption(null)} />}
            <form onSubmit={formik.handleSubmit}>
                {payoutMessage &&
                    <div className={`${payoutMessage?.data?.error ? 'text-danger' : 'text-success'}`}>{payoutMessage?.data?.message}</div>}
                <div className="mb-3">
                    <CustomLabel htmlFor="bankId" content="Bank Name" />
                    <CustomInput
                        type="text"
                        aria-label="bankId"
                        aria-describedby="bankId"
                        name="bankId"
                        onChange={handleBankChange}
                        value={bankName}
                    />
                    <SearchDropdown
                        isActive={Boolean(bankList[0] && bankName && isUserTypingBank)}
                        options={searchBankResults}
                        option="name"
                        onClick={setCurrentBank}
                    />
                    {bankError && <div className="form-text text-danger">Select a Bank</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="accountNumber" content="Account Number" />
                    <CustomInput
                        type="text"
                        placeholder="0000000000"
                        aria-label="accountNumber"
                        aria-describedby="accountNumber"
                        name="accountNumber"
                        onChange={formik.handleChange}
                        value={formik.values.accountNumber}
                    />
                    {formik.touched.accountNumber && <div className="form-text text-danger">{formik.errors.accountNumber}</div>}
                </div>
                <div className="mb-3">
                    <CustomLabel htmlFor="AccountName" content="Account Name" />
                    <CustomInput
                        disabled
                        type="text"
                        placeholder="..."
                        aria-label="accountName"
                        aria-describedby="accountName"
                        name="accountName"
                        value={loadingName ? 'Checking...' : accountName}
                    />
                    {loadingNameMessage &&
                        <div className={`${loadingNameMessage?.error ? 'text-danger form-text' : 'text-success'}`}>{loadingNameMessage.message}</div>}
                </div>
                {withdrawFromWalletfields.map((field, index) => (
                    <CustomForm item={field} formik={formik} key={index} />
                ))}
                <br />
                <CustomButton
                    type="submit"
                    color="primary"
                    text="Generate OTP"
                    loading={loading}
                />
            </form>
            <CustomModal title='Confirm OTP' open={openOTP} onClose={() => setOpen(false)}>
                <div className="my-2">Please enter the OTP sent to the Savers Phone Number</div>
                <OtpInput
                    inputStyle={{...otpInputStyle, width: '100%'}}
                    isInputNum
                    shouldAutoFocus
                    value={pin}
                    onChange={handleChange}
                    isInputSecure={true}
                    numInputs={6}
                    separator={<span>&nbsp; &nbsp;</span>}
                />
                <div className="mt-3">
                    <CustomButton
                        type="submit"
                        color="primary"
                        text="Withdraw"
                        loading={loading}
                        onClick={handleWithdraw}
                    />
                </div>
            </CustomModal>
            {/* <CustomModal open={open} onClose={() => setOpen(false)}>
                <SuccessFeedback
                    title="Withdrawal Successfully"
                    content="You have successfully withdrawn from your wallet."
                    onClick={() => setOpen(false)}
                    buttonText="Ok"
                />
            </CustomModal> */}
        </>
    );
}
