import * as Yup from "yup";

export const bankTransferSchema = Yup.object({
  fullName: Yup.string("Enter full name").required("Full name is required"),
  accountNumber: Yup.string("Enter account number, e.g 9999999999")
    .min(11, "Too short! must be 11 in length")
    .max(11, "Too long! must be 11 in length")
    .required("Account number is required"),
  // amount: Yup.string("Enter amount")
  //   .max(7, "Amount is too much!")
  //   .required("Amount is required"),
  bankName: Yup.string("Select your bank name").required(
    "Bank name is required"
  ),
});

export const cardPaymentSchema = Yup.object({
  amount: Yup.string("Enter amount")
    .max(7, "Too long!")
    .required("Amount is required"),
});

export const withdrawFromWalletSchema = Yup.object({
  accountNumber: Yup.string("Enter account number, e.g 9999999999")
    .length(10, "Must be 10 in length")
    .required("Account number is required"),
  amount: Yup.string("Enter amount")
    .max(7, "Amount is too much!")
    .required("Amount is required"),
  description: Yup.string("Enter widrawal description")
    .required("Description is required")
});

export const profileBankDetailsSchema = Yup.object({
  account_number: Yup.string("Enter account number, e.g 9999999999")
    .min(10, "Too short! must be 10 in length")
    .max(10, "Too long! must be 10 in length")
    .required("Account number is required"),
  bank_name: Yup.string("Select your bank name").required(
    "Bank name is required"
  ),
  account_name: Yup.string("Enter account name")
    .required("Account Name is required"),
});

export const profileChangePasswordSchema = Yup.object({
  oldPassword: Yup.string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  newPassword: Yup.string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const profileForgotPasswordSchema = Yup.object({
  old_password: Yup.string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  new_password: Yup.string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const profileForgotPinSchema = Yup.object({
  old_pin: Yup.string("Enter your old PIN")
    .min(4, "PIN should be of minimum 4 characters length")
    .max(4, "PIN should be of maximum 4 characters length")
    .required("Old PIN is required"),
  new_pin: Yup.string("Enter your new PIN")
    .min(4, "PIN should be of minimum 4 characters length")
    .max(4, "PIN should be of maximum 4 characters length")
    .required("New PIN is required")
});

export const profileGuarantorsInformationSchema = Yup.object({
  first_name: Yup.string("Enter guarantor's first name")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("First Name is required"),
  last_name: Yup.string("Enter guarantor's last name (surname)")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Last Name (surname) is required"),
  middle_name: Yup.string("Enter guarantor's middle name")
    .min(2, "Too short!")
    .max(50, "Too long!"),
  phone: Yup.string("Enter phone number, e.g 08012345678")
    .min(11, "Too short! ")
    .max(11, "Too long!")
    .required("Phone number is required"),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  home_address: Yup.string("Enter home address")
    .required("Home address is required")
});

export const profileProofOfAddressSchema = Yup.object({
  home_address: Yup.string("Address")
    .required("Address is required"),
  city: Yup.string("City")
    .required("City is required"),
  state: Yup.string("State")
    .required("State is required"),
});

export const profilePersonalInfoSchema = Yup.object({
  first_name: Yup.string("Enter saver's first name")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("First Name is required"),
  middle_name: Yup.string("Enter saver's middle name")
    .min(2, "Too short!")
    .max(50, "Too long!"),
  last_name: Yup.string("Enter saver's last name (surname)")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Last Name (surname) is required"),
  phone: Yup.string("Enter phone number, e.g 08012345678")
    .min(11, "Too short! ")
    .max(11, "Too long!")
    .required("Phone number is required"),
  email: Yup.string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  // home_address: Yup.string("Enter home address").required(
  //   "Home address is required"
  // ),
});

export const depositSavingsSchema = Yup.object({

  amount: Yup.string("Enter amount").required("Amount is required"),
  description: Yup.string("Description").required(
    "Description is required"
  ),
});