import {useCallback, useEffect, useState} from "react";
// import CustomIcon from "../../../widgets/icon/CustomIcon";
import CustomModal from "../../../widgets/modals/CustomModal";
// import styles from "./withdraw-from-wallet.module.css";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";
import CustomBackButton from "../../../widgets/form/custom-button/CustomBackButton";
import CustomForm from "../../../widgets/form/CustomForm";
import {useFormik} from "formik";
import CustomButton from "../../../widgets/form/custom-button/CustomButton";
// import CustomList from "../../../widgets/list/CustomList";
import {withdrawFromWalletfields} from "../../../utils/formFields";
import {withdrawFromWalletSchema as validationSchema} from "../../../utils/yupSchemas";
import baseUrl from "../../../utils/baseUrl";
import axios from "axios";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import SearchDropdown from "../../../widgets/search-dropdown/SearchDropdown";
import {getAccountName} from "../../../store/slices/agent/agentSlice";
import {useSelector} from "react-redux";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import {newPayout, payoutSelector, setPayoutIsLoading, setPayoutMessage} from "../../../store/slices/savers/payoutSlice";
import {useDispatch} from "react-redux";
import useVerify from "../../../hooks/useVerify";

export default function WithdrawFromWallet({onClose}) {
  const {userData} = useSelector(loginSelector).login;

  const [bankList, setBankList] = useState([]);
  const [isUserTypingBank, setIsUserTypingBank] = useState(false);
  const [bank, setBank] = useState(null);
  const [bankName, setBankName] = useState("");
  const [searchBankResults, setSearchBankResults] = useState([]);
  const [bankError, setBankError] = useState(false)

  // for resolving account name
  const [loadingName, setLoadingName] = useState(false);
  const [loadingNameMessage, setLoadingNameMessage] = useState(null)
  const [accountName, setAccountName] = useState("")

  const [option, setOption] = useState(null);
  const [open, setOpen] = useState(false);

  const {payoutIsLoading, payoutMessage} = useSelector(payoutSelector).payout;
  const verify = useVerify()

  const formik = useFormik({
    initialValues: {
      accountNumber: "",
      amount: "",
      description: "Withdrawal from AjoBank Wallet",
    },
    validationSchema,
    onSubmit: (values, {resetForm}) => handleSubmit(values, resetForm),
  });
  const dispatch = useDispatch()
  function handleSubmit(values, resetForm) {
    if (bank && !loadingName && accountName) {
      let details = {
        // wfw : Withdrawal from wallet
        purchaseReference: `wfw-${Math.floor(Math.random() * 1000000000)}`,
        amount: values.amount,
        bankcode: bank?.bankCode1,
        accountNumber: values.accountNumber,
        currency: "NGN",
        senderName: `${userData.first_name} ${userData.last_name}`,
        narration: values.description,
        userId: userData.accounts[0].accountId,
        agentId: userData.id,
        transaction_type: "Withdrawal from wallet",
      }

      dispatch(newPayout(details, () => {
        verify();
        setOpen(true);
        resetForm();
        // onClose()
      }))
    } else if (!bank) {
      setBankError(true);
    }
  }

  const resolveAccountName = useCallback(
    async (value) => {
      if (Number(value.length) === 10) {
        const accName = await getAccountName({
          account_number: value,
          bank_code: bank?.bankCode1
        }, {
          setLoadingName,
          setLoadingNameMessage,
        })
        if (accName) {
          setAccountName(accName);
        } else {
          setAccountName("");
        }
      }
    }, [bank?.bankCode1]
  )

  function setCurrentBank(bank) {
    setBank(bank);
    setIsUserTypingBank(false);
    setBankError(false);
    setBankName(bank.bankName)
  }

  function handleBankChange(e) {
    setBank(null);
    setBankName(e.target.value);
    setBankError(false);
    findBankRelatedText(e.target.value);
    setIsUserTypingBank(true);
  }

  function findBankRelatedText(searchTerm) {
    setSearchBankResults(
      bankList.filter(({bankName, bankCode1, bankCode2}) =>
        // bankCode1.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        // bankCode2.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
        bankName.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
      )
    );
  }

  // function handleSelectBank() {
  //   alert("bank selected");
  // }

  // function handleAddNewBank() {
  //   alert("Add New Bank");
  // }

  useEffect(() => {
    resolveAccountName(formik.values.accountNumber);
  }, [bank, formik.values.accountNumber, resolveAccountName])

  const fetchBankList = useCallback(
    async () => {
      const result = await axios.get(`${baseUrl}transactions/get_bank_list `);
      const data = result.data;
      if (!data.error || data.status) {
        setBankList(data.data.data);
      }
    },
    [],
  )

  useEffect(() => {
    fetchBankList();
  }, [fetchBankList])


  useEffect(() => {
    dispatch(setPayoutMessage(null));
    dispatch(setPayoutIsLoading(false));
  }, [dispatch])

  return (
    <>
      {option && <CustomBackButton onBack={() => setOption(null)} />}

      <form onSubmit={formik.handleSubmit}>
        {payoutMessage &&
          <div className={`${payoutMessage?.error ? 'text-danger' : 'text-success'}`}>{payoutMessage.message}</div>}
        <div className="mb-3">
          <CustomLabel htmlFor="bankId" content="Bank Name" />
          <CustomInput
            type="text"
            aria-label="bankId"
            aria-describedby="bankId"
            name="bankId"
            onChange={handleBankChange}
            value={bankName}
          />
          <SearchDropdown
            isActive={Boolean(bankList[0] && bankName && isUserTypingBank)}
            options={searchBankResults}
            option="name"
            onClick={setCurrentBank}
          />
          {bankError && <div className="form-text text-danger">Select a Bank</div>}
        </div>
        <div className="mb-3">
          <CustomLabel htmlFor="accountNumber" content="Account Number" />
          <CustomInput
            type="text"
            placeholder="0000000000"
            aria-label="accountNumber"
            aria-describedby="accountNumber"
            name="accountNumber"
            onChange={formik.handleChange}
            value={formik.values.accountNumber}
          />
          {formik.touched.accountNumber && <div className="form-text text-danger">{formik.errors.accountNumber}</div>}
        </div>
        <div className="mb-3">
          <CustomLabel htmlFor="AccountName" content="Account Name" />
          <CustomInput
            disabled
            type="text"
            placeholder="..."
            aria-label="accountName"
            aria-describedby="accountName"
            name="accountName"
            value={loadingName ? 'Checking...' : accountName}
          />
          {/* <input type="text" oni /> */}
          {loadingNameMessage &&
            <div className={`${loadingNameMessage?.error ? 'text-danger form-text' : 'text-success'}`}>{loadingNameMessage.message}</div>}
        </div>
        {withdrawFromWalletfields.map((field, index) => (
          <CustomForm item={field} formik={formik} key={index} />
        ))}

        {/* <div className="d-flex align-items-center mt-5 mb-2">
          <div className="fw-bold me-2">Bank Details</div>
          <CustomIcon
            name="plusCircle"
            isClickable
            onClick={handleAddNewBank}
          />
        </div>
        <CustomList
          icon="bankCircle"
          title="My Bank Account"
          subtitle="Firstbank; Account No- 002314592"
          isClickable
          onClick={handleSelectBank}
        /> */}
        <br />
        <CustomButton
          type="submit"
          color="primary"
          text="Withdraw from Wallet"
          loading={payoutIsLoading}
        />
      </form>

      <CustomModal open={open} onClose={() => setOpen(false)}>
        <SuccessFeedback
          title="Withdrawal Successfully"
          content="You have successfully withdrawn from your wallet."
          onClick={() => setOpen(false)}
          buttonText="Ok"
        />
      </CustomModal>
    </>
  );
}
