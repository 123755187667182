import React from 'react'
import logo from "../../assets/images/logo.svg"

export default function FetchError({ message, withLogo, onClick, btnText, minHeight }) {
  return (
    <div className="d-flex" style={{ minHeight }}>
      <div className="m-auto d-flex flex-column text-center" style={{ maxWidth: '18rem' }}>
        {withLogo && <img src={logo} alt="logo" width={150} className="mx-auto mb-3" />}
        {message}
        <button onClick={onClick} className="btn btn-sm mt-2 w-50 mx-auto btn-secondary">
          {btnText}
        </button>
      </div>
    </div>
  )
}
