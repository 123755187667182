import styles from "./summary.module.css";
import { formatNumberInThousand } from "../../../utils/functions";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector } from "../../../store/slices/auth/loginSlice";
import { useEffect, useState } from "react";
import { getWalletDetails } from "../../../store/slices/agent/agentSlice";

export default function Summary() {
  const dispatch = useDispatch();
  const { userData } = useSelector(loginSelector).login;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    dispatch(
      getWalletDetails(
        {
          accountId: userData.accounts[0].accountId,
          agentId: userData.id,
        },
        { setLoading, setMessage }
      )
    );
  }, [dispatch, userData])


  return (
    <div className="bgLightBlue cardShadow p-3 h-100">
      <div className="fw-bold">Summary</div>
      <div className="d-flex">
        <div className="w-100 mt-5 pt-4">
          {/* <div>
                        <div className="extraSmallText textPrimary">Average Monthly Value</div>
                        <div className="h4 fw-bold">{formatNumberInThousand(summaryBalance.toFixed(2))}</div>
                    </div> */}
          <div className="mt-5 pt-5 small">
            <div className="mb-2 flex">
              {/* <hr className={styles.summaryCircleLargeBull} /> */}
              &bull;
              Deposits: <span className="fw-bold">{(loading && !message?.error) ? '...' : formatNumberInThousand(message?.data?.total_credit.toFixed(2))}</span>
            </div>
            <div>
              &bull;
              Withdrawals: <span className="fw-bold">{(loading && !message?.error) ? '...' : formatNumberInThousand(message?.data?.total_debit.toFixed(2))}</span>
            </div>
          </div>
        </div>
        {/* <div className="w-100"></div> */}
        <div className="d-flex">
          <div>
            <div className={[styles.summaryCircleLarge].join(' ')}>
              {
                (loading) ? '...' : (message?.data?.total_credit && message?.data?.total_credit) ?
                  ((message?.data?.total_credit / (message?.data?.total_credit + (-1 * message?.data?.total_debit))) * 100).toFixed(1) + '%' : ''
              }
            </div>
            <div className={[styles.summaryCircleSmall].join(' ')}>
              {
                (loading) ? '...' : (message?.data?.total_credit && message?.data?.total_credit) ?
                  (((-1 * message?.data?.total_debit) / (message?.data?.total_credit + (-1 * message?.data?.total_debit))) * 100).toFixed(1) + '%' : ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

