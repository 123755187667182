import { Toast, ToastContainer } from "react-bootstrap";
import CustomCloseButtonIcon from "../form/custom-button/CustomCloseButtonIcon";
import PropTypes from "prop-types";
import CustomIcon from "../icon/CustomIcon";

const CustomToast = ({
  show,
  onClose,
  position,
  icon,
  title,
  subtitle,
  content,
}) => {
  return (
    <ToastContainer className="p-3" position={position || "top-end"}>
      <Toast onClose={() => onClose(false)} show={show} delay={3000} autohide>
        <Toast.Header closeButton={false}>
          {icon && <CustomIcon name={icon} />}
          {title && <strong className="me-auto">{title}</strong>}
          {subtitle && <small>{subtitle}</small>}
          <CustomCloseButtonIcon onClose={onClose} />
        </Toast.Header>
        {content && <Toast.Body>{content}</Toast.Body>}
      </Toast>
    </ToastContainer>
  );
};

export default CustomToast;

CustomToast.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  position: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
};
