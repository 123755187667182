
import {useNavigate} from "react-router-dom";
import SuccessIcon from "../../assets/icons/deposit-savings/success-icon.png";


export default function PayoutSavingsSuccess({payoutValues}) {
    const navigate = useNavigate();

    function handleGoToDashboard() {
        navigate("/");
    }

    return (
        <div className="text-center">
            <img src={SuccessIcon} width="90" alt="success-icon" />
            <br /> <br />
            <div className="fw-bold h5 my-2">Successful</div>
            <div className="extraSmallText mb-4 text-center w-50 mx-auto">You have successfully paid out {payoutValues?.amount} to {payoutValues?.name}</div>
            <button onClick={handleGoToDashboard} className="btn customButton fw-bold customPrimaryButton">Go to Dashboard</button>
        </div>
    )
}