import PropTypes from 'prop-types';

const Hidden = ({onClick}) => {
	return (
		<svg className='cursor-pointer' onClick={onClick} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M21.4685 1.58568C21.792 1.32692 21.8444 0.854953 21.5857 0.531506C21.3269 0.20806 20.855 0.155619 20.5315 0.414376L17.2062 3.07464C15.529 1.56835 13.3897 0.250028 11 0.250028C8.85907 0.250028 6.92327 1.30902 5.35175 2.59574C3.77166 3.88949 2.47964 5.47868 1.61144 6.68804L1.53984 6.78762C1.01718 7.51385 0.58695 8.11166 0.586944 9C0.586937 9.88833 1.01709 10.4861 1.53964 11.2122L1.61121 11.3117C2.15751 12.0727 2.86909 12.9805 3.71226 13.8698L0.531506 16.4144C0.20806 16.6731 0.155619 17.1451 0.414376 17.4685C0.673133 17.792 1.1451 17.8444 1.46855 17.5857L21.4685 1.58568ZM11 1.75003C12.7777 1.75003 14.5024 2.70932 16.0173 4.02574L13.4006 6.11909C12.7506 5.57707 11.9134 5.25003 11 5.25003C8.92896 5.25003 7.25003 6.92896 7.25003 9.00003C7.25003 9.61541 7.39871 10.1971 7.66192 10.71L4.88803 12.9292C4.06702 12.0746 3.36802 11.1868 2.82974 10.4369C2.20595 9.56801 2.08694 9.35854 2.08694 9.00001C2.08695 8.64149 2.206 8.43195 2.82995 7.56281C3.65922 6.40768 4.86351 4.93417 6.30203 3.75634C7.74913 2.57149 9.35426 1.75003 11 1.75003ZM11 6.75003C11.4362 6.75003 11.8433 6.87383 12.1884 7.0889L8.87436 9.7401C8.79377 9.50853 8.75003 9.25964 8.75003 9.00003C8.75003 7.75739 9.75739 6.75003 11 6.75003Z" fill="#1A1111" />
			<path d="M19.3954 5.39079C19.1332 5.07006 18.6608 5.02255 18.34 5.28467C18.0193 5.54679 17.9718 6.01928 18.2339 6.34001C18.5844 6.76888 18.8981 7.18396 19.17 7.56273C19.7941 8.43194 19.9131 8.6415 19.9131 9.00002C19.9131 9.35855 19.7941 9.56809 19.1701 10.4372C18.3408 11.5924 17.1365 13.0659 15.698 14.2437C14.2509 15.4286 12.6458 16.25 11 16.25C9.59522 16.25 8.21821 15.6521 6.93756 14.7322C6.60113 14.4906 6.13252 14.5674 5.89088 14.9038C5.64923 15.2403 5.72607 15.7089 6.0625 15.9505C7.48703 16.9737 9.16761 17.75 11 17.75C13.141 17.75 15.0768 16.691 16.6483 15.4043C18.2284 14.1106 19.5204 12.5214 20.3886 11.312L20.4602 11.2124C20.9829 10.4862 21.4131 9.88837 21.4131 9.00002C21.4131 8.11167 20.9829 7.51382 20.4602 6.78754L20.3886 6.68796C20.1009 6.28725 19.7682 5.84696 19.3954 5.39079Z" fill="#1A1111" />
			<path d="M14.75 9.00003C14.75 8.58581 14.4142 8.25003 14 8.25003C13.5858 8.25003 13.25 8.58581 13.25 9.00003C13.25 10.2427 12.2427 11.25 11 11.25C10.5858 11.25 10.25 11.5858 10.25 12C10.25 12.4142 10.5858 12.75 11 12.75C13.0711 12.75 14.75 11.0711 14.75 9.00003Z" fill="#1A1111" />
		</svg>
	)
}

Hidden.propTypes = {
	onClick: PropTypes.func
}

export default Hidden