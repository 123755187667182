import {useState} from "react";
import CustomIcon from "../../../widgets/icon/CustomIcon";
import CustomModal from "../../../widgets/modals/CustomModal";
import styles from "./fund-wallet.module.css";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";
import BankTransfer from "./payment-options/bank-transfer/BankTransfer";
import CardPayment from "./payment-options/card-payment/CardPayment";
import UssdPayment from "./payment-options/ussd-payment/UssdPayment";

const paymentOptions = [
  {
    id: 1,
    title: "Bank Transfer",
    subtitle: "From bank app or internet transfer",
    icon: "bankTransfer",
  },
  {
    id: 2,
    title: "Card",
    subtitle: "Fund Wallet with a debit Card",
    icon: "card",
  },
  {id: 3, title: "USSD", subtitle: "Fund Wallet via USSD", icon: "card"},
];

export default function FundWallet() {
  const [option, setOption] = useState(1);
  const [open, setOpen] = useState(false);

  function handleSetOption(val) {
    setOption(val);
  }

  function handleCloseModal() {
    setOption(null);
    setOpen(false);
  }

  return (
    <>
      {/* {option && <CustomBackButton onBack={() => setOption(null)} />} */}
      {!option && (
        <>
          <div>Fund your Ajobank Wallet with any option</div>
          <div className="bgLightBlue customBorderRadius py-5 px-3 mt-4">
            {paymentOptions.map((item, index) => (
              <OptionCard key={index} data={item} setOption={handleSetOption} />
            ))}
          </div>
        </>
      )}

      {option === 1 && <BankTransfer callSuccess={() => setOpen(true)} />}
      {option === 2 && <CardPayment callSuccess={() => setOpen(true)} />}
      {option === 3 && <UssdPayment callSuccess={() => setOpen(true)} />}

      <CustomModal open={open} onClose={handleCloseModal}>
        <SuccessFeedback
          title="Wallet Funded Successfully"
          content="You have successfully funded your wallet."
          onClick={handleCloseModal}
          buttonText="Ok"
        />
      </CustomModal>
    </>
  );
}

function OptionCard({data, setOption}) {
  const {id, title, subtitle, icon} = data;
  return (
    <div
      className={styles.cardContainer}
      role="button"
      onClick={() => setOption(id)}
    >
      <div className="me-3">
        <CustomIcon name={icon} />
      </div>
      <div>
        <div className="fw-bold ">{title}</div>
        <div className="extraSmallText">{subtitle}</div>
      </div>
    </div>
  );
}
