import {useSelector} from "react-redux";
import {signupSelector} from "../../../store/slices/auth/signupSlice";
import NProgressBar from "../../../widgets/progress-bar/NProgressBar";


export default function SignUpStepTitle({title, subtitle}) {
  const {step, message} = useSelector(signupSelector).signup;

  return (
    <>
      <h3 className='text-light-blue text-2xl md:text-[2rem] font-bold'>{title}</h3>
      <p className='text-sm '>{subtitle} </p>
      <div className="pt-2">
        <NProgressBar value={step} totalValue={5} />
      </div>
      <p className="text-xs mt-2 text-red-600">{message}</p>
    </>
  );
}
