import React, { useEffect, useState } from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import Filter from "../../widgets/filter/Filter";
import Downloads from "../../widgets/downloads/Downloads";
import AllSavers from "../../components/savers/Savers";
import { useSelector } from "react-redux";
import { saversSelector } from "../../store/slices/savers/saversSlice";

const types = ["Daily", "Weekly", "Monthly"];

export default function Savers() {
  const { savers, saversIsLoading, error } = useSelector(saversSelector).savers;
  const [filteredSaver, setFilteredSaver] = useState(savers);
  const [searchedSaver, setSearchedSaver] = useState(filteredSaver);
  const [searchValue, setsearchValue] = useState("");
  const setFilter = (values) => {
    setFilteredSaver(
      savers.filter(
        (each, i) =>
          (Number(values.entries) === 0 || i + 1 <= Number(values.entries)) &&
          each.saving_type
            .toLowerCase()
            .includes(values.saving_type.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setSearchedSaver(filteredSaver);
    searchSaver(searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSaver]);

  const searchSaver = (searchText) => {
    setsearchValue(searchText);
    setSearchedSaver(
      filteredSaver.filter(
        (each, i) =>
          each.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
          each.last_name.toLowerCase().includes(searchText.toLowerCase())
      )
    );
  };

  return (
    <>
      <PageHeader
        showNewSaverButton
        title="Savers"
        withSearch
        search={searchSaver}
      />
      <div className="container pt-4">
        <section className="row">
          <div className="col-sm-12 p-2">
            {savers[0] && !saversIsLoading && !error && (
              <>
                <div className="mb-5">
                  <Filter setFilter={setFilter} types={types} />
                </div>
                <Downloads />
              </>
            )}
            <AllSavers filteredSaver={searchedSaver} />
          </div>
        </section>
      </div>
    </>
  );
}
