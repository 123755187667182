import {useFormik} from "formik";
import * as Yup from "yup";
import {useState} from "react";
import {confirmOtpAndResetPassword} from "../../../store/slices/auth/forgetPasswordSlice";
import ACInput from "../../../widgets/form/ACInput";
import ReactCodeInput from "react-code-input";
import ACBtn from "../../../widgets/form/ACBtn";

const validationSchema = Yup.object({
  password: Yup.string("Enter your Password")
    .min(8, "Too short!")
    .required("New password is required"),
  confirmPassword: Yup.string("Enter your Password")
    .required("Confirm password is required").oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default function SetNewPassword({changeStep, agentId}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const [pin, setPin] = useState("");

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = async (e) => {
    if (pin.length < 6) return
    setLoading(true);
    setMessage(null)
    await confirmOtpAndResetPassword({
      new_password: formik.values.password,
      otp: pin,
      userId: agentId
    }, {setMessage, changeStep});
    setLoading(false);
  };

  const props = {
    onChange: setPin,
    type: 'password',
    fields: 6,
    className: `[display:flex_!important] justify-between auth sm gap-2`,
    disabled: loading,
    inputMode: 'numeric',
    // isValid: states.validCode,
  }


  return (
    <>
      <form onSubmit={formik.handleSubmit} className='cfade transition-all duration-500 max-w-md py-20 mx-[auto] md:mx-0 px-3 lg:pl-8 flex min-h-screen flex-col'>
        <h3 className='text-light-blue text-2xl md:text-[2rem] font-bold'>Forgot Password</h3>
        <p className='text-sm'>An OTP has been sent to your phone number, please enter the OTP and your new password. </p>
        {message &&
          <div className={`text-xs ${message?.error ? 'text-danger' : 'text-success'}`}>{message.text}</div>}

        <div className='flex flex-col gap-3 pt-7'>
          <ReactCodeInput {...props} />
          <ACInput inputProps={{placeholder: '*********', name: 'password'}} formik={formik} label={'Password'} />
          <ACInput inputProps={{placeholder: '*********', name: 'confirmPassword'}} formik={formik} label={'Confirm Password'} />

          <div className='flex justify-between'>
            <button type='button' onClick={() => (changeStep(1))} className='gr-btn' disabled={loading}>Back</button>
            <ACBtn type='submit' text={'Continue'} loading={loading} disabled={(pin.length !== 4) || loading} className={'max-w-[8rem]'} />
          </div>
        </div>
      </form>
    </>
  );
}
