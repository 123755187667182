import axios from "axios";
import {createSlice} from "@reduxjs/toolkit";
import baseUrl from "../../../utils/baseUrl";

const initialState = {
	payoutIsLoading: false,
	error: null,
	payoutMessage: null,
	success: false,
	saver: {},
};

export const payoutSlice = createSlice({
	name: "payout",
	initialState,
	reducers: {
		// set saver about to pay out to
		setSaver: (state, {payload}) => {
			state.saver = payload;
		},
		setPayoutMessage: (state, {payload}) => {
			// parse payload
			state.payoutMessage = payload;
			state.newPayoutLoading = false;
		},
		setPayout: (state, {payload}) => {
			state.payout = payload;
			state.payoutIsLoading = false;
			// setting payout successfully means error should be null
			state.error = null;
		},
		setPayoutIsLoading: (state, {payload}) => {
			state.payoutIsLoading = payload;
		},
		setError: (state, {payload}) => {
			state.payoutIsLoading = false;
			state.error = payload;
		},
		setNewPayoutDetails: (state, {payload}) => {
			state.newPayoutDetails = {...state.newPayoutDetails, ...payload}
		},
		setNewPayoutStep: (state, {payload}) => {
			// every change in step will set message to null
			state.newPayoutStep = payload;
			state.payoutMessage = null;
		},
		resetPayout: (state) => {
			state.payoutIsLoading = false;
			state.error = null;
			state.payoutMessage = null;
			state.success = false;
			state.saver = {};
		}
	},
});

//actions
export const {
	setPayout,
	setSaver,
	resetPayout,
	setPayoutMessage,
	setPayoutIsLoading,
	setError,
	setNewPayoutDetails,
	setNewPayoutStep
} = payoutSlice.actions;

//selectors
export const payoutSelector = (state) => state;

//reducer
export default payoutSlice.reducer;

//Asynchronous thunk action

export const newPayout = (details, action) => {
	return async (dispatch) => {
		dispatch(setPayoutIsLoading(true));
		dispatch(setPayoutMessage(null));
		try {
			const result = await axios.post(`${baseUrl}transactions/withdraw_to_other_banks`, details);
			const data = result?.data;
			if (!data?.error || data?.status) {
				action();
				dispatch(resetPayout());
			} else {
				dispatch(setPayoutMessage(data));
			}
		} catch (error) {
			console.log({error});
			if (error.response) {
				let err = error.response?.data
				dispatch(setPayoutIsLoading(false));
				if (err) { // to avoid saving unnecessary data to app state.
					dispatch(setPayoutMessage({
						error: true,
						message: err?.error?.data?.forbidden ? err.error.data.forbidden : err?.error?.message
					}));
				}
			} else {
				dispatch(setPayoutMessage({data: {message: "Insuficient Funds", error: true}}));
			}
		}
	};
};

