import { useState } from "react";
import CustomModal from "../../../widgets/modals/CustomModal";
import WithdrawFromSavers from "./WithdrawFromSavers";

export default function SaverPayout({ open, onClose, transaction }) {
  const [step, setStep] = useState(1)
  return (
    <CustomModal
      open={open}
      position="right"
      onClose={onClose}
      dialogClassName="w-md-50"
      isFullscreen={true}
      title="Withdraw From Wallet"
    >
      <WithdrawFromSavers onClose={onClose} details={transaction} />
    </CustomModal>
  );
}
