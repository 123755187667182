import downloadIcon from "../../assets/icons/profile/download.svg";

export default function DownloadAllDocuments() {
    return (
        <div className="bgLightBlue">
            <div className="container d-flex py-3">
                <div className="me-2">
                    <img src={downloadIcon} alt="download" />
                </div>
                <div className="small textPrimary">Download All Documents</div>
            </div>
        </div>
    )
}