import PropTypes from "prop-types";
import CustomIcon from "../../icon/CustomIcon";

export default function CustomCloseButtonIcon({ onClose, color }) {
  return (
    <button className="btn" onClick={onClose}>
      <CustomIcon name="closeCircle" size={30} color={color || "black"} />
    </button>
  );
}

CustomCloseButtonIcon.propTypes = {
  onClose: PropTypes.func.isRequired,
  color: PropTypes.string,
};