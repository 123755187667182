import { setUserData } from "../store/slices/auth/signupSlice";
import { getSavers } from "../store/slices/savers/saversSlice";
import baseUrl from "./baseUrl";

// interceptor and verification goes here
export const interceptRequest = (axios, { token, setActiveComponent, navigation }) => {
  axios.interceptors.request.use(config => {
    if (config.override) {
      return config
    }
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    } else {
      setActiveComponent(navigation);
    }
    return config
  }, error => {
    Promise.reject(error)
  });
}

export const interceptResponse = (axios, { setActiveComponent, navigation }) => {
  axios.interceptors.response.use(response => {
    return response
  }, error => {
    if (
      error?.response?.data?.error?.data?.statusCode === 401
    ) {
      setActiveComponent(navigation);
      // return Promise.reject(error)
    }
    // return Promise.reject(error)
  });
}

const verify = async (axios, {
  token,
  dispatch,
  // setSuccess,
  setActiveComponent,
  navigation,
  networkError,
}) => {
  let success = false;
  await axios.post(`${baseUrl}auth/verify_token`, { token }).then(e => {
    let result = e?.data;
    if (result?.data) {
      success = true;
      // setSuccess(true);
      dispatch(setUserData(result.data))
      //prefetch datas
      dispatch(getSavers({ agentId: result.data.id }))
    } else {
      setActiveComponent(navigation);
    }
  }).catch(err => {
    if (err?.response?.data?.error || err.response?.error) {
      setActiveComponent(navigation);
    } else {
      setActiveComponent(networkError);
    }
  });
  return success;
}

export default verify;