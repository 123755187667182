export default function ProgressBar({value, totalValue}) {
  return (
    <div className="progress" style={{height: "2px"}}>
      <div
        className="progress-bar bg-light-blue"
        role="progressbar"
        style={{width: `${(value / totalValue) * 100}%`}}
        aria-valuenow={(value / totalValue) * 100}
        aria-valuemin={value}
        aria-valuemax="100"
      ></div>
    </div>
  );
}
