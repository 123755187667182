import { Navigate, Outlet } from "react-router-dom";
import LayoutProtected from "../layouts/LayoutProtected";
import axios from 'axios'
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loginSelector } from "../store/slices/auth/loginSlice";
import Loader from "../widgets/others/Loader";
import FetchError from "../widgets/others/FetchError";
import verify, { interceptRequest, interceptResponse } from "../utils/verify";

const ProtectedRoute = () => {
  const { token } = useSelector(loginSelector).login;
  //for loading state
  const loader = <Loader height={"100vh"} withLogo />

  // to navigate to signin when token is invalid
  const navigation = useMemo(() => <Navigate to="/auth" replace />, []);

  // for other error
  const networkError = useMemo(() => <FetchError
    withLogo
    minHeight={'100vh'}
    message={"An error occured, please check your internet connection."}
    onClick={() => window.location.reload()}
    btnText={"REFRESH"}
  />, []);

  // loading state is the default active component
  const [activeComponent, setActiveComponent] = useState(loader)
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Add a request interceptor to always attach userData?.user_token to header 
    interceptRequest(axios, {
      token,
      setActiveComponent,
      navigation
    });

    // Add a request response to always check if user is still authorized 
    interceptResponse(axios, {
      setActiveComponent,
      navigation
    });

    // to verify if the token is valid before access to protected routes
    const ver = async () => {
      if (await verify(axios, {
        token,
        dispatch,
        setActiveComponent,
        navigation,
        networkError
      })) {
        setSuccess(true)
      }
    }
    ver();

  }, [navigation, networkError, token, dispatch])

  return (
    success ?
      <LayoutProtected>
        <Outlet />
      </LayoutProtected>
      : activeComponent
  );
};

export default ProtectedRoute;
