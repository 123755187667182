import {useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import avatar from "../../../assets/icons/navbar/avatar.png";
import useLogout from "../../../hooks/useLogout";
import {loginSelector} from "../../../store/slices/auth/loginSlice";
import {copyTextToClipboard} from "../../../utils/functions";
import Avatar from "../../avatar/Avatar";
import CustomIcon from "../../icon/CustomIcon";
import CustomToast from "../../toast/CustomToast";


const dropdownOptions = [
  {icon: "userMark", name: "View Profile", path: "/profile"},
];

export default function ProfileDropdown() {
  const handleLogOut = useLogout();
  const {userData: {first_name, last_name, account_number}} = useSelector(loginSelector).login;
  const [showToast, setShowToast] = useState(false);

  function handleCopy() {
    copyTextToClipboard(account_number);
    setShowToast(true);
  }

  return (
    <>
      <div className="dropdown">
        <button
          className="btn text-white"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="d-flex">
            <span className="mx-2">{first_name} {last_name}</span>
            <CustomIcon name="dropdown" size={15} />
          </span>
        </button>
        <ul className="dropdown-menu px-3" aria-labelledby="profile-dropdown">
          <div className="text-center pt-2">
            <div className="mb-2">
              <Avatar src={avatar} alt="user-avatar" />
            </div>
            <div className="fw-bold">{first_name} {last_name}</div>
            <div className="textPrimary fwNormal my-2">{account_number}</div>
            {
              account_number && <button
                type="button"
                className="btn btn-sm"
                data-bs-container="body"
                data-bs-toggle="popover"
                data-bs-placement="bottom"
                data-bs-content="Bottom popover"
                onClick={handleCopy}
              >

                <span className="d-flex">
                  Copy <CustomIcon name="copy" size={15} />
                </span>
              </button>
            }
          </div>
          <hr className="dropdown-divider" />
          {dropdownOptions.map((item, index) => (
            <li className="d-flex" key={index}>
              <span className="me-1">
                <CustomIcon name={item.icon} size={15} />
              </span>
              <Link to={item.path} className="dropdown-item">
                {item.name}
              </Link>
            </li>
          ))}
          <li className="d-flex">
            <span className="me-1">
              <CustomIcon name="exit" size={15} />
            </span>
            <span
              className="dropdown-item cursorPointer text-danger"
              onClick={handleLogOut}
            >
              Logout
            </span>
          </li>
        </ul>
      </div>
      <CustomToast
        show={Boolean(showToast)}
        title={"Copied to clipboard!"}
        onClose={() => setShowToast("")}
      />
    </>
  );
}
