import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import LayoutAuth from "../../../layouts/LayoutAuth";
import CustomModal from "../../../widgets/modals/CustomModal";
import SuccessFeedback from "../../../widgets/feedbacks/SuccessFeedback";
import AccountEmail from "../../../components/auth/forgot-password/AccountEmail";
import SetNewPassword from "../../../components/auth/forgot-password/SetNewPassword";

export default function ForgotPassword() {
  const [step, setStep] = useState(1);
  const [open, setOpen] = useState(false);
  const [agentId, setAgentId] = useState(null)
  const navigate = useNavigate();

  function handleChangeStep(val) {
    setStep(val);
  }

  function redirectToLogin() {
    navigate("/auth/login");
  }

  function handleOpenModal() {
    setOpen(true);
  }

  function handleCloseModal() {
    setOpen(false);
  }

  useEffect(() => {
    if (step === 3) handleOpenModal();
  }, [step]);


  return (
    <LayoutAuth>

      {step === 1 && <AccountEmail setAgentId={setAgentId} changeStep={handleChangeStep} />}
      {step === 2 && <SetNewPassword agentId={agentId} changeStep={handleChangeStep} />}

      <CustomModal open={open} onClose={handleCloseModal}>
        <SuccessFeedback
          title="Password Changed successfully"
          content="You can now log into your app and fund your Wallet"
          onClick={redirectToLogin}
          buttonText="Login Now"
        />
      </CustomModal>
    </LayoutAuth>
  );
}
