import {useState} from "react";
import {Link} from "react-router-dom";
import {dashboardQuickLinks} from "../../../utils/constants";
import FundWalletModal from "../../wallet/fund-wallet/FundWalletModal";
import styles from "./quick-links.module.css";

export default function QuickLinks() {
  const [openModal, setOpenModal] = useState(false);

  function handleFundWallet() {
    setOpenModal(true);
  }
  function handleCloseFundWallet() {
    setOpenModal(false);
  }

  return (
    <>
      <div className="p-4 customBorderRadius bgWhite h-100">
        <div className="fw-bold mb-3">Quick Links</div>
        <div
          className={[
            "d-flex flex-nowrap overflow-auto justify-content-between removeScrollbar position-relative",
            styles.linkBoxContainer,
          ].join(" ")}
        >
          {dashboardQuickLinks.map((item, index) => (
            <LinkBox box={item} key={index} fundWallet={handleFundWallet} />
          ))}
        </div>
      </div>
      <FundWalletModal open={openModal} onClose={handleCloseFundWallet} />
    </>
  );
}

function LinkBox({box, fundWallet}) {
  function checkFunction(type) {
    if (type === "fund-wallet") {
      fundWallet();
    }
  }

  if (box.isLink) {
    return (
      <Link to={box.path} className={styles.linkBox}>
        <img
          src={require(`../../../assets/icons/dashboard/quick-links/${box.icon}`)}
          width="25"
          height="25"
          alt={box.name}
        />
        <div className="mt-2">{box.name}</div>
      </Link>
    );
  } else {
    return (
      <div className={styles.linkBox} onClick={() => checkFunction(box.func)}>
        <img
          src={require(`../../../assets/icons/dashboard/quick-links/${box.icon}`)}
          width="25"
          height="25"
          alt={box.name}
        />
        <div className="mt-2">{box.name}</div>
      </div>
    );
  }
}
