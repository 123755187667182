import {useState} from "react";
import styles from "./filter.module.css";
import {nigerianBanksList} from "../../utils/constants";

const entries = [5, 10, 20, 30, 40, 50, 100];

export default function Filter({setFilter, isTransaction, showBank, types}) {
    const [values, setValues] = useState({
        bank_name: "",
        saving_type: "",
        start_date: "",
        end_date: "",
        // 0 means all
        entries: 0
    })

    function handleChange(e) {
        setValues({...values, [e.target.name]: e.target.value});
        setFilter({...values, [e.target.name]: e.target.value});
    }

    function handleSubmit() {
        // console.log(values);
    }

    return (
        <div className={["container-fluid p-3", styles.filter].join(' ')}>
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="col-sm-12 mb-2">Filter/Sort By:</div>
                    {showBank && <div className="col-sm-6 col-md-2 col-6 mb-2">
                        <select className="form-select customFormControl" name="bank_name" onChange={handleChange} value={values.bank_name} aria-label="BankName" aria-describedby="bank_name">
                            <option defaultValue>Bank</option>
                            {nigerianBanksList.map((item, index) => <option key={index} value={item.id}>{item.name}</option>)}
                        </select>
                    </div>}
                    {!isTransaction &&
                        <div className="col-sm-6 col-md-2 col-6 mb-2">
                            <select className="form-select customFormControl" name="saving_type" onChange={handleChange} value={values.type} aria-label="Type" aria-describedby="type">
                                <option value="">Types</option>
                                {types?.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                        </div>
                    }
                    {
                        isTransaction &&
                        <>
                            <div className="col-sm-6 col-md-2 col-6 mb-2">
                                <input type="date" name="start_date" className="form-control" value={values.start_date} onChange={handleChange} placeholder="Start Date" />
                            </div>
                            <div className="col-sm-6 col-md-2 col-6 mb-2">
                                <input type="date" name="end_date" className="form-control" value={values.end_date} onChange={handleChange} placeholder="End Date" />
                            </div>
                        </>
                    }
                    <div className="col-sm-6 col-md-4 col-7 mb-2">
                        <div className="d-flex  align-items-center">
                            <div>Show</div>
                            <select style={{maxWidth: '5rem'}} className="form-select customFormControl mx-2" name="entries" onChange={handleChange} value={values.entries} aria-label="Type" aria-describedby="type">
                                <option value={0}>All</option>
                                {entries.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>
                            <div>entries</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}