import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  resendOtp,
  setStep,
  signupSelector,
  verifyOTP,
} from "../../../store/slices/auth/signupSlice";
import SignUpStepTitle from "./SignUpStepTitle";
import ReactCodeInput from "react-code-input";
import ACBtn from "../../../widgets/form/ACBtn";

export default function SignUpVerifyOTP() {
  const [otp, setOtp] = useState("");
  const {loading, userData, message} = useSelector(signupSelector).signup;
  const [showResend, setShowResend] = useState(false);
  const [resending, setResending] = useState(false);
  const [otpSent, setOTPSent] = useState(false);
  const dispatch = useDispatch();
  function handleChange(otp) {
    setOtp(otp);
  }

  function handleSubmit(e) {
    e.preventDefault()
    if (otp.length < 5) return;
    dispatch(verifyOTP({phone: userData.phone, otp}));
  }

  useEffect(() => {
    let resendInterval = setInterval(() => {
      setShowResend(true);
      // console.log("Yoo");
      clearInterval(resendInterval);
    }, 20000);
  }, []);

  const resend = () => {
    setShowResend(false);
    dispatch(
      resendOtp(userData?.phone, {
        setShowResend,
        setResending,
        setOTPSent,
      })
    );
  };

  useEffect(() => {
    if (otpSent) {
      let resendInterval = setInterval(() => {
        setShowResend(true);
        setOTPSent(false);
        setResending(false);
        // console.log("Yoo");
        clearInterval(resendInterval);
      }, 5000);
    }
  }, [otpSent]);

  const props = {
    onChange: handleChange,
    type: 'password',
    fields: 6,
    className: `[display:flex_!important] justify-between auth sm gap-2`,
    disabled: loading,
    inputMode: 'numeric',
    // isValid: states.validCode,
  }

  return (
    <>
      <form onSubmit={handleSubmit} className='cfade max-w-md pt-20 pb-5 mx-[auto] md:mx-0 px-3 lg:pl-8 flex flex-col'>
        <SignUpStepTitle message={message} title="Enter OTP" subtitle="An OTP has been sent to the registered phone number with the BVN you supplied." />
        <div className='flex flex-col gap-1 pt-7'>
          <ReactCodeInput {...props} />
          <div className="flex justify-end text-sm">
            {showResend && <button type="button" className="hover:text-light-blue transition" onClick={resend}  >Resend Otp</button>}
            {resending && <span>Sending...</span>}
            {otpSent && <span> OTP Sent </span>}
          </div> <br />
          <div className='flex justify-between'>
            <button type='button' onClick={() => dispatch(setStep(2))} className='gr-btn' disabled={loading}>Back</button>
            <ACBtn type='submit' text={'Verify'} loading={loading} disabled={(otp.length !== 6) || loading} className={'max-w-[8rem]'} />
          </div>
          <div className='flex gap-2 text-center justify-center'>

          </div>
        </div>
        <div className='mt-auto flex justify-end'>
          {/* <span className='text-xs text-black-4 hover:text-black transition cursor-pointer'>Need Help?</span> */}
        </div>
      </form>
    </>
  );
}
