import React, {Fragment, useState} from 'react'
import PropsType from 'prop-types';
import NotHidden from '../../components/svgs/NotHidden';
import Hidden from '../../components/svgs/Hidden';
import {Listbox, Transition} from '@headlessui/react';
import countries from '../../utils/countries'
import {CheckIcon, ChevronDownIcon} from '@heroicons/react/20/solid'

const ACInput = ({inputProps, label, formik, subText, options, optionDisplayKey, optionValueKey, icon, pText, className, useIndexForOption}) => {
	const [currentType, setCurrentType] = useState(inputProps?.type);
	const [selected, setSelected] = useState(countries[161])
	const [sselected, setSselected] = useState({})
	const [prText, setPrText] = useState(((inputProps.name === 'phone') ? selected.dial_code : pText) ?? '')

	return (
		<div className={`h-16 ${className ?? ''}`}>
			<div className='flex gap-x-2'>
				{inputProps.name === 'phone' &&
					<Listbox disabled value={selected} onChange={(e) => {setSelected(e); setPrText(e.dial_code)}}>
						<div className="relative w-full max-w-[8rem]">
							<Listbox.Button className="relative h-14 w-full border-slate-400 focus:border-light-blue cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border-[.7px] focus:outline-none focus:text-black text-black-4 text-sm">
								<span className="flex items-center gap-2 truncate">
									<span className='text-2xl'>
										{selected.flag ? selected.flag : <img className='ml-[.1rem] w-5 object-cover' alt={selected.flag} src={selected.flag_link} />}
									</span>
									<span> {selected.code}</span>
								</span>
								<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
									<ChevronDownIcon className='text-gray-400 w-5' />
								</span>
							</Listbox.Button>
							<Transition
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="relative z-10 mt-1 max-h-64 w-full overflowed rounded-md py-1 shadow-lg bg-white border focus:outline-none text-sm">
									{countries.map((country, personIdx) => (
										<Listbox.Option key={personIdx} value={country} title={country.name}
											className={({active}) =>
												`relative transition-all bg-white cursor-default select-none py-2 pr-2 ${active ? 'bg-light-blue/20 text-light-blue' : 'text-gray-900'
												}`
											}
										>
											{({selected}) => (
												<span className="flex items-center gap-2 truncate">
													<span className="text-lg pl-3">
														{country.flag ? country.flag : <img className='ml-[.1rem] w-7 object-cover' alt={selected.flag} src={country.flag_link} />}
													</span>
													<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
														{country.name}
													</span>
												</span>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</Listbox>
				}
				<div className='border-[.7px] border-slate-400 rounded-lg h-14 w-full group focus-within:border-light-blue px-3 transition-all duration-300'>
					<div className='flex gap-2 items-center h-full'>
						{icon}
						<div className='w-full'>
							<label className={`${icon ? 'ml-1' : ''} text-xs text-black-4`}>{label}</label>
							{inputProps.type === 'select' ?
								<>
									<select onChange={formik.handleChange} value={formik.values[inputProps.name]} className='w-full outline-none text-black-4 focus:text-black placeholder:opacity-40' {...inputProps}>
										<option>-- --</option>
										{
											options.map((each, i) => (
												<option value={useIndexForOption ? i : optionValueKey ? each[optionValueKey] : each} key={each}>{optionDisplayKey ? each[optionDisplayKey] : each}</option>
											))
										}
									</select>
									{/* <Listbox value={sselected} onChange={(e) => {setSselected(e)}}>
										<div className="relative w-full max-w-[8rem]">
											<Listbox.Button className="relative h-14 w-full focus:border-light-blue cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus:text-black text-black-4 text-sm">
												<span className="flex items-center gap-2 truncate">
													<span> {optionDisplayKey ? sselected[optionDisplayKey] : sselected}</span>
												</span>
												<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
													<ChevronDownIcon className='text-gray-400 w-5' />
												</span>
											</Listbox.Button>
											<Transition
												as={Fragment}
												leave="transition ease-in duration-100"
												leaveFrom="opacity-100"
												leaveTo="opacity-0"
											>
												<Listbox.Options className="relative z-10 mt-1 max-h-64 w-full overflowed rounded-md py-1 shadow-lg bg-white border focus:outline-none text-sm">
													{options.map((opt, idx) => (
														<Listbox.Option key={idx} value={optionValueKey ? opt[optionValueKey] : opt}
															className={({active}) =>
																`relative transition-all bg-white cursor-default select-none py-2 pr-2 ${active ? 'bg-light-blue/20 text-light-blue' : 'text-gray-900'
																}`
															}
														>
															{({selected}) => (
																<span className="flex items-center gap-2 truncate">
																	<span className="text-lg pl-3">
																		{selected && <CheckIcon />}
																	</span>
																	<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
																		{optionDisplayKey ? opt[optionDisplayKey] : opt}
																	</span>
																</span>
															)}
														</Listbox.Option>
													))}
												</Listbox.Options>
											</Transition>
										</div>
									</Listbox> */}
								</>
								: <div className='flex flex-row-reverse'>
									<input onChange={(e) => {
										if (inputProps.name === 'phone' && e.target.value && e.target.value.slice(0, 1) !== '0') {
											e.target.value = '0' + e.target.value;
										}
										formik.handleChange(e)
									}} value={formik.values[inputProps.name]} className='w-full peer outline-none text-black-4 focus:text-black placeholder:opacity-40 bg-transparent' {...inputProps} type={currentType ?? 'text'} />
									{prText && <span className='text-black-4 mr-1 peer-focus:text-black'>{prText}</span>}
								</div>}
						</div>
						{inputProps?.type === 'password' &&
							<>
								{currentType === 'password' ? <Hidden onClick={() => setCurrentType('text')} /> : <NotHidden onClick={() => setCurrentType('password')} />}
							</>
						}
					</div>
				</div>
			</div>
			<span className='text-[.65rem] pl-1 block transition-all duration-300'>
				{subText || <span className='text-red-600'>{formik.touched[inputProps.name] && formik.errors[inputProps.name]}</span>}
				{/* {JSON.stringify(formik)} */}
			</span>
		</div>
	)
}

ACInput.propTypes = {
	formik: PropsType.any,
	options: PropsType.array,
	type: PropsType.string,
	label: PropsType.string,
	name: PropsType.string,
	className: PropsType.string,
	disabled: PropsType.bool,
	disableUpdate: PropsType.bool,
	icon: PropsType.any,
	inputProps: PropsType.any,
	initialValues: PropsType.any,
	subText: PropsType.any,
	pText: PropsType.string,
	optionDisplayKey: PropsType.string,
	optionValueKey: PropsType.string
}



export default ACInput