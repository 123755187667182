import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";

const validationSchema = Yup.object({
  first_name: Yup.string("Enter saver's first name")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("First Name is required"),
  middle_name: Yup.string("Enter saver's middle name")
    .min(2, "Too short!")
    .max(50, "Too long!"),
  last_name: Yup.string("Enter saver's last name (surname)")
    .min(2, "Too short!")
    .max(50, "Too long!")
    .required("Last Name (surname) is required"),
  phone: Yup.string("Enter phone number, e.g 08012345678")
    .min(11, "Too short! ")
    .max(11, "Too long!")
    .required("Phone number is required"),
  email: Yup.string("Enter email, e.g example@gmail.com")
    .email("Email is invalid")
    .required("Email is required"),
  collection_amount: Yup.string("Enter collection_amount").required("Amount is required"),
  collection_date: Yup.string("Enter Collection Date")
    .min(10, "Too short!")
    .max(11, "Too long!")
    .required("Collection date is required"),
});

export default function AddPersonalDetails({changeStep}) {
  const {newSaverDetails} = useSelector(saversSelector).savers;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      first_name: newSaverDetails?.first_name ?? "",
      middle_name: newSaverDetails?.middle_name ?? "",
      last_name: newSaverDetails?.last_name ?? "",
      phone: newSaverDetails?.phone ?? "",
      collection_amount: newSaverDetails?.collection_amount ?? '',
      email: newSaverDetails?.email ?? '',
      collection_date: newSaverDetails?.collection_date ?? '',
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  function handleSubmit(values) {
    dispatch(setNewSaverDetails(values))
    dispatch(setNewSaverStep(2));
  }

  function handleGoBack() {
    navigate(-1);
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-3">
        <CustomLabel htmlFor="first_name" content="First Name" />
        <CustomInput
          type="text"
          placeholder="Enter Saver’s First Name"
          aria-label="First_name"
          aria-describedby="first_name"
          name="first_name"
          onChange={formik.handleChange}
          value={formik.values.first_name}
        />
        {formik.touched.first_name && (
          <div className="form-text text-danger">{formik.errors.first_name}</div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="middle_name" content="Middle Name" />
        <CustomInput
          type="text"
          placeholder="Enter Saver’s Middle Name"
          aria-label="Middle_name"
          aria-describedby="middle_name"
          name="middle_name"
          onChange={formik.handleChange}
          value={formik.values.middle_name}
        />
        {formik.touched.middle_name && (
          <div className="form-text text-danger">
            {formik.errors.middle_name}
          </div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="last_name" content="Last Name" />
        <CustomInput
          type="text"
          placeholder="Enter Saver’s Last Name"
          aria-label="Last_name"
          aria-describedby="last_name"
          name="last_name"
          onChange={formik.handleChange}
          value={formik.values.last_name}
        />
        {formik.touched.last_name && (
          <div className="form-text text-danger">{formik.errors.last_name}</div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="phone" content="Phone Number" />
        <CustomInput
          type="tel"
          aria-label="Phone"
          aria-describedby="phone"
          withPrepend
          prependValue="+234"
          minLength="11"
          maxLength="11"
          name="phone"
          onChange={formik.handleChange}
          value={formik.values.phone}
        />
        {formik.touched.phone && (
          <div className="form-text text-danger">
            {formik.errors.phone}
          </div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="email" content="Email" />
        <CustomInput
          type="text"
          placeholder="Saver's email"
          aria-label="email"
          aria-describedby="email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && (
          <div className="form-text text-danger">
            {formik.errors.email}
          </div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="collection_amount" content="Amount" />
        <CustomInput
          type="number"
          placeholder="₦0.00"
          aria-label="Amount"
          aria-describedby="collection_amount"
          name="collection_amount"
          onChange={formik.handleChange}
          value={formik.values.collection_amount}
        />
        {formik.touched.collection_amount && (
          <div className="form-text text-danger">{formik.errors.collection_amount}</div>
        )}
      </div>
      <div className="mb-3">
        <CustomLabel htmlFor="date" content="Collection Date" />
        <CustomInput
          type="date"
          placeholder="Collection Date"
          aria-label="Collection_date"
          aria-describedby="collection_date"
          name="collection_date"
          onChange={formik.handleChange}
          value={formik.values.collection_date}
        />
        {formik.touched.collection_date && (
          <div className="form-text text-danger">
            {formik.errors.collection_date}
          </div>
        )}
      </div>
      <div className="d-flex justify-content-between">
        <button
          className="btn customButton customGreyButton"
          onClick={handleGoBack}
          type="button"
        >
          Back
        </button>
        <button type="submit" className="btn customButton customPrimaryButton">
          Next
        </button>
      </div>
    </form>
  );
}
