import {useEffect, useState} from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
import PayoutSavingsForm from "../../components/payout-savings/PayoutSavingsForm";
import PayoutSavingsSuccess from "../../components/payout-savings/PayoutSavingsSuccess";
import AlertWarning from "../../widgets/alerts/warning/AlertWarning";
import {useDispatch} from "react-redux";
import {setPayoutIsLoading, setPayoutMessage} from "../../store/slices/savers/payoutSlice";

export default function PayoutSavings() {
    const [step, setStep] = useState(1);

    function handleChangeStep(val) {
        setStep(val);
    }
    const dispatch = useDispatch();
    const [payoutValues, setPayoutValues] = useState({})
    useEffect(() => {
        dispatch(setPayoutMessage(null));
        dispatch(setPayoutIsLoading(false));
    }, [dispatch])

    return (
        <>
            <PageHeader title="Payout Savings" />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                        {step === 1 && <AlertWarning content="Savers will be charged N100 on every N5000, if the payout is done before the set collection date " />}
                        <div className="d-flex justify-content-between align-items-center mb-3 mt-5">
                            {/* <div className="fw-bold">
                                {step === 1 && "Recent"}
                            </div> */}
                        </div>
                        {/* {step === 1 && <RecentPayoutSavers />} */}
                        <div className="customFormCard mt-3 py-5">
                            {step === 1 && <PayoutSavingsForm setPayoutValues={setPayoutValues} changeStep={handleChangeStep} />}
                            {step === 2 && <PayoutSavingsSuccess payoutValues={payoutValues} />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}