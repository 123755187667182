import {useFormik} from "formik";
import {profilePersonalInfoFields} from "../../utils/formFields";
import CustomForm from "../../widgets/form/CustomForm";
import CustomButton from "../../widgets/form/custom-button/CustomButton";
import {profilePersonalInfoSchema as validationSchema} from "../../utils/yupSchemas";
import {useDispatch, useSelector} from "react-redux";
import {loginSelector} from "../../store/slices/auth/loginSlice";
import {useState} from "react";
import useVerify from "../../hooks/useVerify";
import {updatePersonalDdetails} from "../../store/slices/agent/agentSlice";

export default function ProfilePersonalInfo({changeStep}) {
  const {userData} = useSelector(loginSelector).login;
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const formik = useFormik({
    initialValues: {
      first_name: userData?.first_name ?? "",
      middle_name: userData.middle_name ?? "",
      last_name: userData?.last_name ?? "",
      phone: userData?.phone ?? "",
      email: userData.email ?? "",
      // homeAddress: userData?.homeAddress ?? "",
    },
    validationSchema,
    onSubmit: (values) => handleSubmit(values),
  });

  const dispatch = useDispatch();
  const verify = useVerify()
  // useVerify will always refetch user data
  async function handleSubmit(values) {
    const details = {
      ...values,
      userId: userData.id
    };
    await dispatch(updatePersonalDdetails(details, {
      setLoading,
      setMessage
    }));
    // to refetch user data
    verify()
  }

  return (
    <>
      <div className="fw-bold">Personal Info</div>
      <div className="extraSmallText mb-4">
        These information are verified and cannot be changed without the admin’s
        Permission
      </div>
      <form onSubmit={formik.handleSubmit}>
        {(message && typeof message.text === 'string') &&
          <div className={message.error ? 'text-danger' : 'text-success'}>{message.text}</div>
        }
        {profilePersonalInfoFields.map((field, index) => (
          <CustomForm item={field} formik={formik} key={index} />
        ))}
        <div className="d-flex justify-content-end">
          <CustomButton type="submit" color="primary" text="Update Profile" loading={loading} />
        </div>
      </form>
    </>
  );
}
