import LayoutProfile from "../../layouts/LayoutProfile";
import { Outlet } from "react-router-dom";

export default function Profile() {
  return (
    <>
      <LayoutProfile>
        <Outlet />
      </LayoutProfile>
    </>
  );
}
