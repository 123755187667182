import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";

const validationSchema = Yup.object({
	nok_name: Yup.string("Enter Next of Kin Full name")
		.min(2, "Too short!")
		.max(50, "Too long!")
		.required("Full name is required"),
	nok_address: Yup.string("Enter Next of Kin Address").required(),
	nok_relationship: Yup.string("Select your relationship with your next of kin").required(),
	nok_phone: Yup.string("Enter phone number, e.g 08012345678")
		.min(11, "Too short! ")
		.max(11, "Too long!")
		.required("Phone number is required"),
	nok_email: Yup.string("Enter email, e.g example@gmail.com")
		.email("Email is invalid")
		.required("Email is required"),
});

export default function NextOfKin({changeStep}) {
	const {newSaverDetails, newSaverStep} = useSelector(saversSelector).savers;
	const dispatch = useDispatch();
	const formik = useFormik({
		initialValues: {
			nok_name: newSaverDetails?.nok_name ?? "",
			nok_address: newSaverDetails?.nok_address ?? "",
			nok_relationship: newSaverDetails?.nok_relationship ?? "",
			nok_phone: newSaverDetails?.nok_phone ?? "",
			nok_email: newSaverDetails?.nok_email ?? '',
		},
		validationSchema,
		onSubmit: (values) => handleSubmit(values),
	});

	function handleSubmit(values) {
		dispatch(setNewSaverDetails(values))
		dispatch(setNewSaverStep(7));
	}

	function handleGoBack() {
		dispatch(setNewSaverStep(newSaverStep - 1));
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className="mb-3">
				<CustomLabel htmlFor="nok_name" content="Full Name" />
				<CustomInput
					type="text"
					placeholder="Next of Kin full name"
					aria-label="First_name"
					aria-describedby="nok_name"
					name="nok_name"
					onChange={formik.handleChange}
					value={formik.values.nok_name}
				/>
				{formik.touched.nok_name && (
					<div className="form-text text-danger">{formik.errors.nok_name}</div>
				)}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="nok_email" content="Email Address" />
				<CustomInput
					type="text"
					placeholder="Enter Saver’s Middle Name"
					aria-label="Middle_name"
					aria-describedby="nok_email"
					name="nok_email"
					onChange={formik.handleChange}
					value={formik.values.nok_email}
				/>
				{formik.touched.nok_email && (
					<div className="form-text text-danger">
						{formik.errors.nok_email}
					</div>
				)}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="nok_phone" content="Phone Number" />
				<CustomInput
					type="tel"
					aria-label="Phone"
					aria-describedby="phone"
					withPrepend
					prependValue="+234"
					minLength="11"
					maxLength="11"
					name="nok_phone"
					onChange={formik.handleChange}
					value={formik.values.nok_phone}
				/>
				{formik.touched.nok_phone && (
					<div className="form-text text-danger">
						{formik.errors.nok_phone}
					</div>
				)}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="nok_address" content="Address" />
				<CustomInput
					type="text"
					placeholder="Address"
					aria-label="email"
					aria-describedby="email"
					name="nok_address"
					onChange={formik.handleChange}
					value={formik.values.nok_address}
				/>
				{formik.touched.nok_address && (
					<div className="form-text text-danger">
						{formik.errors.nok_address}
					</div>
				)}
			</div>
			<div className="mb-3">
				<CustomLabel htmlFor="nok_relationship" content="Relationship" />
				<CustomInput
					type="text"
					placeholder="Father"
					aria-label="relationship"
					aria-describedby="nok_relationship"
					name="nok_relationship"
					onChange={formik.handleChange}
					value={formik.values.nok_relationship}
				/>
				{formik.touched.nok_relationship && (
					<div className="form-text text-danger">{formik.errors.nok_relationship}</div>
				)}
			</div>
			<div className="d-flex justify-content-between">
				<button
					className="btn customButton customGreyButton"
					onClick={handleGoBack}
					type="button"
				>
					Back
				</button>
				<button type="submit" className="btn customButton customPrimaryButton">
					Next
				</button>
			</div>
		</form>
	);
}
