import React from "react";
import PageHeader from "../../widgets/page-header/PageHeader";
// import DataCard from "../../components/dashboard/data-card/DataCard";
// import {walletDataCards} from "../../utils/constants";
import Filter from "../../widgets/filter/Filter";
import Downloads from "../../widgets/downloads/Downloads";
import ContributionTransactions from "../../components/loans/loans/contribution-transactions/ContributionTransactions";

export default function Loans() {
  return (
    <>
      <PageHeader title="Loans" withSearch={true} withNewLoan={true} />
      <div className="container pt-4">
        {/* <section className="row flex-nowrap flex-md-wrap overflow-auto removeScrollbar position-relative">
          {walletDataCards.map((item, index) => <DataCard data={item} key={index} />)}
        </section> */}
        <section className="row">
          <div className="col-sm-12 p-2">
            <div className="mb-5"><Filter /></div>
            <Downloads />
            <ContributionTransactions />
          </div>
        </section>
      </div>
    </>
  );
};
