import CustomInput from "../../../widgets/form/custom-input/CustomInput";
import CustomLabel from "../../../widgets/form/custom-label/CustomLabel";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useDispatch, useSelector} from "react-redux";
import {saversSelector, setNewSaverDetails, setNewSaverStep} from "../../../store/slices/savers/saversSlice";
import './index.css'

const validationSchema = Yup.object({
    account_number: Yup.string("Enter account number, e.g 9999999999")
        .length(10, "Must be 10 in length")
        .max(10, "Too long! must be 10 in length"),
    bank: Yup.string("Select your bank name"),
    account_name: Yup.string("Enter your name on the account"),
    bvn: Yup.string("Enter bvn, e.g 9999999999")
        .length(11, "Must be 11 in length")
        .max(11, "Too long! must be 11 in length")
});

export default function AddBankDetails() {
    const {newSaverStep, newSaverDetails} = useSelector(saversSelector).savers;
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            account_number: newSaverDetails?.account_number ?? "",
            bank: newSaverDetails?.bank ?? "",
            bvn: newSaverDetails?.bvn ?? "",
            account_name: newSaverDetails?.account_name ?? ""
        },
        validationSchema,
        onSubmit: (values) => handleSubmit(values),
    });

    function handleSubmit(values) {
        // const bankD = JSON.parse(values.bank);
        dispatch(setNewSaverDetails({...values}));
        // dispatch(setNewSaverDetails({...values, bank_name: bankD.name, bank_number: bankD.code}));
        dispatch(setNewSaverStep(4))
    }

    function handleGoBack() {
        dispatch(setNewSaverStep(newSaverStep - 1));
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            {/* <div className="mb-3">
                <CustomLabel htmlFor="account_name" content="Account Name" />
                <CustomInput
                    type="text"
                    aria-label="Account_name"
                    aria-describedby="account_name"
                    name="account_name"
                    onChange={formik.handleChange}
                    value={formik.values.account_name}
                />
                {formik.touched.account_name && <div className="form-text text-danger">{formik.errors.account_name}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="account_number" content="Account Number" />
                <CustomInput
                    type="number"
                    aria-label="Account_number"
                    aria-describedby="account_number"
                    name="account_number"
                    onChange={formik.handleChange}
                    value={formik.values.account_number}
                />
                {formik.touched.account_number && <div className="form-text text-danger">{formik.errors.account_number}</div>}
            </div>
            <div className="mb-3">
                <CustomLabel htmlFor="bank" content="Bank Name" />
                <select className="form-select customFormControl" name="bank" onChange={formik.handleChange} aria-label="Bank_name" aria-describedby="bank">
                    <option value="">Open this select menu</option>
                    {nigerianBanksList.map((item, index) => <option key={index} value={JSON.stringify(item)}>{item.name}</option>)}
                </select>
                {formik.touched.bank && <div className="form-text text-danger">{formik.errors.bank}</div>}
            </div> */}
            <div className="mb-3">
                <CustomLabel htmlFor="bvn" content="BVN" />
                <CustomInput
                    type="number"
                    aria-label="bvn"
                    aria-describedby="bvn"
                    name="bvn"
                    onChange={formik.handleChange}
                    value={formik.values.bvn}
                />
                {formik.touched.bvn && <div className="form-text text-danger">{formik.errors.bvn}</div>}
            </div>
            <div className="d-flex justify-content-between">
                <button className="btn customButton customGreyButton" onClick={handleGoBack} type="button">Back</button>
                <button type="submit" className="btn customButton customPrimaryButton">Next</button>
            </div>
        </form>
    )
}
